import axios from "axios";
import {baseUrlB2b} from "../../config/apiConfig/apiConfig";

class B2bService {
    private axios = axios.create({
        baseURL: baseUrlB2b,
    });

    public async createB2bProspect(inputData?: any) {
        try {
            return await this.axios.post<any>('/webhook/prospect/add',
                inputData, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
        } catch (e) {
            throw e;
        }
    }

    public async getDetailOffer(params?: any) {
        try {
            return await this.axios.get<any>(`/prospect/temporary/${params.offerCode}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        } catch (e) {
            throw e;
        }
    }

    public async getDetailSurvey(params?: any) {
        try {
            return await this.axios.get<any>(`/public/get-survey/offer-code/${params.offerCode}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        } catch (e) {
            throw e;
        }
    }

    public async createB2bTemporaryProspect(inputData?: any) {
        try {
            return await this.axios.post<any>('/prospect/temporary',
                inputData, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
        } catch (e) {
            throw e;
        }
    }

    public async getmasterPricelist(params?: { leasingCode?: string, cityGroup?: string, modelName?: string, variantCode?: string }) {
        const queries = {
            ...(params?.leasingCode && {
                leasingCode: params.leasingCode,
            }),
            ...(params?.cityGroup && {
                cityGroup: params.cityGroup,
            }),
            ...(params?.modelName && {
                modelName: params.modelName,
            }),
            ...(params?.variantCode && {
                variantCode: params.variantCode,
            })
        }
        try {
            return await this.axios.get<any>(`price-list`, {
                params: {...queries}
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }
}

export const b2bService = new B2bService();
