import React, {Component} from "react";
import Select from "antd/lib/select";

class CustomerLastEducation extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            customerLastEducation: null,
        }
        this.state = {...this.initState}
    }

    onChange = (value: any, option: any) => {
        this.setState({
            customerLastEducation: value,
        });
        this.props.onCustomerLastEducationChangeProps(option);
    }

    render() {
        return (
            <Select
                placeholder="Pendidikan Terakhir"
                showSearch
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.props.value ?? this.state.customerLastEducation}
            >
                <Select.Option value="NO_SCHOOL">Tidak Sekolah</Select.Option>
                <Select.Option value="DID_NOT_FINISH_ELEMENTARY_SCHOOL">Tidak Lulus SD</Select.Option>
                <Select.Option value="GRADUATED_ELEMENTARY_SCHOOL">Lulus SD</Select.Option>
                <Select.Option value="JUNIOR_HIGH_SCHOOL">Lulus SMP</Select.Option>
                <Select.Option value="HIGH_SCHOOL">Lulus SMA</Select.Option>
                <Select.Option value="DIPLOMA_1_2">Lulus D1 / D2</Select.Option>
                <Select.Option value="DIPLOMA_3">Lulus D3</Select.Option>
                <Select.Option value="DIPLOMA_4_OR_BACHELOR">Lulus S1 / Sederajat</Select.Option>
                <Select.Option value="MASTER">Lulus S2</Select.Option>
                <Select.Option value="DOCTOR">Lulus S3</Select.Option>
            </Select>
        );
    }
}

export default CustomerLastEducation;
