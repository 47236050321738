import React, {Component} from "react";
// import {Buffer} from "buffer";
import Cookies from "universal-cookie";
import {baseUrlCdnUpload} from "../../config/apiConfig/apiConfig";
import {Button, Col, Image, DatePicker, Form, Input, Modal, Row, Spin, Upload, Collapse} from "antd";
import {offerApiServices} from "../../services/offer/OfferApiService";
import {IdcardOutlined, UploadOutlined} from "@ant-design/icons";
import CustomerMaritalStatus from "../customer/CustomerMaritalStatus";
import CustomerOccupation from "../customer/CustomerOccupation";
import AreaProvinceSelect from "../area/AreaProvinceSelect";
import AreaCitySelect from "../area/AreaCitySelect";
import AreaDistrictSelect from "../area/AreaDistrictSelect";
import CropIdCardImage from "../../components/image/CropIdCardImage";
import AreaSubdistrictSelect from "../area/AreaSubdistrictSelect";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import {cdnApiService} from "../../services/trimitraCdn/CdnApiService";
import {authApiService} from "../../services/amartaVip/AuthApiService";

const {Panel} = Collapse;

class UpdateOrderMakerOffer extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();
    private user = "user amartahonda";

    constructor(props: any) {
        super(props);
        this.initState = {
            dealerCode: "",
            offerCode: "",
            dataDetailStock: {},
            fetchingDetailOffer: false,
            orderMakerIdCardNumber: "",
            orderMakerIdCardName: "",
            orderMakerIdCardImage: "",
            orderMakerBirthPlace: "",
            orderMakerBirthDate: moment(new Date(), 'YYYY-MM-DD'),
            orderMakerMaritalStatus: null,
            orderMakerMaritalStatusName: "",
            orderMakerOccupationCode: null,
            orderMakerOccupationName: "",
            orderMakerPlaceToStayCode: null,
            orderMakerPlaceToStayName: "",
            orderMakerFullAddress: "",
            orderMakerPostalCode: "",
            orderMakerProvinceCode: "",
            orderMakerProvinceName: "",
            orderMakerCityCode: "",
            orderMakerCityName: "",
            orderMakerDistrictCode: "",
            orderMakerDistrictName: "",
            orderMakerSubdistrictCode: "",
            orderMakerSubdistrictName: "",
            updateProcess: false,
            showModalUploadIdCardOrderMaker: false,
            base64IdCardOrderMaker: "",
            freezeStatus: false,
            token: this.cookies.get('_amh-t'),
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            dealerCode: this.props.dealerCode,
            offerCode: this.props.offerCode,
            fetchingDetailOffer: true,
        });

        this.showOfferDetail();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.dealerCode !== prevProps.dealerCode) {
            console.log('diff dealer');
        }

        if (this.props.offerCode !== prevProps.offerCode) {
            await this.promisedSetState({
                offerCode: this.props.offerCode,
                fetchingDetailOffer: true,
            });
        }

        if (this.props.time !== prevProps.time) {
            this.showOfferDetail();
        }
    }

    showOfferDetail = async () => {

        await this.promisedSetState({
            dealerCode: "",
            offerCode: "",
            dataDetailStock: {},
            fetchingDetailOffer: false,
            orderMakerIdCardNumber: "",
            orderMakerIdCardName: "",
            orderMakerIdCardImage: "",
            orderMakerBirthPlace: "",
            orderMakerBirthDate: moment(new Date(), 'YYYY-MM-DD'),
            orderMakerMaritalStatus: null,
            orderMakerMaritalStatusName: "",
            orderMakerOccupationCode: null,
            orderMakerOccupationName: "",
            orderMakerPlaceToStayCode: null,
            orderMakerPlaceToStayName: "",
            orderMakerFullAddress: "",
            orderMakerPostalCode: "",
            orderMakerProvinceCode: "",
            orderMakerProvinceName: "",
            orderMakerCityCode: "",
            orderMakerCityName: "",
            orderMakerDistrictCode: "",
            orderMakerDistrictName: "",
            orderMakerSubdistrictCode: "",
            orderMakerSubdistrictName: "",
            updateProcess: false,
        });

        await offerApiServices.getDetailOffer({
            offerCode: this.props.offerCode,
        }).then(async value => {
            await this.promisedSetState({
                dataDetailStock: value.data.data,
                fetchingDetailOffer: false,
                orderMakerIdCardNumber: value.data.data?.id_card_order_maker?.id_card_number,
                orderMakerIdCardName: value.data.data?.id_card_order_maker?.full_name,
                orderMakerIdCardImage: value.data.data?.id_card_order_maker?.id_card_image,
                dataStatusUpdateImage: true,
                showModalUploadIdCardOrderMaker: false,
                base64IdCardOrderMaker: "",
                freezeStatus: false,
                orderMakerBirthPlace: value.data.data?.id_card_order_maker?.birth_place,
                orderMakerBirthDate: (value.data.data?.id_card_order_maker?.birth_date) ? value.data.data?.id_card_order_maker?.birth_date : moment(new Date(), 'YYYY-MM-DD'),
                orderMakerPostalCode: value.data.data.address_order_maker.zip_code,
                orderMakerProvinceCode: value.data.data.address_order_maker.province_code,
                orderMakerProvinceName: value.data.data.address_order_maker.province_name,
                orderMakerCityCode: value.data.data.address_order_maker.city_code,
                orderMakerCityName: value.data.data.address_order_maker.city_name,
                orderMakerDistrictCode: value.data.data.address_order_maker.district_code,
                orderMakerDistrictName: value.data.data.address_order_maker.district_name,
                orderMakerSubdistrictCode: value.data.data.address_order_maker.sub_district_code,
                orderMakerSubdistrictName: value.data.data.address_order_maker.sub_district_name,
                orderMakerFullAddress: value.data.data.address_order_maker.full_address,
                orderMakerMaritalStatus: value.data.data.address_owner.marital_status,
                orderMakerMaritalStatusName: value.data.data.address_owner.marital_status,
                orderMakerOccupationCode: value.data.data.address_owner.occupation,
                orderMakerOccupationName: value.data.data.address_owner.occupation_code,
                orderMakerPlaceToStayCode: null,
                orderMakerPlaceToStayName: "",
            });
        }).catch(async reason => {
            await this.promisedSetState({
                dataDetailStock: {},
                fetchingDetailOffer: false,
            });
        });
    }

    onFieldChange = <T extends keyof Pick<any, "orderMakerIdCardNumber" | "orderMakerIdCardName" | "orderMakerFullAddress" | "orderMakerMaritalStatus" | "orderMakerPostalCode" | "orderMakerDifferentAddress" | "shippingFullAddress" | "shippingPostalCode" | "orderMakerBirthPlace" | "orderMakerBirthDate">>(target: T, value: any) => {
        const currentState = {...this.state};
        switch (target) {
            case "orderMakerIdCardNumber":
                currentState.orderMakerIdCardNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "orderMakerIdCardName":
                currentState.orderMakerIdCardName = value?.toUpperCase();
                break;
            case "orderMakerBirthPlace":
                currentState.orderMakerBirthPlace = value;
                break;
            case "orderMakerBirthDate":
                currentState.orderMakerBirthDate = value;
                break;
            case "orderMakerFullAddress":
                currentState.orderMakerFullAddress = value;
                break;
            case "orderMakerPostalCode":
                currentState.orderMakerPostalCode = value;
                break;
        }

        if (target === 'orderMakerIdCardNumber') {
            currentState.dataStatusUpdateImage = target === 'orderMakerIdCardNumber' && value.length === 16;
        }

        this.setState({
            ...currentState,
        });
    }

    uploadImageChange = (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document;
            this.setState({
                submitting: false,
                orderMakerIdCardImage: newImgLocation,
                updateProcess: false,
            });
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                submitting: true,
                updateProcess: false,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                submitting: false,
                orderMakerIdCardImage: "",
                updateProcess: false,
            });
        }
    }

    onCustomerMaritalStatusChangeChange = (data: any) => {
        this.setState({
            orderMakerMaritalStatus: data.value,
            orderMakerMaritalStatusName: data.children
        });
    }

    onCustomerOccupationChangeChange = (data: any) => {
        this.setState({
            orderMakerOccupationCode: data.value,
            orderMakerOccupationName: data.children
        });
    }

    onAreaProvinceChange = (data: any) => {
        this.setState({
            orderMakerProvinceCode: data.value,
            orderMakerProvinceName: data.children,
            orderMakerCityCode: null,
            orderMakerCityName: null,
            orderMakerDistrictCode: null,
            orderMakerDistrictName: null,
            orderMakerSubdistrictCode: null,
            orderMakerSubdistrictName: null,
            orderMakerPostalCode: "",
        });
    }

    onAreaCityChange = (data: any) => {
        this.setState({
            orderMakerCityCode: data.value,
            orderMakerCityName: data.children,
            orderMakerDistrictCode: null,
            orderMakerDistrictName: null,
            orderMakerSubdistrictCode: null,
            orderMakerSubdistrictName: null,
            orderMakerPostalCode: "",
        });
    }

    onAreaDistrictChange = (data: any) => {
        this.setState({
            orderMakerDistrictCode: data.value,
            orderMakerDistrictName: data.children,
            orderMakerSubdistrictCode: null,
            orderMakerSubdistrictName: null,
            orderMakerPostalCode: "",
        });
    }

    onAreaSubdistrictChange = (data: any) => {
        this.setState({
            orderMakerSubdistrictCode: data.value,
            orderMakerSubdistrictName: data.children,
            orderMakerPostalCode: data?.postalCode,
        });
    }

    onUpdateData = async () => {
        const dataUpdate = {
            type: "update-spk-id-card-owner",
            company: this.props.companyCode,
            admin_id: this.user,
            admin_name: this.user,
            offer_code: this.props.offerCode,
            id_card_order_maker: {
                id_card_number: this.state.orderMakerIdCardNumber,
                id_card_image: this.state.orderMakerIdCardImage,
                full_name: this.state.orderMakerIdCardName,
                full_address: this.state.orderMakerFullAddress,
                birth_place: this.state.orderMakerBirthPlace,
                birth_date: moment(this.state?.orderMakerBirthDate).format("YYYY-MM-DD"),
                marital_status_code: this.state.orderMakerMaritalStatus,
                marital_status: this.state.orderMakerMaritalStatusName,
                occupation_code: this.state.orderMakerOccupationCode,
                occupation: this.state.orderMakerOccupationName,
            },
            address_order_maker: {
                full_address: this.state.orderMakerFullAddress,
                province_code: this.state.orderMakerProvinceCode,
                province_name: this.state.orderMakerProvinceName,
                city_code: this.state.orderMakerCityCode,
                city_name: this.state.orderMakerCityName,
                district_code: this.state.orderMakerDistrictCode,
                district_name: this.state.orderMakerDistrictName,
                sub_district_code: this.state.orderMakerSubdistrictCode,
                sub_district_name: this.state.orderMakerSubdistrictName,
                zip_code: this.state.orderMakerPostalCode,
            }
        };

        if (!this.state.orderMakerIdCardNumber) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Nomor KTP"});
            return false;
        }
        if (!this.state.orderMakerIdCardImage) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Gambar KTP"});
            return false;
        }
        if (!this.state.orderMakerIdCardName) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Nama KTP"});
            return false;
        }
        if (!this.state.orderMakerBirthPlace) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Tempat Lahir Pemilik"});
            return false;
        }
        if (!this.state.orderMakerBirthDate) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Tanggal Lahir Pemilik"});
            return false;
        }
        if (!this.state.orderMakerMaritalStatus) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Status Perkawinan"});
            return false;
        }
        if (!this.state.orderMakerOccupationCode) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Pekerjaan"});
            return false;
        }
        if (!this.state.orderMakerFullAddress) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Alamat Lengkap"});
            return false;
        }
        if (!this.state.orderMakerPostalCode) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi kode pos"});
            return false;
        }
        if (!this.state.orderMakerProvinceCode) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Provinsi"});
            return false;
        }
        if (!this.state.orderMakerCityCode) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Kota"});
            return false;
        }
        if (!this.state.orderMakerDistrictCode) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Kecamatan"});
            return false;
        }
        if (!this.state.orderMakerSubdistrictCode) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Kelurahan"});
            return false;
        }

        await this.promisedSetState({
            updateProcess: true,
        });

        await authApiService.setToken(this.state.token);
        try {
            await authApiService.updateBiodata(dataUpdate)
                .then(successData => {
                    Modal.success({
                        title: 'Proses Sukses',
                        content: 'SPK berhasil di update '
                    });
                    this.setState({
                        updateProcess: false,
                    });
                    this.props.onCloseUpdateOrderMakerIdCardProps("close");
                });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.promisedSetState({
                updateProcess: false,
            });
        }
    }

    clickUpdateIdCardImage = () => {
        if (this.state.orderMakerIdCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KTP tidak valid"});
            return;
        }
        this.setState({showModalUploadIdCardOrderMaker: true});
    }

    onCropIdCardOrderMaker = async (base64DataImagePng: any) => {
        await this.promisedSetState({
            base64IdCardOrderMaker: base64DataImagePng,
        });
    }

    saveOrderMakerIdCardData = async () => {
        if (this.state.base64IdCardOrderMaker?.length < 10) {
            Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"});
            await this.promisedSetState({
                showOldUploadRawImage: true,
            });
            return;
        }

        let randomString = (Math.random() + 1).toString(36).substring(7);
        const dataUpload = {
            id_image: "amartavip-idcard-" + randomString + "-" + this.state.orderMakerIdCardNumber,
            image_data: this.state.base64IdCardOrderMaker,
        }

        await this.uploadImageAndUpdateState("IDCARDORDERMAKER_IMAGE", dataUpload);
    }

    uploadImageAndUpdateState = async (type: string, dataUpload: any) => {
        if (type === "IDCARDORDERMAKER_IMAGE") {
            if (!this.state.base64IdCardOrderMaker) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image KTP Pemilik"});
                return;
            }
        }

        await this.setState({
            freezeStatus: true,
        });

        try {
            await cdnApiService.uploadIdCardBase64(dataUpload).then(async successData => {
                if (type === "FAMILYCARD_IMAGE") {
                    await this.promisedSetState({
                        familyCardImageUrl: successData?.data?.data?.url_document,
                    });
                }
                if (type === "IDCARDORDERMAKER_IMAGE") {
                    await this.promisedSetState({
                        orderMakerIdCardImage: successData?.data?.data?.url_document,
                    });
                }

                await this.promisedSetState({
                    freezeStatus: false,
                    showModalUploadFamilyCard: false,
                    showModalUploadIdCardOrderMaker: false,
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.setState({
                freezeStatus: false
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.fetchingDetailOffer ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.fetchingDetailOffer ? {} : {display: 'none'}}>
                    <Form layout="vertical">
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Nama Lengkap *</i>
                            <Input
                                value={this.state.orderMakerIdCardName}
                                placeholder="Nama Lengkap"
                                onChange={event => this.onFieldChange("orderMakerIdCardName", event.target.value)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Nomor KTP Pemilik *</i>
                            <Input
                                maxLength={16}
                                value={this.state.orderMakerIdCardNumber}
                                placeholder="Nomor Sesuai KTP Pemilik"
                                onChange={event => this.onFieldChange("orderMakerIdCardNumber", event.target.value)}
                            />
                        </Form.Item>

                        <div className={`card-box-shadow`}>
                            <Row>
                                <Col span={24}>
                                    <IdcardOutlined className={`icon-title-info`}/>
                                    <b className={`text-title-info`}>KTP</b>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: 10}}>
                                <Col span={23} offset={1}>
                                    <Row>
                                        <Col span={12}>
                                            <div className="font-old-grey-100">
                                                <i className={`text-extra-small-grey-nopadding`}>Nomor KTP:</i>
                                                <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.orderMakerIdCardNumber} </div>
                                                <i className={`text-extra-small-grey-nopadding`}>Nama:</i>
                                                <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.orderMakerIdCardName} </div>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            {this.state.orderMakerIdCardImage
                                                ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.orderMakerIdCardImage}/></div>
                                                : <div onClick={() => this.clickUpdateIdCardImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                    <div onClick={() => this.clickUpdateIdCardImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                        {this.state.orderMakerIdCardImage
                                            ? <span>Update Gambar KTP</span>
                                            : <span>Lengkapi Gambar KTP</span>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <br/>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Tempat Lahir Pemilik *</i>
                            <Input
                                value={this.state.orderMakerBirthPlace}
                                placeholder="Tempat Lahir Sesuai KTP Pemilik"
                                onChange={event => this.onFieldChange("orderMakerBirthPlace", event.target.value)}
                            />
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`text-small-grey-nopadding`}>Tanggal Lahir Pemilik *</i>
                            <DatePicker value={moment(this.state.orderMakerBirthDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("orderMakerBirthDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Status Perkawinan *</i>
                            <CustomerMaritalStatus onCustomerMaritalStatusChangeProps={this.onCustomerMaritalStatusChangeChange} value={this.state.orderMakerMaritalStatus}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Pekerjaan *</i>
                            <CustomerOccupation onCustomerOccupationChangeProps={this.onCustomerOccupationChangeChange} value={this.state.orderMakerOccupationCode}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Alamat Sesuai KTP *</i>
                            <TextArea showCount maxLength={200} value={this.state.orderMakerFullAddress} onChange={event => this.onFieldChange("orderMakerFullAddress", event.target.value)} placeholder="Alamat Sesuai KTP"/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Provinsi *</i>
                            <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceChange} country={"idn"} provinceCode={this.state.orderMakerProvinceCode} provinceName={this.state.orderMakerProvinceName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kota / Kabupaten *</i>
                            <AreaCitySelect onAreaCityChangeProps={this.onAreaCityChange} provinceCode={this.state.orderMakerProvinceCode} cityName={this.state.orderMakerCityName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kecamatan *</i>
                            <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictChange} cityCode={this.state.orderMakerCityCode} districtName={this.state.orderMakerDistrictName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kelurahan *</i>
                            <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictChange} districtCode={this.state.orderMakerDistrictCode} subdistrictName={this.state.orderMakerSubdistrictName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kode Pos Sesuai KTP *</i>
                            <Input
                                value={this.state.orderMakerPostalCode}
                                placeholder="Kodepos"
                                onChange={event => this.onFieldChange("orderMakerPostalCode", event.target.value)}
                            />
                        </Form.Item>
                        <br/>

                        <Button type={"primary"} loading={this.state.updateProcess} onClick={() => this.onUpdateData()}> Update Data</Button>
                    </Form>
                </div>

                <Modal
                    title="Lengkapi Data KTP"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadIdCardOrderMaker}
                    onCancel={() => this.setState({showModalUploadIdCardOrderMaker: false})}
                    footer={[
                        <Button style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}} key={'saveCustomer'} type="primary" onClick={this.saveOrderMakerIdCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadIdCardOrderMaker: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical" style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64IdCardOrderMaker) ? "0" : "1"}>
                                <ul style={{fontSize: "80%"}}>
                                    <li>Klik Tombol Upload Gambar KTP Pemilik</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardOrderMaker}/>
                        </Form.Item>
                    </Form>

                    <Form layout="vertical" style={this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Upload
                            method="POST"
                            action={baseUrlCdnUpload + "/upload-document/id-card"}
                            listType="picture"
                            maxCount={1}
                            data={{
                                id_card_number: this.state.orderMakerIdCardNumber,
                                source: "amartahonda.com",
                                notes: "my-biodata"
                            }}
                            name="image"
                            onChange={this.uploadImageChange}
                        > <Button icon={<UploadOutlined/>}>Upload Ulang Gambar KTP</Button>
                        </Upload>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default UpdateOrderMakerOffer;