import axios from "axios";
import {baseUrlB2b} from "../../config/apiConfig/apiConfig";

class FincoServices {
    private axios = axios.create({
        baseURL: baseUrlB2b,
    });

    public async getListLeasing() {
        const queries = {
            products: "2W_NEW"
        }
        try {
            return await this.axios.get<any>(`/public/available-leasing/e-survey`, {
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }
}

export const fincoServices = new FincoServices();
