import React from "react";
import Image from "antd/lib/image";
import "./ImageCarousel.css";

export interface IImageCarousel {
    imageSrc: string;
}

const ImageCarousel = (props: IImageCarousel) => {
    return (
        <Image width={'100%'} preview={false} className={`carousel-img`} src={props.imageSrc} alt={`amartahonda-logo`}/>
    );
}

ImageCarousel.defaultProps = {
    imageSrc: "",
}

export default ImageCarousel;
