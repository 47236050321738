import React, {Component} from 'react';
import {Buffer} from "buffer";
import {Layout, Upload} from "antd";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import Col from "antd/lib/col";
import MyProfileLoginHeader from "../../components/layouts/MyProfileLoginHeader";
import Form from "antd/lib/form";
import Tabs from "antd/lib/tabs";
import Input from "antd/lib/input";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import Image from "antd/lib/image";
import TextArea from "antd/es/input/TextArea";
import AreaDistrictSelect from "../../components/area/AreaDistrictSelect";
import AreaProvinceSelect from "../../components/area/AreaProvinceSelect";
import AreaCitySelect from "../../components/area/AreaCitySelect";
import AreaSubdistrictSelect from "../../components/area/AreaSubdistrictSelect";
import Row from "antd/lib/row";
import Button from "antd/lib/button";
import {MyBiodataStates} from "./types/MyBiodataTypes";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import IdcardOutlined from "@ant-design/icons/IdcardOutlined";
import Spin from "antd/lib/spin";
import CropIdCardImage from "../../components/image/CropIdCardImage";
import Collapse from "antd/lib/collapse";
import Modal from "antd/lib/modal";
import {cdnApiService} from "../../services/trimitraCdn/CdnApiService";
import {baseUrlCdnUpload} from "../../config/apiConfig/apiConfig";
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import {appVersion} from "../../config/dataConfig/appConfig";
import FooterSection from "../../components/footer/FooterSection";

const {Panel} = Collapse;

export interface MyBiodataProps {
}

class MyBiodata extends Component<MyBiodataProps & any, MyBiodataStates> {
    private cookies = new Cookies();

    constructor(props: MyBiodataProps & any) {
        super(props);

        this.state = {
            submitting: false,
            freezeStatus: false,
            processing: false,
            profileData: {},

            name: Buffer.from(this.cookies.get('_amh-n'), 'base64').toString(),
            email: Buffer.from(this.cookies.get('_amh-e'), 'base64').toString(),
            phoneNumber: Buffer.from(this.cookies.get('_amh-p'), 'base64').toString(),
            token: this.cookies.get('_amh-t'),

            showOldUploadRawImage: false,

            idCardImageUrl: "",
            showModalUploadIdCardOwner: false,
            base64IdCardOwner: "",

            familyCardImageUrl: "",
            showModalUploadFamilyCard: false,
            base64FamilyCard: "",

            showDrawerMenu: false,
            idCardNumber: "",
            idCardName: "",
            birthPlace: "",
            birthDate: moment(new Date(), 'YYYY-MM-DD'),
            fullAddress: "",
            provinceCode: null,
            provinceName: null,
            cityCode: null,
            cityName: null,
            districtCode: null,
            districtName: null,
            subdistrictCode: null,
            subdistrictName: null,
            postalCode: "",
            hamlet: "",
            neighbourhood: "",

            familyCardNumber: "",

            fullAddressCurrent: "",
            provinceCodeCurrent: null,
            provinceNameCurrent: null,
            cityCodeCurrent: null,
            cityNameCurrent: null,
            districtCodeCurrent: null,
            districtNameCurrent: null,
            subdistrictCodeCurrent: null,
            subdistrictNameCurrent: null,
            postalCodeCurrent: "",
            hamletCurrent: "",
            neighbourhoodCurrent: "",
        }
    }


    onFieldChange = <T extends keyof Pick<any, "idCardNumber" | "idCardName" | "birthPlace" | "birthDate" | "fullAddress" | "postalCode" | "hamlet" | "neighbourhood" | "familyCardNumber" | "fullAddressCurrent" | "postalCodeCurrent" | "hamletCurrent" | "neighbourhoodCurrent">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "idCardNumber":
                currentState.idCardNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "idCardName":
                currentState.idCardName = value;
                break;
            case "birthPlace":
                currentState.birthPlace = value;
                break;
            case "birthDate":
                if (value) {
                    currentState.birthDate = value;
                }
                break;
            case "fullAddress":
                currentState.fullAddress = value;
                break;
            case "postalCode":
                currentState.postalCode = value;
                break;
            case "hamlet":
                currentState.hamlet = value;
                break;
            case "neighbourhood":
                currentState.neighbourhood = value;
                break;
            case "familyCardNumber":
                currentState.familyCardNumber = value.replace(/[^0-9.]/g, '');
                break;

            case "fullAddressCurrent":
                currentState.fullAddressCurrent = value;
                break;
            case "postalCodeCurrent":
                currentState.postalCodeCurrent = value;
                break;
            case "hamletCurrent":
                currentState.hamletCurrent = value;
                break;
            case "neighbourhoodCurrent":
                currentState.neighbourhoodCurrent = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    onAreaProvinceChange = (data: any) => {
        this.setState({
            provinceCode: data.value,
            provinceName: data.children,
            cityCode: "",
            cityName: "",
            districtCode: "",
            districtName: "",
            subdistrictCode: "",
            subdistrictName: "",
            postalCode: ""
        });
    }

    onAreaCityChange = (data: any) => {
        this.setState({
            cityCode: data.value,
            cityName: data.children,
            districtCode: "",
            districtName: "",
            subdistrictCode: "",
            subdistrictName: "",
            postalCode: ""
        });
    }

    onAreaDistrictChange = (data: any) => {
        this.setState({
            districtCode: data.value,
            districtName: data.children,
            subdistrictCode: "",
            subdistrictName: "",
            postalCode: ""
        });
    }

    onAreaSubdistrictChange = (data: any) => {
        this.setState({
            subdistrictCode: data.value,
            subdistrictName: data.children,
            postalCode: data.postalCode
        });
    }

    onAreaProvinceCurrentChange = (data: any) => {
        this.setState({
            provinceCodeCurrent: data.value,
            provinceNameCurrent: data.children,
            cityCodeCurrent: "",
            cityNameCurrent: "",
            districtCodeCurrent: "",
            districtNameCurrent: "",
            subdistrictCodeCurrent: "",
            subdistrictNameCurrent: "",
            postalCodeCurrent: ""
        });
    }

    onAreaCityCurrentChange = (data: any) => {
        this.setState({
            cityCodeCurrent: data.value,
            cityNameCurrent: data.children,
            districtCodeCurrent: "",
            districtNameCurrent: "",
            subdistrictCodeCurrent: "",
            subdistrictNameCurrent: "",
            postalCodeCurrent: ""
        });
    }

    onAreaDistrictCurrentChange = (data: any) => {
        this.setState({
            districtCodeCurrent: data.value,
            districtNameCurrent: data.children,
            subdistrictCodeCurrent: "",
            subdistrictNameCurrent: "",
            postalCodeCurrent: ""
        });
    }

    onAreaSubdistrictCurrentChange = (data: any) => {
        this.setState({
            subdistrictCodeCurrent: data.value,
            subdistrictNameCurrent: data.children,
            postalCodeCurrent: data.postalCode
        });
    }

    addLeadingZeros = (num: any, totalLength: any) => {
        return String(num).padStart(totalLength, '0');
    }

    onUpdateIdCard = async () => {
        if (!this.state.idCardImageUrl || !this.state.idCardNumber || !this.state.idCardName || !this.state.subdistrictCode) {
            Modal.error({title: 'Error', content: "Mohon lengkapi data"});
            return;
        }
        if (this.state.idCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KTP tidak valid"});
            return;
        }

        const dataUpdate = {
            type: "update-biodata-id-card",
            id_card: {
                id_card_number: this.state.idCardNumber,
                id_card_image: this.state.idCardImageUrl,
                full_name: this.state.idCardName,
                birth_date: moment(this.state.birthDate).format("YYYY-MM-DD"),
                birth_place: this.state.birthPlace,
                marital_status: null,
                occupation_code: null,
                occupation: null,
                sex: null,
                religion: null
            },
            address: {
                full_address: this.state.fullAddress,
                province_code: this.state.provinceCode,
                province_name: this.state.provinceName,
                city_code: this.state.cityCode,
                city_name: this.state.cityName,
                district_code: this.state.districtCode,
                district_name: this.state.districtName,
                postal_code: this.state.postalCode,
                sub_district_code: this.state.subdistrictCode,
                sub_district_name: this.state.subdistrictName,
                hamlet: this.state.hamlet,
                neighbourhood: this.state.neighbourhood
            }
        }

        await this.promisedSetState({
            submitting: true,
        })

        authApiService.setToken(this.state.token);
        try {
            await authApiService.updateBiodata(dataUpdate);
            Modal.success({
                title: 'Proses Sukses',
                content: 'data berhasil di update'
            });
            this.setState({
                submitting: false,
            });
        } catch (e) {
            Modal.error({
                title: 'update Failed',
                content: 'error: ' + e,
            });
            this.setState({
                submitting: false,
            });
        }
    }

    onUpdateFamilyCard = async () => {
        if (!this.state.familyCardImageUrl || !this.state.familyCardNumber) {
            Modal.error({title: 'Error', content: "Mohon lengkapi data"});
            return;
        }
        if (this.state.familyCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KK tidak valid"});
            return;
        }

        const dataUpdate = {
            type: "update-biodata-family-register",
            other_documents: {
                document_image: this.state.familyCardImageUrl,
                document_number: this.state.familyCardNumber,
                type: "family_register"
            }
        }

        await this.promisedSetState({
            submitting: true,
        })

        authApiService.setToken(this.state.token);
        try {
            await authApiService.updateBiodata(dataUpdate);
            Modal.success({
                title: 'Proses Sukses',
                content: 'data berhasil di update'
            });
            this.setState({
                submitting: false,
            });
        } catch (e) {
            Modal.error({
                title: 'update Failed',
                content: 'error: ' + e,
            });
            this.setState({
                submitting: false,
            });
        }
    }

    onUpdateCurrentAddress = async () => {
        if (!this.state.districtCodeCurrent || !this.state.postalCodeCurrent) {
            Modal.error({title: 'Error', content: "Mohon lengkapi data"});
            return;
        }

        const dataUpdate = {
            type: "update-biodata-current-address",
            current_address: {
                full_address: this.state.fullAddressCurrent,
                province_code: this.state.provinceCodeCurrent,
                province_name: this.state.provinceNameCurrent,
                city_code: this.state.cityCodeCurrent,
                city_name: this.state.cityNameCurrent,
                district_code: this.state.districtCodeCurrent,
                district_name: this.state.districtNameCurrent,
                sub_district_code: this.state.districtCodeCurrent,
                sub_district_name: this.state.subdistrictNameCurrent,
                postal_code: this.state.postalCodeCurrent,
                hamlet: this.addLeadingZeros(this.state.hamletCurrent, 3),
                neighbourhood: this.addLeadingZeros(this.state.neighbourhoodCurrent, 3),
            }
        }

        await this.promisedSetState({
            submitting: true,
        })

        authApiService.setToken(this.state.token);
        try {
            await authApiService.updateBiodata(dataUpdate);
            Modal.success({
                title: 'Proses Sukses',
                content: 'data berhasil di update'
            });
            this.setState({
                submitting: false,
            });
        } catch (e) {
            Modal.error({
                title: 'update Failed',
                content: 'error: ' + e,
            });
            this.setState({
                submitting: false,
            });
        }
    }

    async componentDidMount() {
        this.setState({
            processing: true,
        });

        try {
            await authApiService.setToken(this.state.token);
            const dataBiodata = await authApiService.getUserBiodata();
            const dataBio = dataBiodata?.data?.data;
            const dataIdCard = dataBio?.id_card;
            const dataAddress = dataBio?.address;
            const dataCurrentAddress = dataBio?.current_address;
            const dataFamilyCard = (dataBio?.other_documents ?? []).find((o: any) => o.type === 'family_register') ?? {};

            this.setState({
                processing: false,
                profileData: dataBio,

                idCardImageUrl: (dataIdCard?.id_card_image) ? dataIdCard?.id_card_image : "",
                familyCardImageUrl: (dataFamilyCard?.document_image) ? dataFamilyCard?.document_image : "",

                idCardNumber: (dataIdCard?.id_card_number) ? dataIdCard?.id_card_number : "",
                idCardName: (dataIdCard?.full_name) ? dataIdCard?.full_name : "",
                birthPlace: (dataIdCard?.birth_place) ? dataIdCard?.birth_place : "",
                birthDate: (dataIdCard?.birth_date) ? moment(new Date(dataIdCard?.birth_date), 'YYYY-MM-DD') : moment(new Date(), 'YYYY-MM-DD'),

                fullAddress: (dataAddress?.full_address) ? dataAddress?.full_address : "",
                provinceCode: (dataAddress?.province_code) ? dataAddress?.province_code : null,
                provinceName: (dataAddress?.province_name) ? dataAddress?.province_name : null,
                cityCode: (dataAddress?.city_code) ? dataAddress?.city_code : null,
                cityName: (dataAddress?.city_name) ? dataAddress?.city_name : null,
                districtCode: (dataAddress?.district_code) ? dataAddress?.district_code : null,
                districtName: (dataAddress?.district_name) ? dataAddress?.district_name : null,
                subdistrictCode: (dataAddress?.sub_district_code) ? dataAddress?.sub_district_code : null,
                subdistrictName: (dataAddress?.sub_district_name) ? dataAddress?.sub_district_name : null,
                postalCode: (dataAddress?.postal_code) ? dataAddress?.postal_code : null,
                hamlet: (dataAddress?.hamlet) ? dataAddress?.hamlet : null,
                neighbourhood: (dataAddress?.neighbourhood) ? dataAddress?.neighbourhood : null,

                fullAddressCurrent: (dataCurrentAddress?.full_address) ? dataCurrentAddress?.full_address : "",
                provinceCodeCurrent: (dataCurrentAddress?.province_code) ? dataCurrentAddress?.province_code : null,
                provinceNameCurrent: (dataCurrentAddress?.province_name) ? dataCurrentAddress?.province_name : null,
                cityCodeCurrent: (dataCurrentAddress?.city_code) ? dataCurrentAddress?.city_code : null,
                cityNameCurrent: (dataCurrentAddress?.city_name) ? dataCurrentAddress?.city_name : null,
                districtCodeCurrent: (dataCurrentAddress?.district_code) ? dataCurrentAddress?.district_code : null,
                districtNameCurrent: (dataCurrentAddress?.district_name) ? dataCurrentAddress?.district_name : null,
                subdistrictCodeCurrent: (dataCurrentAddress?.sub_district_code) ? dataCurrentAddress?.sub_district_code : null,
                subdistrictNameCurrent: (dataCurrentAddress?.sub_district_name) ? dataCurrentAddress?.sub_district_name : null,
                postalCodeCurrent: (dataCurrentAddress?.postal_code) ? dataCurrentAddress?.postal_code : null,
                hamletCurrent: (dataCurrentAddress?.hamlet) ? dataCurrentAddress?.hamlet : null,
                neighbourhoodCurrent: (dataCurrentAddress?.neighbourhood) ? dataCurrentAddress?.neighbourhood : null,

                familyCardNumber: (dataFamilyCard?.document_number) ? dataFamilyCard?.document_number : "",
            });
        } catch (e) {
            console.log(e);
            this.setState({
                processing: false,
            });
        }
    }

    clickUpdateIdCardImage = () => {
        if (this.state.idCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KTP tidak valid"});
            return;
        }
        this.setState({showModalUploadIdCardOwner: true});
    }

    clickUpdateFamilyCardImage = () => {
        if (this.state.familyCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KK tidak valid"});
            return;
        }
        this.setState({showModalUploadFamilyCard: true});
    }

    onCropIdCardOwner = async (base64DataImagePng: any) => {
        await this.promisedSetState({
            base64IdCardOwner: base64DataImagePng,
        });
    }
    saveOwnerIdCardData = async () => {
        if (this.state.base64IdCardOwner?.length < 10) {
            Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"});
            await this.promisedSetState({
                showOldUploadRawImage: true,
            });
            return;
        }

        let randomString = (Math.random() + 1).toString(36).substring(7);
        const dataUpload = {
            id_image: "amartavip-idcard-" + randomString + "-" + this.state.idCardNumber,
            image_data: this.state.base64IdCardOwner,
        }

        await this.uploadImageAndUpdateState("IDCARDOWNER_IMAGE", dataUpload);
    }

    onCropFamilyCard = async (base64DataImagePng: any) => {
        await this.setState({
            base64FamilyCard: base64DataImagePng,
        });
        window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: "smooth"});
    }

    saveFamilyCardData = async () => {
        let randomString = (Math.random() + 1).toString(36).substring(7);
        const dataUpload = {
            id_image: "amartavip-familycard-" + randomString + "-" + this.state.familyCardNumber,
            image_data: this.state.base64FamilyCard,
        }

        await this.uploadImageAndUpdateState("FAMILYCARD_IMAGE", dataUpload);
    }

    uploadImageAndUpdateState = async (type: string, dataUpload: any) => {
        if (type === "FAMILYCARD_IMAGE") {
            if (!this.state.base64FamilyCard) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image KK"});
                return;
            }
        }
        if (type === "IDCARDOWNER_IMAGE") {
            if (!this.state.base64IdCardOwner) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image KTP Pemilik"});
                return;
            }
        }

        await this.setState({
            freezeStatus: true,
        });

        try {
            await cdnApiService.uploadIdCardBase64(dataUpload).then(async successData => {
                if (type === "FAMILYCARD_IMAGE") {
                    await this.promisedSetState({
                        familyCardImageUrl: successData?.data?.data?.url_document,
                    });
                }
                if (type === "IDCARDOWNER_IMAGE") {
                    await this.promisedSetState({
                        idCardImageUrl: successData?.data?.data?.url_document,
                    });
                }

                await this.promisedSetState({
                    freezeStatus: false,
                    showModalUploadFamilyCard: false,
                    showModalUploadIdCardOwner: false,
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.setState({
                freezeStatus: false
            });
        }
    }

    uploadImageChange = (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document;
            this.setState({
                freezeStatus: false,
                idCardImageUrl: newImgLocation,
            });
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                freezeStatus: true,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                freezeStatus: false,
                idCardImageUrl: "",
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Row style={{backgroundColor: '#fff', paddingBottom: 5, borderBottom: "#f0f2f5 2px solid"}}>
                        <Col xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <MyProfileLoginHeader title={"My Biodata"}/>
                        </Col>
                    </Row>
                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={{minHeight: '79vh', backgroundColor: "#fff", padding: "2vh"}}>
                            <div style={this.state.processing ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                            </div>
                            <div style={!this.state.processing ? {} : {display: 'none'}}>
                                <Form layout="vertical">
                                    <div style={{marginBottom: 10, width: "100%", border: 1, borderColor: "grey", backgroundColor: "#eeeeee", padding: 5, fontSize: "80%"}}>
                                        <i>*Data dapat digunakan untuk mempercepat pengisian form pada saat anda melakukan transaksi tunai maupun kredit.</i>
                                    </div>
                                    <Tabs
                                        defaultActiveKey="1"
                                        items={[
                                            {
                                                label: `Data KTP`,
                                                key: '1',
                                                children:
                                                    <React.Fragment>
                                                        <Row>
                                                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 10}}>
                                                                <Form.Item>
                                                                    <i className={`text-small-grey-nopadding`}>Nomor KTP *</i>
                                                                    <Input
                                                                        maxLength={16}
                                                                        value={this.state.idCardNumber}
                                                                        placeholder="Nomor Sesuai KTP"
                                                                        onChange={event => this.onFieldChange("idCardNumber", event.target.value)}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <i className={`text-small-grey-nopadding`}>Nama KTP *</i>
                                                                    <Input
                                                                        value={this.state.idCardName}
                                                                        placeholder="Nama Sesuai KTP"
                                                                        onChange={event => this.onFieldChange("idCardName", event.target.value)}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <i className={`text-small-grey-nopadding`}>Tempat Lahir *</i>
                                                                    <Input
                                                                        value={this.state.birthPlace}
                                                                        placeholder="Tempat Lahir Sesuai KTP"
                                                                        onChange={event => this.onFieldChange("birthPlace", event.target.value)}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item className={'padTop-7'}>
                                                                    <i className={`text-small-grey-nopadding`}>Tanggal Lahir *</i>
                                                                    <DatePicker value={moment(this.state.birthDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("birthDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                                                                </Form.Item>
                                                                <div className={`card-box-shadow`}>
                                                                    <Row>
                                                                        <Col span={24}>
                                                                            <IdcardOutlined className={`icon-title-info`}/>
                                                                            <b className={`text-title-info`}>KTP</b>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row style={{paddingTop: 10}}>
                                                                        <Col span={23} offset={1}>
                                                                            <Row>
                                                                                <Col span={12}>
                                                                                    <div className="font-old-grey-100">
                                                                                        <i className={`text-extra-small-grey-nopadding`}>Nomor KTP:</i>
                                                                                        <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.idCardNumber} </div>
                                                                                        <i className={`text-extra-small-grey-nopadding`}>Nama:</i>
                                                                                        <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.idCardName} </div>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col span={12}>
                                                                                    {this.state.idCardImageUrl
                                                                                        ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.idCardImageUrl}/></div>
                                                                                        : <div onClick={() => this.clickUpdateIdCardImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                                                            <div onClick={() => this.clickUpdateIdCardImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                                                                {this.state.idCardImageUrl
                                                                                    ? <span>Update Gambar KTP</span>
                                                                                    : <span>Lengkapi Gambar KTP</span>
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 10}}>
                                                                <Form.Item>
                                                                    <i className={`text-small-grey-nopadding`}>Alamat KTP *</i>
                                                                    <TextArea showCount maxLength={200} value={this.state.fullAddress} onChange={event => this.onFieldChange("fullAddress", event.target.value)} placeholder="Alamat Sesuai KTP"/>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <i className={`text-small-grey-nopadding`}>Provinsi *</i>
                                                                    <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceChange} country={"idn"} provinceCode={this.state.provinceCode} provinceName={this.state.provinceName}/>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <i className={`text-small-grey-nopadding`}>Kota / Kabupaten *</i>
                                                                    <AreaCitySelect onAreaCityChangeProps={this.onAreaCityChange} provinceCode={this.state.provinceCode} cityName={this.state.cityName}/>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <i className={`text-small-grey-nopadding`}>Kecamatan *</i>
                                                                    <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictChange} cityCode={this.state.cityCode} districtName={this.state.districtName}/>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <i className={`text-small-grey-nopadding`}>Kelurahan *</i>
                                                                    <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictChange} districtCode={this.state.districtCode} subdistrictName={this.state.subdistrictName}/>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <i className={`text-small-grey-nopadding`}>Kode Pos *</i>
                                                                    <Input
                                                                        value={this.state.postalCode}
                                                                        placeholder="Kodepos"
                                                                        onChange={event => this.onFieldChange("postalCode", event.target.value)}
                                                                    />
                                                                </Form.Item>
                                                                <Row>
                                                                    <Col span={12} style={{paddingRight: '2px'}}>
                                                                        <Form.Item>
                                                                            <i className={`text-small-grey-nopadding`}>RT</i>
                                                                            <Input maxLength={3} value={this.state.hamlet} onChange={event => this.onFieldChange("hamlet", event.target.value)} placeholder="RT"/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} style={{paddingLeft: '2px'}}>
                                                                        <Form.Item>
                                                                            <i className={`text-small-grey-nopadding`}>RW</i>
                                                                            <Input maxLength={3} value={this.state.neighbourhood} onChange={event => this.onFieldChange("neighbourhood", event.target.value)} placeholder="RW"/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>

                                                        <br/>
                                                        <Button style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} loading={this.state.submitting} onClick={() => this.onUpdateIdCard()} type="primary"> Update Data KTP</Button>
                                                    </React.Fragment>,
                                            },
                                            {
                                                label: `Data KK`,
                                                key: '2',
                                                children:
                                                    <React.Fragment>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Nomor KK *</i>
                                                            <Input
                                                                maxLength={16}
                                                                value={this.state.familyCardNumber}
                                                                placeholder="Nomor Sesuai KK"
                                                                onChange={event => this.onFieldChange("familyCardNumber", event.target.value)}
                                                            />
                                                        </Form.Item>
                                                        <div className={`card-box-shadow`}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <IdcardOutlined className={`icon-title-info`}/>
                                                                    <b className={`text-title-info`}>Kartu Keluarga</b>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{paddingTop: 10}}>
                                                                <Col span={23} offset={1}>
                                                                    <Row>
                                                                        <Col span={12}>
                                                                            <div className="font-old-grey-100">
                                                                                <i className={`text-extra-small-grey-nopadding`}>Nomor KK:</i>
                                                                                <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.familyCardNumber} </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            {this.state.familyCardImageUrl
                                                                                ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.familyCardImageUrl}/></div>
                                                                                : <div onClick={() => this.clickUpdateFamilyCardImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                                                    <div onClick={() => this.clickUpdateFamilyCardImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                                                        {this.state.familyCardImageUrl
                                                                            ? <span>Update Gambar Kartu Keluarga</span>
                                                                            : <span>Lengkapi Gambar Kartu Keluarga</span>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <br/>

                                                        <Button style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} loading={this.state.submitting} onClick={() => this.onUpdateFamilyCard()} type="primary"> Update Data KK</Button>
                                                    </React.Fragment>,
                                            },
                                            {
                                                label: `Alamat Saat Ini`,
                                                key: '3',
                                                children:
                                                    <React.Fragment>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Alamat Saat Ini *</i>
                                                            <TextArea showCount maxLength={200} value={this.state.fullAddressCurrent} onChange={event => this.onFieldChange("fullAddressCurrent", event.target.value)} placeholder="Alamat Saat Ini"/>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Provinsi Saat Ini *</i>
                                                            <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceCurrentChange} country={"idn"} provinceCode={this.state.provinceCodeCurrent} provinceName={this.state.provinceNameCurrent}/>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Kota / Kabupaten Saat Ini *</i>
                                                            <AreaCitySelect onAreaCityChangeProps={this.onAreaCityCurrentChange} provinceCode={this.state.provinceCodeCurrent} cityName={this.state.cityNameCurrent}/>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Kecamatan Saat Ini *</i>
                                                            <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictCurrentChange} cityCode={this.state.cityCodeCurrent} districtName={this.state.districtNameCurrent}/>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Kelurahan Saat Ini *</i>
                                                            <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictCurrentChange} districtCode={this.state.districtCodeCurrent} subdistrictName={this.state.subdistrictNameCurrent}/>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Kode Pos Saat Ini *</i>
                                                            <Input
                                                                value={this.state.postalCodeCurrent}
                                                                placeholder="Kodepos"
                                                                onChange={event => this.onFieldChange("postalCodeCurrent", event.target.value)}
                                                            />
                                                        </Form.Item>
                                                        <Row>
                                                            <Col span={12} style={{paddingRight: '2px'}}>
                                                                <Form.Item>
                                                                    <i className={`text-small-grey-nopadding`}>RT Saat Ini</i>
                                                                    <Input maxLength={3} value={this.state.hamletCurrent} onChange={event => this.onFieldChange("hamletCurrent", event.target.value)} placeholder="RT"/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12} style={{paddingLeft: '2px'}}>
                                                                <Form.Item>
                                                                    <i className={`text-small-grey-nopadding`}>RW Saat Ini</i>
                                                                    <Input maxLength={3} value={this.state.neighbourhoodCurrent} onChange={event => this.onFieldChange("neighbourhoodCurrent", event.target.value)} placeholder="RW"/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Button style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} loading={this.state.submitting} onClick={() => this.onUpdateCurrentAddress()} type="primary"> Update Data Alamat Saat Ini</Button>
                                                    </React.Fragment>,
                                            },
                                        ]}
                                    />
                                </Form>
                            </div>
                        </div>
                    </Col>
                    <FooterSection areaCode={'bandung'} version={appVersion} lite={true}/>
                </Layout>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>

                <Modal
                    title="Lengkapi Data KTP"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadIdCardOwner}
                    onCancel={() => this.setState({showModalUploadIdCardOwner: false})}
                    footer={[
                        <Button style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}} key={'saveCustomer'} type="primary" onClick={this.saveOwnerIdCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadIdCardOwner: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical" style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64IdCardOwner) ? "0" : "1"}>
                                <ul style={{fontSize: "80%"}}>
                                    <li>Klik Tombol Upload Gambar KTP Pemilik</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardOwner}/>
                        </Form.Item>
                    </Form>

                    <Form layout="vertical" style={this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Upload
                            method="POST"
                            action={baseUrlCdnUpload + "/upload-document/id-card"}
                            listType="picture"
                            maxCount={1}
                            data={{
                                id_card_number: this.state.idCardNumber,
                                source: "amartahonda.com",
                                notes: "my-biodata"
                            }}
                            name="image"
                            onChange={this.uploadImageChange}
                        > <Button icon={<UploadOutlined/>}>Upload Ulang Gambar KTP</Button>
                        </Upload>
                    </Form>
                </Modal>

                <Modal
                    title="Lengkapi Data KK"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadFamilyCard}
                    onCancel={() => this.setState({showModalUploadFamilyCard: false})}
                    footer={[
                        <Button key={'saveCustomer'} type="primary" onClick={this.saveFamilyCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadFamilyCard: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical">
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64FamilyCard) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", paddingLeft: 20}}>
                                    <li>Klik Tombol Upload Gambar KK</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropFamilyCard}/>
                        </Form.Item>
                    </Form>
                </Modal>

            </React.Fragment>
        );
    }
}

export default withAuth(MyBiodata);
