import React, {Component} from 'react';
import {Layout, Image, Button, Card, Divider, Form, Input, Row, Modal} from "antd";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import Cookies from 'universal-cookie';
import {withRouter} from "../../hoc/withRouter";
import Col from "antd/lib/col";
import {appVersion} from "../../config/dataConfig/appConfig";
import {authApiService} from "../../services/amartaVip/AuthApiService";

const {Footer} = Layout;

export interface ResetPasswordProps {
}

export interface ResetPasswordStates extends ResetPasswordFields {
    processing: boolean;
}

export interface ResetPasswordFields {
    email: string;
    password: string;
    retypePassword: string;
    otpCode: string;
}

class ResetPassword extends Component<ResetPasswordProps & any, ResetPasswordStates> {
    private cookies = new Cookies();
    private readonly initState!: ResetPasswordStates;

    constructor(props: ResetPasswordProps & any) {
        super(props);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let otpCode = params.get('c') ?? "";

        this.initState = {
            processing: false,
            email: "",
            password: "",
            retypePassword: "",
            otpCode: otpCode
        }

        this.state = {...this.initState}
    }

    onFieldChange = <T extends keyof ResetPasswordFields>(target: T, value: string) => {
        const currentState: ResetPasswordStates = {...this.state};
        switch (target) {
            case "email":
                currentState.email = value;
                break;
            case "password":
                currentState.password = value;
                break;
            case "retypePassword":
                currentState.retypePassword = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    submit = async () => {
        const email = this.state.email;
        const password = this.state.password;
        const retypePassword = this.state.retypePassword;

        if (!email || !password || !retypePassword) {
            Modal.error({
                title: 'Error',
                content: "Lengkapi data"
            });
            return false;
        }

        if (password !== retypePassword) {
            Modal.error({
                title: 'Error',
                content: "Password dengan Password yang diketik ulang tidak sama"
            });
            return false;
        }

        this.setState({
            processing: true,
        });

        const dataReset = {
            media: "email",
            email: this.state.email,
            otp: this.state.otpCode,
            password: this.state.password
        }

        try {
            await authApiService.resetPassword(dataReset);
            this.setState({
                processing: false,
            });

            Modal.success({
                title: 'Proses Sukses',
                content: 'Reset email sukses, dalam 3 detik kedepan anda akan diarahkan ke halaman login'
            });

            await new Promise(resolve => setTimeout(resolve, 3000));
            this.props.history.push('/login');
        } catch (e) {
            Modal.error({
                title: 'Reset Password Failed',
                content: e + ' ',
            });
            this.setState({
                processing: false,
            });
        }

    }

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={!this.state.otpCode ? {minHeight: '85vh', backgroundColor: "#fff", padding: "5vh"} : {display: 'none'}}>
                            You have no access
                        </div>

                        <div style={this.state.otpCode ? {minHeight: '85vh', backgroundColor: "#fff", padding: "5vh"} : {display: 'none'}}>
                            <Card type="inner" style={{width: "100%"}}>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 50}}>
                                    <Image style={{height: 80}} preview={false} src={`/assets/img/favicon.png`}/>
                                </div>
                                <div style={{textAlign: "center", paddingTop: 10, fontSize: "120%", fontWeight: 600}}>ResetPassword</div>
                                <Divider/>
                                <Row>
                                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                        <div className="content-main-form">
                                            <Form>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Ketik Ulang Email</i>
                                                    <Input prefix={<MailOutlined/>} placeholder={'Email'} value={this.state.email} onChange={event => this.onFieldChange("email", event.target.value)}/>
                                                </Form.Item>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Password Baru</i>
                                                    <Input prefix={<LockOutlined/>} type={'password'} placeholder={'Password'} value={this.state.password} onChange={event => this.onFieldChange("password", event.target.value)}/>
                                                </Form.Item>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Ketik Ulang Password</i>
                                                    <Input prefix={<LockOutlined/>} type={'password'} placeholder={'Password'} value={this.state.retypePassword} onChange={event => this.onFieldChange("retypePassword", event.target.value)}/>
                                                </Form.Item>
                                                <div style={{margin: '25px 0'}}>
                                                    <Button style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} block={true} onClick={this.submit} loading={this.state.processing} type={'primary'} htmlType={'submit'}> Reset Password</Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </div>

                        <Footer style={{textAlign: 'center', backgroundColor: "#fff"}}>
                            <Divider/>
                            Amartahonda ©{new Date().getFullYear()} <br/>v-{appVersion}
                        </Footer>

                    </Col>
                </Layout>

            </React.Fragment>
        );
    }
}

export default withRouter(ResetPassword);
