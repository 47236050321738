export default function navigatorShare(title: string, text: string, url: string) {
    if (navigator.share) {
        navigator.share({
            title: title,
            text: text,
            url: url
        }).then(() => {
            console.log('Thanks for sharing!');
        })
            .catch(err => {
                console.log(`Couldn't share because of`, err.message);
            });
    } else {
        console.log('web share not supported');
    };
}
