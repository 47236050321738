import React from "react";
import "./css/DetailProductLink.css";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import CalculatorOutlined from '@ant-design/icons/CalculatorOutlined';
import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';
import PercentageOutlined from '@ant-design/icons/PercentageOutlined';

const DetailProductLink = (props: any) => {
    return (
        <React.Fragment>
            {props.link ? (
                <a href={props?.link ?? "#"} target={props?.target ?? "_self"}>
                    <div className={`card-simple-simulation`}>
                        <Row>
                            <Col span={20}>
                                <CalculatorOutlined style={props.icon === 'CalculatorOutlined' ? {} : {display: 'none'}} className={`icon-simple-simulation`}/>
                                <SyncOutlined style={props.icon === 'SyncOutlined' ? {} : {display: 'none'}} className={`icon-simple-simulation`}/>
                                <DashboardOutlined style={props.icon === 'DashboardOutlined' ? {} : {display: 'none'}} className={`icon-simple-simulation`}/>
                                <PercentageOutlined style={props.icon === 'PercentageOutlined' ? {} : {display: 'none'}} className={`icon-simple-simulation`}/>
                                <span className={`title-simple-simulation`}>{props.title}</span>
                            </Col>
                            <Col span={4}>
                                <DoubleRightOutlined className={`righticon-simple-simulation`} style={{float: "right"}}/>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={2}>
                                &nbsp;
                            </Col>
                            <Col span={22}>
                                <Row className={'mt-12'}>
                                    <Col span={24} className={'a-text-simple-simulation'}>
                                        {props.description}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </a>
            ) : (
                <div className={`card-simple-simulation`}>
                    <Row>
                        <Col span={20}>
                            <CalculatorOutlined style={props.icon === 'CalculatorOutlined' ? {} : {display: 'none'}} className={`icon-simple-simulation`}/>
                            <SyncOutlined style={props.icon === 'SyncOutlined' ? {} : {display: 'none'}} className={`icon-simple-simulation`}/>
                            <DashboardOutlined style={props.icon === 'DashboardOutlined' ? {} : {display: 'none'}} className={`icon-simple-simulation`}/>
                            <PercentageOutlined style={props.icon === 'PercentageOutlined' ? {} : {display: 'none'}} className={`icon-simple-simulation`}/>
                            <span className={`title-simple-simulation`}>{props.title}</span>
                        </Col>
                        <Col span={4}>
                            <DoubleRightOutlined className={`righticon-simple-simulation`} style={{float: "right"}}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={2}>
                            &nbsp;
                        </Col>
                        <Col span={22}>
                            <Row className={'mt-12'}>
                                <Col span={24} className={'a-text-simple-simulation'}>
                                    {props.description}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            )}

        </React.Fragment>
    )
        ;
}
export default DetailProductLink;
