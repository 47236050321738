import React, {Component} from "react";
import {connect} from "react-redux";
import TextArea from "antd/es/input/TextArea";
import AreaProvinceSelect from "../area/AreaProvinceSelect";
import AreaCitySelect from "../area/AreaCitySelect";
import AreaDistrictSelect from "../area/AreaDistrictSelect";
import AreaSubdistrictSelect from "../area/AreaSubdistrictSelect";
import CustomerMaritalStatus from "../customer/CustomerMaritalStatus";
import CustomerPlaceToStay from "../customer/CustomerPlaceToStay";
import '../../screen/newVehicleVariantDetailCredit/NewVehicleVariantDetailFormCredit.css';
import CustomerMariageAge from "../customer/CustomerMariageAge";
import CustomerOccupation from "../customer/CustomerOccupation";
import CustomerOwnerRelation from "../customer/CustomerOwnerRelation";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import CustomerLastEducation from "../customer/CustomerLastEducation";
import Checkbox from "antd/lib/checkbox";

class CreditSubmissionOrderMaker extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            dataStatusUpdateImage: false,
            guarantorDisablePostalCode: false,
            guarantorDisableCurrentAddressPostalCode: false,
        }
        this.state = {...this.initState}
    }

    componentDidMount() {
        this.setState({
            guarantorDisablePostalCode: !!(this.props.guarantorPostalCode),
            guarantorDisableCurrentAddressPostalCode: !!(this.props.guarantorCurrentAddressPostalCode)
        });
    }

    onCustomerMaritalStatusChangeChange = (data: any) => {
        this.props.changeOwnerMaritalStatus(data.value);
        this.props.changeOwnerMaritalStatusName(data.children);
    }

    onCustomerOccupationChangeChange = (data: any) => {
        this.props.changeOwnerOccupationCode(data.value);
        this.props.changeOwnerOccupationName(data.children);
    }

    onCustomerLastEducationChange = (data: any) => {
        this.props.changeOwnerLastEducationCode(data.value);
        this.props.changeOwnerLastEducationName(data.children);
    }

    onCustomerPlaceToStayChangeChange = (data: any) => {
        this.props.changeOwnerPlaceToStayCode(data.value);
        this.props.changeOwnerPlaceToStayName(data.children);
    }

    startDateChange = (data: any) => {
        if (data) {
            this.props.changeGuarantorBirthDate(data);
        }
    }

    onFieldChange = <T extends keyof Pick<any, "guarantorAndOwnerDifferent" | "guarantorCurrentAddressDifferent" | "guarantorCurrentAddressFullAddress" | "guarantorCurrentAddressPostalCode" | "guarantorCurrentAddressHamlet" | "guarantorCurrentAddressNeighbourhood" | "familyCardNumber" | "birthMother" | "guarantorIdCardNumber" | "guarantorIdCardName" | "guarantorFullAddress" | "guarantorMaritalStatus" | "guarantorPostalCode" | "guarantorDifferentAddress" | "guarantorBirthPlace" | "guarantorHamlet" | "guarantorNeighbourhood">>(target: T, value: any) => {
        const currentState = {...this.state};
        switch (target) {
            case "guarantorAndOwnerDifferent":
                this.props.changeGuarantorAndOwnerDifferent(value);
                break;
            case "guarantorCurrentAddressDifferent":
                this.props.changeGuarantorCurrentAddressDifferent(value);
                if (!value) {
                    this.props.changeGuarantorCurrentAddressProvinceCode(null);
                    this.props.changeGuarantorCurrentAddressProvinceName(null);
                    this.props.changeGuarantorCurrentAddressCityCode(null);
                    this.props.changeGuarantorCurrentAddressCityName(null);
                    this.props.changeGuarantorCurrentAddressDistrictCode(null);
                    this.props.changeGuarantorCurrentAddressDistrictName(null);
                    this.props.changeGuarantorCurrentAddressSubdistrictCode(null);
                    this.props.changeGuarantorCurrentAddressSubdistrictName(null);
                    this.props.changeGuarantorCurrentAddressPostalCode("");
                    this.props.changeGuarantorCurrentAddressFullAddress("");
                    this.props.changeGuarantorCurrentAddressHamlet("000");
                    this.props.changeGuarantorCurrentAddressNeighbourhood("000");
                }
                break;
            case "guarantorCurrentAddressFullAddress":
                this.props.changeGuarantorCurrentAddressFullAddress(value);
                break;
            case "guarantorCurrentAddressPostalCode":
                this.props.changeGuarantorCurrentAddressPostalCode(value.replace(/[^0-9.]/g, ''));
                break;
            case "guarantorCurrentAddressHamlet":
                this.props.changeGuarantorCurrentAddressHamlet(value.replace(/[^0-9.]/g, ''));
                break;
            case "guarantorCurrentAddressNeighbourhood":
                this.props.changeGuarantorCurrentAddressNeighbourhood(value.replace(/[^0-9.]/g, ''));
                break;
            case "familyCardNumber":
                this.props.changeFamilyCardNumber(value.replace(/[^0-9.]/g, ''));
                break;
            case "birthMother":
                this.props.changeBirthMother(value?.toUpperCase()?.replace(/[^a-zA-Z0-9\s]/g, ''));
                break;
            case "guarantorIdCardNumber":
                this.props.changeGuarantorIdCardNumber(value.replace(/[^0-9.]/g, ''));
                break;
            case "guarantorIdCardName":
                this.props.changeGuarantorIdCardName(value?.toUpperCase()?.replace(/[^a-zA-Z0-9\s]/g, ''));
                break;
            case "guarantorFullAddress":
                this.props.changeGuarantorFullAddress(value);
                break;
            case "guarantorPostalCode":
                this.props.changeGuarantorPostalCode(value.replace(/[^0-9.]/g, ''));
                break;
            case "guarantorDifferentAddress":
                this.props.changeGuarantorDifferentAddress(value);
                break;
            case "guarantorBirthPlace":
                this.props.changeGuarantorBirthPlace(value);
                break;
            case "guarantorHamlet":
                this.props.changeGuarantorHamlet(value.replace(/[^0-9.]/g, ''));
                break;
            case "guarantorNeighbourhood":
                this.props.changeGuarantorNeighbourhood(value.replace(/[^0-9.]/g, ''));
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    onAreaProvinceChange = (data: any) => {
        this.props.changeGuarantorProvinceCode(data.value);
        this.props.changeGuarantorProvinceName(data.children);
        this.props.changeGuarantorCityCode(null);
        this.props.changeGuarantorCityName(null);
        this.props.changeGuarantorDistrictCode(null);
        this.props.changeGuarantorDistrictName(null);
        this.props.changeGuarantorSubdistrictCode(null);
        this.props.changeGuarantorSubdistrictName(null);
        this.props.changeGuarantorPostalCode("");
    }

    onAreaCityChange = (data: any) => {
        this.props.changeGuarantorCityCode(data.value);
        this.props.changeGuarantorCityName(data.children);
        this.props.changeGuarantorDistrictCode(null);
        this.props.changeGuarantorDistrictName(null);
        this.props.changeGuarantorSubdistrictCode(null);
        this.props.changeGuarantorSubdistrictName(null);
        this.props.changeGuarantorPostalCode("");
    }

    onAreaDistrictChange = (data: any) => {
        this.props.changeGuarantorDistrictCode(data.value);
        this.props.changeGuarantorDistrictName(data.children);
        this.props.changeGuarantorSubdistrictCode(null);
        this.props.changeGuarantorSubdistrictName(null);
        this.props.changeGuarantorPostalCode("");
    }

    onAreaSubdistrictChange = (data: any) => {
        this.props.changeGuarantorSubdistrictCode(data.value);
        this.props.changeGuarantorSubdistrictName(data.children);
        this.props.changeGuarantorPostalCode(data.postalCode);
        this.setState({
            guarantorDisablePostalCode: !!(data.postalCode)
        });
    }

    onCustomerMariageAgeChange = (data: any) => {
        this.props.changeGuarantorMarriageAge(data.value);
    }

    onCustomerOwnerRelationChange = (data: any) => {
        this.props.changeGuarantorOwnerRelation(data.value);
    }

    onGuarantorCurrentAddressProvinceChange = (data: any) => {
        this.props.changeGuarantorCurrentAddressProvinceCode(data.value);
        this.props.changeGuarantorCurrentAddressProvinceName(data.children);
        this.props.changeGuarantorCurrentAddressCityCode(null);
        this.props.changeGuarantorCurrentAddressCityName(null);
        this.props.changeGuarantorCurrentAddressDistrictCode(null);
        this.props.changeGuarantorCurrentAddressDistrictName(null);
        this.props.changeGuarantorCurrentAddressSubdistrictCode(null);
        this.props.changeGuarantorCurrentAddressSubdistrictName(null);
        this.props.changeGuarantorCurrentAddressPostalCode("");
    }

    onGuarantorCurrentAddressCityChange = (data: any) => {
        this.props.changeGuarantorCurrentAddressCityCode(data.value);
        this.props.changeGuarantorCurrentAddressCityName(data.children);
        this.props.changeGuarantorCurrentAddressDistrictCode(null);
        this.props.changeGuarantorCurrentAddressDistrictName(null);
        this.props.changeGuarantorCurrentAddressSubdistrictCode(null);
        this.props.changeGuarantorCurrentAddressSubdistrictName(null);
        this.props.changeGuarantorCurrentAddressPostalCode("");
    }

    onGuarantorCurrentAddressDistrictChange = (data: any) => {
        this.props.changeGuarantorCurrentAddressDistrictCode(data.value);
        this.props.changeGuarantorCurrentAddressDistrictName(data.children);
        this.props.changeGuarantorCurrentAddressSubdistrictCode(null);
        this.props.changeGuarantorCurrentAddressSubdistrictName(null);
        this.props.changeGuarantorCurrentAddressPostalCode("");
    }

    onGuarantorCurrentAddressSubdistrictChange = (data: any) => {
        this.props.changeGuarantorCurrentAddressSubdistrictCode(data.value);
        this.props.changeGuarantorCurrentAddressSubdistrictName(data.children);
        this.props.changeGuarantorCurrentAddressPostalCode(data.postalCode);

        this.setState({
            guarantorDisableCurrentAddressPostalCode: !!(data.postalCode)
        });
    }

    render() {
        return (
            <React.Fragment>
                <Form layout="vertical">

                    <div style={{marginBottom: 10, width: "100%", border: 1, borderColor: "grey", backgroundColor: "#eeeeee", padding: 5, fontSize: "80%"}}>
                        <i>*Data Pemohon digunakan sebagai persyartan data leasing</i>
                    </div>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Nomor KTP Pemohon *</i>
                        <Input
                            maxLength={16}
                            value={this.props.guarantorIdCardNumber}
                            placeholder="Nomor Sesuai KTP"
                            onChange={event => this.onFieldChange("guarantorIdCardNumber", event.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Nama KTP Pemohon *</i>
                        <Input
                            value={this.props.guarantorIdCardName}
                            placeholder="Nama Sesuai KTP"
                            onChange={event => this.onFieldChange("guarantorIdCardName", event.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Tempat Lahir Pemohon *</i>
                        <Input
                            value={this.props.guarantorBirthPlace}
                            placeholder="Tempat Lahir Sesuai KTP Pemohon"
                            onChange={event => this.onFieldChange("guarantorBirthPlace", event.target.value)}
                        />
                    </Form.Item>

                    <Form.Item className={'padTop-7'}>
                        <i className={`text-small-grey-nopadding`}>Tanggal Lahir Pemohon *</i>
                        <DatePicker value={moment(this.props.guarantorBirthDate, 'YYYY-MM-DD')} onChange={event => this.startDateChange(event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Status Perkawinan *</i>
                        <CustomerMaritalStatus onCustomerMaritalStatusChangeProps={this.onCustomerMaritalStatusChangeChange} value={this.props.ownerMaritalStatus}/>
                    </Form.Item>

                    {/*<Form.Item style={(this.props.ownerMaritalStatus === 'married') ? {} : {display: 'none'}}>*/}
                    <Form.Item style={{display: "none"}}>
                        <i className={`text-small-grey-nopadding`}>Usia Pernikahan</i>
                        <CustomerMariageAge onCustomerMariageAgeChangeProps={this.onCustomerMariageAgeChange} value={this.props.guarantorMarriageAge}/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Pekerjaan *</i>
                        <CustomerOccupation onCustomerOccupationChangeProps={this.onCustomerOccupationChangeChange} value={this.props.ownerOccupationCode}/>
                    </Form.Item>

                    <Form.Item style={{display: "none"}}>
                        <i className={`text-small-grey-nopadding`}>Pendidikan Terakhir</i>
                        <CustomerLastEducation onCustomerLastEducationChangeProps={this.onCustomerLastEducationChange} value={this.props.ownerLastEducationCode}/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Status Tempat Tinggal *</i>
                        <CustomerPlaceToStay onCustomerPlaceToStayChangeProps={this.onCustomerPlaceToStayChangeChange} value={this.props.ownerPlaceToStayCode}/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Alamat KTP Pemohon *</i>
                        <TextArea showCount maxLength={200} value={this.props.guarantorFullAddress} onChange={event => this.onFieldChange("guarantorFullAddress", event.target.value)} placeholder="Alamat Sesuai KTP"/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Provinsi KTP Pemohon *</i>
                        <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceChange} country={"idn"} provinceCode={this.props.guarantorProvinceCode} provinceName={this.props.guarantorProvinceName}/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Kota / Kabupaten KTP Pemohon *</i>
                        <AreaCitySelect onAreaCityChangeProps={this.onAreaCityChange} provinceCode={this.props.guarantorProvinceCode} cityName={this.props.guarantorCityName}/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Kecamatan KTP Pemohon *</i>
                        <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictChange} cityCode={this.props.guarantorCityCode} districtName={this.props.guarantorDistrictName}/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Kelurahan KTP Pemohon *</i>
                        <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictChange} districtCode={this.props.guarantorDistrictCode} subdistrictName={this.props.guarantorSubdistrictName}/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Kode Pos *</i>
                        <Input disabled={this.state.guarantorDisablePostalCode} value={this.props.guarantorPostalCode} placeholder="Kodepos" onChange={event => this.onFieldChange("guarantorPostalCode", event.target.value)}/>
                    </Form.Item>

                    <Row>
                        <Col span={12} style={{paddingRight: '2px'}}>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>RT</i>
                                <Input maxLength={3} value={this.props.guarantorHamlet} onChange={event => this.onFieldChange("guarantorHamlet", event.target.value)} placeholder="RT"/>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{paddingLeft: '2px'}}>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>RW</i>
                                <Input maxLength={3} value={this.props.guarantorNeighbourhood} onChange={event => this.onFieldChange("guarantorNeighbourhood", event.target.value)} placeholder="RW"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Nomor Kartu Keluarga *</i>
                        <Input
                            maxLength={16}
                            value={this.props.familyCardNumber}
                            placeholder="Nomor Sesuai KK"
                            onChange={event => this.onFieldChange("familyCardNumber", event.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Nama Ibu Kandung *</i>
                        <Input
                            value={this.props.birthMother}
                            placeholder="Nama Ibu Kandung"
                            onChange={event => this.onFieldChange("birthMother", event.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Checkbox onChange={e => this.onFieldChange("guarantorCurrentAddressDifferent", e.target.checked)} checked={this.props.guarantorCurrentAddressDifferent}>
                            <i className={`text-small-grey-nopadding`}>Tempat tinggal Pemohon <b>berbeda</b> dari KTP Pemohon.</i>
                        </Checkbox>
                    </Form.Item>

                    <div style={{display: (this.props.guarantorCurrentAddressDifferent) ? 'block' : 'none'}}>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Alamat Pemohon Saat Ini</i>
                            <TextArea showCount maxLength={200} value={this.props.guarantorCurrentAddressFullAddress} onChange={event => this.onFieldChange("guarantorCurrentAddressFullAddress", event.target.value)} placeholder="Alamat Tempat Tinggal Pemohon Saat Ini"/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Provinsi Pemohon Saat Ini</i>
                            <AreaProvinceSelect onAreaProvinceChangeProps={this.onGuarantorCurrentAddressProvinceChange} country={"idn"} provinceCode={this.props.guarantorCurrentAddressProvinceCode} provinceName={this.props.guarantorCurrentAddressProvinceName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kota / Kabupaten Pemohon Saat Ini</i>
                            <AreaCitySelect onAreaCityChangeProps={this.onGuarantorCurrentAddressCityChange} provinceCode={this.props.guarantorCurrentAddressProvinceCode} cityName={this.props.guarantorCurrentAddressCityName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kecamatan Pemohon Saat Ini</i>
                            <AreaDistrictSelect onAreaDistrictChangeProps={this.onGuarantorCurrentAddressDistrictChange} cityCode={this.props.guarantorCurrentAddressCityCode} districtName={this.props.guarantorCurrentAddressDistrictName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kelurahan Pemohon Saat Ini</i>
                            <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onGuarantorCurrentAddressSubdistrictChange} districtCode={this.props.guarantorCurrentAddressDistrictCode} subdistrictName={this.props.guarantorCurrentAddressSubdistrictName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kode Pos Pemohon Saat Ini</i>
                            <Input disabled={this.state.guarantorDisableCurrentAddressPostalCode} value={this.props.guarantorCurrentAddressPostalCode} placeholder="Kodepos" onChange={event => this.onFieldChange("guarantorCurrentAddressPostalCode", event.target.value)}/>
                        </Form.Item>

                        <Row>
                            <Col span={12} style={{paddingRight: '2px'}}>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>RT</i>
                                    <Input maxLength={3} value={this.props.guarantorCurrentAddressHamlet} onChange={event => this.onFieldChange("guarantorCurrentAddressHamlet", event.target.value)} placeholder="RT"/>
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{paddingLeft: '2px'}}>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>RW</i>
                                    <Input maxLength={3} value={this.props.guarantorCurrentAddressNeighbourhood} onChange={event => this.onFieldChange("guarantorCurrentAddressNeighbourhood", event.target.value)} placeholder="RW"/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    <Form.Item>
                        <Checkbox onChange={e => this.onFieldChange("guarantorAndOwnerDifferent", e.target.checked)} checked={this.props.guarantorAndOwnerDifferent}>
                            <i className={`text-small-grey-nopadding`}>KTP pemilik <b>berbeda</b> dari KTP Pemohon.</i>
                        </Checkbox>
                    </Form.Item>
                    <div style={{display: (this.props.guarantorAndOwnerDifferent) ? 'block' : 'none'}}>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Hubungan Dengan Pemohon *</i>
                            <CustomerOwnerRelation onCustomerOwnerRelationChangeProps={this.onCustomerOwnerRelationChange} value={this.props.guarantorOwnerRelation}/>
                        </Form.Item>
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}

const reduxState = (state: any) => ({
    birthMother: state.RbirthMother,
    ownerMaritalStatus: state.RownerMaritalStatus,
    ownerMaritalStatusName: state.RownerMaritalStatusName,
    ownerOccupationCode: state.RownerOccupationCode,
    ownerOccupationName: state.RownerOccupationName,
    ownerPlaceToStayCode: state.RownerPlaceToStayCode,
    ownerPlaceToStayName: state.RownerPlaceToStayName,
    ownerLastEducationCode: state.RownerLastEducationCode,
    ownerLastEducationName: state.RownerLastEducationName,
    guarantorIdCardNumber: state.RguarantorIdCardNumber,
    guarantorIdCardName: state.RguarantorIdCardName,
    guarantorIdCardImage: state.RguarantorIdCardImage,
    guarantorBirthPlace: state.RguarantorBirthPlace,
    guarantorBirthDate: state.RguarantorBirthDate,
    guarantorFullAddress: state.RguarantorFullAddress,
    guarantorProvinceCode: state.RguarantorProvinceCode,
    guarantorProvinceName: state.RguarantorProvinceName,
    guarantorCityCode: state.RguarantorCityCode,
    guarantorCityName: state.RguarantorCityName,
    guarantorDistrictCode: state.RguarantorDistrictCode,
    guarantorDistrictName: state.RguarantorDistrictName,
    guarantorSubdistrictCode: state.RguarantorSubdistrictCode,
    guarantorSubdistrictName: state.RguarantorSubdistrictName,
    guarantorPostalCode: state.RguarantorPostalCode,
    guarantorHamlet: state.RguarantorHamlet,
    guarantorNeighbourhood: state.RguarantorNeighbourhood,

    guarantorMarriageAge: state.RguarantorMarriageAge,
    guarantorOwnerRelation: state.RguarantorOwnerRelation,

    familyCardNumber: state.RfamilyCardNumber,

    guarantorAndOwnerDifferent: state.RguarantorAndOwnerDifferent,
    guarantorCurrentAddressDifferent: state.RguarantorCurrentAddressDifferent,
    guarantorCurrentAddressFullAddress: state.RguarantorCurrentAddressFullAddress,
    guarantorCurrentAddressProvinceCode: state.RguarantorCurrentAddressProvinceCode,
    guarantorCurrentAddressProvinceName: state.RguarantorCurrentAddressProvinceName,
    guarantorCurrentAddressCityCode: state.RguarantorCurrentAddressCityCode,
    guarantorCurrentAddressCityName: state.RguarantorCurrentAddressCityName,
    guarantorCurrentAddressDistrictCode: state.RguarantorCurrentAddressDistrictCode,
    guarantorCurrentAddressDistrictName: state.RguarantorCurrentAddressDistrictName,
    guarantorCurrentAddressSubdistrictCode: state.RguarantorCurrentAddressSubdistrictCode,
    guarantorCurrentAddressSubdistrictName: state.RguarantorCurrentAddressSubdistrictName,
    guarantorCurrentAddressPostalCode: state.RguarantorCurrentAddressPostalCode,
    guarantorCurrentAddressHamlet: state.RguarantorCurrentAddressHamlet,
    guarantorCurrentAddressNeighbourhood: state.RguarantorCurrentAddressNeighbourhood,
});

const reduxDispatch = (dispatch: any) => ({
    changeBirthMother: (data: string) => dispatch({type: 'CHANGE_BIRTH_MOTHER', value: data}),
    changeOwnerMaritalStatus: (data: string) => dispatch({type: 'CHANGE_OWNER_MARITAL_STATUS', value: data}),
    changeOwnerMaritalStatusName: (data: string) => dispatch({type: 'CHANGE_OWNER_MARITAL_STATUSNAME', value: data}),
    changeOwnerOccupationCode: (data: string) => dispatch({type: 'CHANGE_OWNER_OCCUPATIONCODE', value: data}),
    changeOwnerOccupationName: (data: string) => dispatch({type: 'CHANGE_OWNER_OCCUPATIONNAME', value: data}),
    changeOwnerLastEducationCode: (data: string) => dispatch({type: 'CHANGE_OWNER_LASTEDUCATIONCODE', value: data}),
    changeOwnerLastEducationName: (data: string) => dispatch({type: 'CHANGE_OWNER_LASTEDUCATIONNAME', value: data}),
    changeOwnerPlaceToStayCode: (data: string) => dispatch({type: 'CHANGE_OWNER_PLACETOSTAYCODE', value: data}),
    changeOwnerPlaceToStayName: (data: string) => dispatch({type: 'CHANGE_OWNER_PLACETOSTAYNAME', value: data}),

    changeGuarantorIdCardNumber: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_IDCARD_NUMBER', value: data}),
    changeGuarantorIdCardName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_IDCARD_NAME', value: data}),
    changeGuarantorIdCardImage: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_IDCARD_IMAGE', value: data}),
    changeGuarantorBirthPlace: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_BIRTHPLACE', value: data}),
    changeGuarantorBirthDate: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_BIRTHDATE', value: data}),
    changeGuarantorFullAddress: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_FULLADDRESS', value: data}),
    changeGuarantorProvinceCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_PROVINCECODE', value: data}),
    changeGuarantorProvinceName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_PROVINCENAME', value: data}),
    changeGuarantorCityCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CITYCODE', value: data}),
    changeGuarantorCityName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CITYNAME', value: data}),
    changeGuarantorDistrictCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_DISTRICTCODE', value: data}),
    changeGuarantorDistrictName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_DISTRICTNAME', value: data}),
    changeGuarantorSubdistrictCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_SUBDISTRICTCODE', value: data}),
    changeGuarantorSubdistrictName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_SUBDISTRICTNAME', value: data}),
    changeGuarantorPostalCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_POSTALCODE', value: data}),
    changeGuarantorHamlet: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_HAMLET', value: data}),
    changeGuarantorNeighbourhood: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_NEIGHBOURHOOD', value: data}),

    changeGuarantorMarriageAge: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_MARRIAGEAGE', value: data}),
    changeGuarantorOwnerRelation: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_OWNERRELATION', value: data}),

    changeFamilyCardNumber: (data: string) => dispatch({type: 'CHANGE_FAMILYCARD_NUMBER', value: data}),

    changeGuarantorAndOwnerDifferent: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_AND_OWNER_DIFFERENT', value: data}),
    changeGuarantorCurrentAddressDifferent: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_DIFFERENT', value: data}),
    changeGuarantorCurrentAddressFullAddress: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_FULLADDRESS', value: data}),
    changeGuarantorCurrentAddressProvinceCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_PROVINCECODE', value: data}),
    changeGuarantorCurrentAddressProvinceName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_PROVINCENAME', value: data}),
    changeGuarantorCurrentAddressCityCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_CITYCODE', value: data}),
    changeGuarantorCurrentAddressCityName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_CITYNAME', value: data}),
    changeGuarantorCurrentAddressDistrictCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_DISTRICTCODE', value: data}),
    changeGuarantorCurrentAddressDistrictName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_DISTRICTNAME', value: data}),
    changeGuarantorCurrentAddressSubdistrictCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_SUBDISTRICTCODE', value: data}),
    changeGuarantorCurrentAddressSubdistrictName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_SUBDISTRICTNAME', value: data}),
    changeGuarantorCurrentAddressPostalCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_POSTALCODE', value: data}),
    changeGuarantorCurrentAddressHamlet: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_HAMLET', value: data}),
    changeGuarantorCurrentAddressNeighbourhood: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_NEIGHBOURHOOD', value: data}),
});

export default connect(reduxState, reduxDispatch)(CreditSubmissionOrderMaker);
