import React, {Component} from 'react';
import {withRouter} from "../../hoc/withRouter";
import "../../screen/home/Home.css";
import {TradeInVehicleStates} from "./types/TradeInVehicleTypes";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Form from "antd/lib/form";
import NormalHeader from "../../components/layouts/NormalHeader";
import FooterSection from "../../components/footer/FooterSection";
import Input from "antd/lib/input";
import {Breadcrumb} from "antd";
import TextArea from "antd/es/input/TextArea";
import Modal from "antd/lib/modal";
import Tabs from "antd/lib/tabs";
import Select from "antd/lib/select";
import VehicleModelSelect from "../../components/vehicle-copan/VehicleModelSelect";
import VehicleVariantSelect from "../../components/vehicle-copan/VehicleVariantSelect";
import VehicleVariantColorSelect from "../../components/vehicle-copan/VehicleVariantColorSelect";
import Checkbox from "antd/lib/checkbox";
import Button from "antd/lib/button";
import Spin from "antd/lib/spin";
import UploadMultiImage from "../../components/image/UploadMultiImage";
import MomotorCitySelect from "../../components/momotor/MomotorCitySelect";
import MomotorBrandSelect from "../../components/momotor/MomotorBrandSelect";
import MomotorModelSelect from "../../components/momotor/MomotorModelSelect";
import {momotorVehicleService} from "../../services/momotor/MomotorVehicleService";
import {tradeInApiService} from "../../services/track/TradeInApiService";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";

class TradeInVehicle extends Component<any, TradeInVehicleStates> {
    private readonly initState!: TradeInVehicleStates;

    constructor(props: any) {
        super(props);
        document.title = "Amartahonda | Tukar Tamabah Motor";

        const queryParams = new URLSearchParams(window.location.search);
        let variantCode: any = "";
        let area: any = "";
        if (queryParams.get('variant_code')) {
            variantCode = queryParams.get('variant_code')?.toUpperCase();
        }
        if (queryParams.get('area')) {
            area = queryParams.get('area')?.toLowerCase();
        }

        this.initState = {
            company: "AMARTA",
            areaCode: "bandung",
            isDesktop: false,
            freezeStatus: false,
            idCardName: "",
            momotorProvinceCode: null,
            momotorProvinceName: null,
            momotorCityCode: null,
            momotorCityName: null,
            momotorBrandCode: null,
            momotorModelCode: null,
            momotorModelName: null,
            licensePlate: "",
            vehicleBrandUuid: "afce6884-fc4d-4f7a-b784-09d2a2f7b903",
            vehicleBrandName: "honda",
            vehicleModelUuid: null,
            vehicleModelName: null,
            vehicleVariantUuid: null,
            vehicleVariantCode: null,
            vehicleVariantName: null,
            vehicleColorCode: null,
            vehicleColorName: null,
            vehicleYear: new Date().getFullYear(),
            completeCertificate: true,
            activeTax: true,
            description: "",
            mileage: null,
            agentCode: "",
            loading: false,
            phoneNumber: "",
            listImages: [],
            paramsVariantCode: variantCode,
            paramsArea: area,
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    async componentDidMount() {
        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();

        if (this.state.paramsVariantCode && this.state.paramsArea) {
            try {
                trimitraCatalogServices.getCatalogVariant({
                    areaCode: this.state.paramsArea,
                    variantCode: this.state.paramsVariantCode,
                }).then(async dataVariantDetail => {
                    this.setState({
                        vehicleModelUuid: dataVariantDetail.data.data[0]?.model_uuid,
                        vehicleModelName: dataVariantDetail.data.data[0]?.model_name,
                        vehicleVariantUuid: dataVariantDetail.data.data[0]?.variant_uuid,
                        vehicleVariantCode: dataVariantDetail.data.data[0].variant_code,
                        vehicleVariantName: dataVariantDetail.data.data[0].variant_name,
                        vehicleColorCode: dataVariantDetail.data.data[0].color_code,
                        vehicleColorName: dataVariantDetail.data.data[0].color_name,
                    })
                });
            } catch (e) {
                console.log(e)
            }
        }
    }

    onFieldChange = <T extends keyof Pick<any, "idCardName" | "agentCode" | "licensePlate" | "activeTax" | "completeCertificate" | "mileage" | "vehicleYear" | "familyCardNumber" | "description" | "phoneNumber">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "idCardName":
                currentState.idCardName = value;
                break;
            case "agentCode":
                currentState.agentCode = value;
                break;
            case "licensePlate":
                currentState.licensePlate = value?.toUpperCase().replace(/^([A-Z]+)(\d+)([A-Z]+)/, '$1 $2 $3');
                break;
            case "activeTax":
                currentState.activeTax = value;
                break;
            case "completeCertificate":
                currentState.completeCertificate = value;
                break;
            case "mileage":
                currentState.mileage = value;
                break;
            case "description":
                currentState.description = value;
                break;
            case "vehicleYear":
                currentState.vehicleYear = value.replace(/[^0-9.]/g, '');
                break;
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, '');
                break;
        }

        if (target === 'phoneNumber') {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== '08') {
                    currentState.phoneNumber = "";
                }
            }
        }

        this.setState({
            ...currentState,
        });
    }

    onMomotorCityChange = (data: any) => {
        this.setState({
            momotorCityCode: data.value,
            momotorCityName: data.children,
            momotorProvinceCode: data.province_code,
            momotorProvinceName: data.province_name,
        })
    }

    onMomotorBrandChanges = (data: any) => {
        this.setState({
            momotorBrandCode: data.value?.toUpperCase()
        })
    }

    onMomotorModelChanges = (data: any) => {
        this.setState({
            momotorModelCode: data.value?.toUpperCase(),
            momotorModelName: data.children?.toUpperCase(),
        })
    }

    onAreaChange = async (data: any) => {
        await this.promisedSetState({
            areaCode: data.city_group,
        });
    }

    onVehicleModelChange = (data: any) => {
        this.setState({
            vehicleModelUuid: data.uuid,
            vehicleModelName: data.value,
            vehicleVariantUuid: null,
            vehicleVariantCode: null,
            vehicleVariantName: null,
            vehicleColorCode: null,
            vehicleColorName: null,
        });
    }

    onVehicleVariantChange = (data: any) => {
        this.setState({
            vehicleVariantUuid: data.uuid,
            vehicleVariantCode: data.value,
            vehicleVariantName: data.variant_name,
            vehicleColorCode: null,
            vehicleColorName: null,
        });
    }

    onVehicleVariantColorChange = (data: any) => {
        console.log(data);
        this.setState({
            vehicleColorCode: data.key,
            vehicleColorName: data.children,
        });
    }

    onImageUpdate = (data: any) => {
        this.setState({
            listImages: data,
        })
    }

    getMomotorPrice = async () => {
        try {
            const dataMomotorPrice = await momotorVehicleService.getPrice({model_code: this.state.momotorModelCode, year: this.state.vehicleYear});
            return {
                success: true,
                data: dataMomotorPrice.data?.data?.[0]?.price,
            }
        } catch (e) {
            return {
                success: false,
                data: 0,
            }
        }
    }

    sendSaleRequest = async () => {
        let warnNotice: string = "";
        warnNotice += (!this.state.licensePlate) ? "Plat Nomor Kendaaan, " : "";
        warnNotice += (!this.state.phoneNumber) ? "Nomor whatsapp, " : "";
        warnNotice += (!this.state.vehicleYear) ? "Tahun Kendaraan, " : "";
        warnNotice += (!this.state.mileage) ? "Jarak tempuh, " : "";
        warnNotice += (!this.state.description) ? "Deskripsi Kendaraan, " : "";
        warnNotice += (this.state.listImages.length < 1) ? "Mohon Minimal Upload 1 gambar kendaraan anda, " : "";
        warnNotice += (!this.state.momotorBrandCode) ? "Brand Kendaraan, " : "";
        warnNotice += (!this.state.momotorModelName) ? "Model Kendaraan, " : "";
        warnNotice += (!this.state.momotorCityCode) ? "Kota, " : "";

        warnNotice += (!this.state.idCardName) ? "Nama Pemilik, " : "";
        warnNotice += (!this.state.vehicleModelName) ? "Model Kendaraan yang Diinginkan, " : "";

        if (warnNotice !== "") {
            const objError = warnNotice.split(",");
            Modal.error({
                title: 'Proses Gagal',
                content: (<div>
                    Mohon Lengkapi data-data berikut: <br/><br/>
                    {objError.map(function (itemError, i) {
                        return <div key={i}>{itemError}</div>;
                    })}
                </div>)
            });
            return false;
        }

        const vehiclePrice = await this.getMomotorPrice()
        if (!vehiclePrice.success) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Mohon maaf, Jenis Model dan tahun kendaraan yang anda pilih belum dapat kami akomodir.'
            });
            return
        }

        await this.promisedSetState({
            loading: true,
        });

        const dataMomotor = {
            phone_number: this.state.phoneNumber,
            model_code: this.state.momotorModelCode,
            brand_desc: this.state.momotorBrandCode,
            type_desc: "",
            model_desc: this.state.momotorModelName,
            price: vehiclePrice.data,
            description: this.state.description,
            bpkb: (this.state.completeCertificate),
            year: Number(this.state.vehicleYear),
            pajak: (this.state.activeTax),
            mileage: Number(this.state.mileage),
            plat_no: this.state.licensePlate?.replace(/ /g, ''),
            city_code: this.state.momotorCityCode,
            city_desc: this.state.momotorCityName,
            assets: {
                img1: (this.state.listImages.length > 0) ? this.state.listImages[0].image_url : "",
                img2: (this.state.listImages.length > 1) ? this.state.listImages[1].image_url : "",
                img3: (this.state.listImages.length > 2) ? this.state.listImages[2].image_url : "",
                img4: (this.state.listImages.length > 3) ? this.state.listImages[3].image_url : "",
                img5: (this.state.listImages.length > 4) ? this.state.listImages[4].image_url : "",
                video: ""
            }
        };

        let idMomotor = ""
        try {
            const dataMomotorPost = await momotorVehicleService.postSubmitLead(dataMomotor);
            idMomotor = dataMomotorPost?.data?.data?.id ?? ""
        } catch (e) {
            Modal.error({title: 'Proses Gagal', content: 'Momotor Server Error'});
            this.setState({loading: false});
            return
        }

        const dataBqMomotor = {
            platform: "MOMOTOR",
            customer_name: this.state.idCardName,
            phone_number: this.state.phoneNumber,
            platform_vehicle_type: "",
            platform_brand_name: this.state.momotorBrandCode,
            platform_model_code: this.state.momotorModelCode,
            platform_model_name: this.state.momotorModelName,
            platform_price: vehiclePrice.data,
            description: idMomotor + "|" + this.state.description,
            vehicle_year: Number(this.state.vehicleYear),
            vehicle_ownership: (this.state.completeCertificate),
            vehicle_tax: (this.state.activeTax),
            vehicle_mileage: Number(this.state.mileage),
            vehicle_license_plate: this.state.licensePlate,
            vehicle_license_plate_formatted: this.state.licensePlate?.replace(/ /g, ''),
            platform_city_code: this.state.momotorCityCode,
            platform_city_name: this.state.momotorCityName,
            vehicle_assets: {
                image1: (this.state.listImages.length > 0) ? this.state.listImages[0].image_url : "",
                image2: (this.state.listImages.length > 1) ? this.state.listImages[1].image_url : "",
                image3: (this.state.listImages.length > 2) ? this.state.listImages[2].image_url : "",
                image4: (this.state.listImages.length > 3) ? this.state.listImages[3].image_url : "",
                image5: (this.state.listImages.length > 4) ? this.state.listImages[4].image_url : "",
                video: ""
            },
            brand_uuid: (this.state.vehicleBrandUuid) ? this.state.vehicleBrandUuid : "",
            brand_name: (this.state.vehicleBrandName) ? this.state.vehicleBrandName : "",
            model_uuid: (this.state.vehicleModelUuid) ? this.state.vehicleModelUuid : "",
            model_name: (this.state.vehicleModelName) ? this.state.vehicleModelName : "",
            variant_uuid: (this.state.vehicleVariantUuid) ? this.state.vehicleVariantUuid : "",
            variant_code: (this.state.vehicleVariantCode) ? this.state.vehicleVariantCode : "",
            variant_name: (this.state.vehicleVariantName) ? this.state.vehicleVariantName : "",
            color_code: (this.state.vehicleColorCode) ? this.state.vehicleColorCode : "",
            color_name: (this.state.vehicleColorName) ? this.state.vehicleColorName : "",
        };

        try {
            await tradeInApiService.postTradeIn(dataBqMomotor);
            Modal.success({
                title: 'Proses Sukses',
                content: 'data telah kami terima dan akan kami proses. terimakasih telah melakukan input form tukar tambah motor.'
            });
            setTimeout(function () {
                window.location.reload();
            }, 3000);
        } catch (e) {
            Modal.error({title: 'Proses Gagal', content: 'Server Menolak Inputan Anda :' + e});
            this.setState({loading: false,});
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        const years = [];
        for (let i = new Date().getFullYear(); i >= new Date().getFullYear() - 10; i--) {
            years.push(i.toString());
        }

        return (
            <React.Fragment>
                <NormalHeader areaCode={this.state.areaCode} onAreaChangeProps={this.onAreaChange} isDesktop={this.state.isDesktop}/>
                <Row>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>AmartaHonda</Breadcrumb.Item>
                            <Breadcrumb.Item>Tukar Tamabah Motor</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row style={{backgroundColor: "#f8f8f8"}}>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col className={`p-20`} xxl={{span: 16}} xl={{span: 16}} md={{span: 16}} sm={{span: 24}} xs={{span: 24}}>
                                <div className={`detail-product-title`}>AmartaHonda - Tukar Tambah</div>
                                <br/>
                                <div className={`detail-product-caption`}>Ingin beralih ke motor baru dengan cara yang lebih mudah dan menguntungkan? <br/><br/> AmartaHonda hadir dengan layanan tukar tambah motor. Kami akan memberikan penawaran yang sesuai dengan nilai sebenarnya. Tukarkan motor lama Anda dengan motor baru favorit Anda sekarang!</div>
                                <br/>
                            </Col>
                            <Col className={`p-10`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 0}} xs={{span: 0}}>
                                <img src={`https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/money-transfer.png`} style={{height: 200}} alt={`logo`}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>

                        <Tabs
                            type="card"
                            defaultActiveKey="1"
                            items={[
                                {
                                    label: `(1 of 2) Data Kendaraan`,
                                    key: '1',
                                    children:
                                        <React.Fragment>
                                            <Row>
                                                <div style={{marginBottom: 10, width: "100%", border: 1, borderColor: "grey", backgroundColor: "#eeeeee", padding: 5, fontSize: "80%"}}>
                                                    &nbsp; <i> form dengan tanda bintang ( <b style={{color: "red"}}>*</b> ) wajib diisi</i>
                                                </div>
                                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 10}}>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Nama Sesuai KTP <b style={{color: "red"}}>*</b></i>
                                                        <Input value={this.state.idCardName} placeholder="Nama Sesuai KTP" onChange={event => this.onFieldChange("idCardName", event.target.value)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Nomor Whatsapp <b style={{color: "red"}}>*</b></i>
                                                        <Input value={this.state.phoneNumber} maxLength={17} placeholder="Format Telpon: 08xxxxxxxx" onChange={event => this.onFieldChange("phoneNumber", event.target.value)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Kota / Kabupaten <b style={{color: "red"}}>*</b></i>
                                                        <MomotorCitySelect onAreaCityChangeProps={this.onMomotorCityChange} cityName={this.state.momotorCityName}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <span className={`text-small-grey`}>Brand Kendaraan <b style={{color: "red"}}>*</b></span>
                                                        <MomotorBrandSelect onVehicleBrandChangeProps={this.onMomotorBrandChanges} value={this.state.momotorBrandCode}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <span className={`text-small-grey`}>Model Kendaraan <b style={{color: "red"}}>*</b></span>
                                                        <MomotorModelSelect onVehicleModelChangeProps={this.onMomotorModelChanges} value={this.state.momotorModelName} brandCode={this.state.momotorBrandCode} time={Math.floor(+new Date() / 1000)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Plat Nomor <b style={{color: "red"}}>*</b></i>
                                                        <Input value={this.state.licensePlate} placeholder="D 1234 DDD" onChange={event => this.onFieldChange("licensePlate", event.target.value)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Tahun Kendaraan <b style={{color: "red"}}>*</b></i>
                                                        <Select
                                                            onChange={value => this.onFieldChange("vehicleYear", value)}
                                                            value={this.state.vehicleYear}
                                                            style={{width: '100%'}}
                                                            placeholder={`Tahun Kendaraan`}
                                                        >
                                                            {years.map((item: any, i: number) => {
                                                                return (<Select.Option key={i} value={item}>{item}</Select.Option>)
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Jarak Tempuh <b style={{color: "red"}}>*</b></i>
                                                        <Select
                                                            onChange={value => this.onFieldChange("mileage", value)}
                                                            value={this.state.mileage}
                                                            style={{width: '100%'}}
                                                            placeholder={`Jarak Tempuh / Odometer Kendaraan`}
                                                        >
                                                            <Select.Option value={15000}>0 - 15.000 KM</Select.Option>
                                                            <Select.Option value={50000}>15.001 - 50.000 KM</Select.Option>
                                                            <Select.Option value={100000}>50.001 - 100.000 KM</Select.Option>
                                                            <Select.Option value={200000}>lebih dari 100.000 KM</Select.Option>
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Kelengkapan Surat <b style={{color: "red"}}>*</b></i><br/>
                                                        <Checkbox style={{marginTop: 10}} onChange={e => this.onFieldChange("completeCertificate", e.target.checked)} checked={this.state.completeCertificate}>
                                                            <i className={`text-small-grey-nopadding`}>BPKB & STNK kendaraan lengkap.</i>
                                                        </Checkbox><br/>
                                                        <Checkbox style={{marginTop: 15}} onChange={e => this.onFieldChange("activeTax", e.target.checked)} checked={this.state.activeTax}>
                                                            <i className={`text-small-grey-nopadding`}>Pajak masih aktif.</i>
                                                        </Checkbox><br/>
                                                    </Form.Item>

                                                </Col>
                                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 10}}>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Deskripsi Kendaraan <b style={{color: "red"}}>*</b></i>
                                                        <TextArea showCount maxLength={500} rows={3} value={this.state.description} onChange={event => this.onFieldChange("description", event.target.value)} placeholder="Deskripsi Dari Kendaraan yang akan dijual, anda dapat menjelaskan kondisi, kelebihan dan kekurangan kendaraan"/>
                                                    </Form.Item>
                                                    <Form.Item style={{marginTop: 17}}>
                                                        <i className={`text-small-grey-nopadding`}>Upload Gambar <b style={{color: "red"}}>*</b></i>
                                                        <UploadMultiImage onImageUpdateProps={this.onImageUpdate}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </React.Fragment>,
                                },
                                {
                                    label: `(2 of 2) Motor Yang Diinginkan`,
                                    key: '2',
                                    children:
                                        <React.Fragment>
                                            <Row>
                                                <div style={{marginBottom: 10, width: "100%", border: 1, borderColor: "grey", backgroundColor: "#eeeeee", padding: 5, fontSize: "80%"}}>
                                                    &nbsp; <i> form dengan tanda bintang ( <b style={{color: "red"}}>*</b> ) wajib diisi</i>
                                                </div>
                                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 10}}>
                                                    <Form.Item>
                                                        <span className={`text-small-grey`}>Model Kendaraan <b style={{color: "red"}}>*</b></span>
                                                        <VehicleModelSelect onVehicleModelChangeProps={this.onVehicleModelChange} value={this.state.vehicleModelName} brandCode={this.state.vehicleBrandName} category={"motorcycle"} time={Math.floor(+new Date() / 1000)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <span className={`text-small-grey`}>Variant Kendaraan</span>
                                                        <VehicleVariantSelect showUnActive={true} onVehicleVariantChangeProps={this.onVehicleVariantChange} brandCode={this.state.vehicleBrandName} modelCode={this.state.vehicleModelName} value={this.state.vehicleVariantCode} time={Math.floor(+new Date() / 1000)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <span className={`text-small-grey`}>Warna Kendaraan</span>
                                                        <VehicleVariantColorSelect onVehicleVariantColorChangeProps={this.onVehicleVariantColorChange} brandCode={this.state.vehicleBrandName} modelCode={this.state.vehicleModelName} variantCode={this.state.vehicleVariantCode} value={this.state.vehicleColorCode} time={Math.floor(+new Date() / 1000)}/>
                                                    </Form.Item>
                                                    {/*<Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Agent / Affiliate Code</i>
                                                        <Input
                                                            value={this.state.agentCode}
                                                            placeholder="abaikan jika tidak memiliki"
                                                            onChange={event => this.onFieldChange("agentCode", event.target.value)}
                                                        />
                                                    </Form.Item>*/}
                                                </Col>
                                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 10}}/>

                                            </Row>
                                        </React.Fragment>,
                                },
                            ]}
                        />

                        <Button onClick={this.sendSaleRequest} loading={this.state.loading} style={{background: "#64b48f", color: "#fff", borderColor: "#64b48f", marginTop: 10, marginBottom: 20}}> Ajukan Form Penjualan </Button>
                    </Col>
                </Row>

                <FooterSection areaCode={this.state.areaCode}/>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(TradeInVehicle);