import React, {ComponentType} from "react";
import Cookies from 'universal-cookie';
import {authApiService} from "../services/amartaVip/AuthApiService";

export default function withAuth<P extends object>(Component: ComponentType<P>, pageCode?: any) {
    const cookies = new Cookies();

    class InnerHoc extends React.Component<any, any> {
        constructor(props: any) {
            super(props);

            this.state = {
                authenticated: false,
                authenticating: true,
            }
        }

        checkAuth = async () => {
            await this.promisedSetState({
                authenticating: true,
            });

            let currentState = {...this.state};
            const token = cookies.get('_amh-t');
            if (!token) {
                currentState.authenticated = false;
            } else {
                authApiService.setToken(token);
                try {
                    await authApiService.checkJwt();
                    currentState.authenticated = true;
                } catch (e) {
                    currentState.authenticated = false;
                }
            }
            currentState.authenticating = false;
            await this.promisedSetState({...currentState});
        }

        componentDidMount = () => {
            this.checkAuth().then();
        };

        promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

        render = () => {
            if (this.state.authenticating) {
                return <span>Authenticating...</span>
            } else {
                if (this.state.authenticated) {
                    return (<Component {...this.props as P}/>)
                } else {
                    cookies.remove('_amh-t');
                    cookies.remove('_amh-n');
                    cookies.remove('_amh-p');
                    cookies.remove('_amh-e');
                    cookies.remove('_amh-ut');
                    cookies.remove('_amh-uc');
                    cookies.remove('_amh-ui');
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 100);
                }
            }
        };
    }

    return InnerHoc;
}
