import React, {FunctionComponent} from "react";
import Card from "antd/lib/card";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Tabs from "antd/lib/tabs";
import Typography from "antd/lib/typography";
import Image from "antd/lib/image";

export const PaymentInstruction: FunctionComponent<any> = (props: any) => {
    return (
        <React.Fragment>

            <div style={(props.paymentMethod === 'cod') ? {} : {display: 'none'}}>
                <Card>
                    <Row>
                        <Col span={18}>
                            <Typography.Text type="secondary">Jumlah Tagihan:</Typography.Text><br/>
                            <span style={{fontSize: '120%'}}>{props.totalInvoice}</span>
                        </Col>
                        <Col span={6}>
                            <Image preview={false} style={{width: 70, marginTop: 5}} src={props.invoiceMethodLogo}/>
                        </Col>
                    </Row>
                </Card><br/>

                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="INSTRUKSI COD" key="1">
                        <ul>
                            <li>Pembayaran wajib dilunasi sebelum Berita Acara Serah Terima dilaksanakan.</li>
                            <li>Jika unit yang dikirim tidak sesuai maka akan dikenakan biaya pembatalan sebesar Rp. 50,000,-</li>
                            <li>Pembayaran wajib sesuai dengan nilai nominal yang tertera pada Surat Pesanan Kendaraan</li>
                            <li>Pembayaran dianggap lunas apabila nilai yang dibayarkan sudah sesuai dengan nominal yang tertera pada Surat Pesanan Kendaraan</li>
                            <li>Unit yang sudah dilakukan Berita Acara Serah Terima tidak bisa dibatalkan.</li>
                        </ul>
                    </Tabs.TabPane>
                </Tabs>
            </div>

            <div style={(props.paymentMethod === 'va_bca') ? {} : {display: 'none'}}>
                <Card>
                    <Row>
                        <Col span={18}>
                            <Typography.Text type="secondary">Nomor Virtual Akun:</Typography.Text><br/>
                            <span style={{fontSize: '120%'}}>{props.virtualAccountNumber}</span><br/><br/>
                            <Typography.Text type="secondary">Jumlah Tagihan:</Typography.Text><br/>
                            <span style={{fontSize: '120%'}}>{props.totalInvoice}</span>
                        </Col>
                        <Col span={6}>
                            <Image preview={false} style={{width: 70, marginTop: 5}} src={props.invoiceMethodLogo}/>
                        </Col>
                    </Row>
                </Card><br/>

                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="ATM" key="1">
                        <ul style={{paddingLeft: 20}}>
                            <li>Pilih menu TRANSAKSI LAINNYA</li>
                            <li>Pilih menu TRANSFER</li>
                            <li>Pilih menu KE REK. BCA VIRTUAL ACCOUNT</li>
                            <li>Masukkan Nomor Virtual Account</li>
                            <li>Pastikan Nomor Virtual Account benar, kemudian masukkan angka sesuai jumlah tagihan</li>
                            <li>Cek & Perhatikan Konfirmasi Pembayaran dari layar ATM, jika sudah benar pilih “YA“, atau pilih “TIDAK” jika data di layar masih salah</li>
                        </ul>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="IBANKING" key="2">
                        <ul style={{paddingLeft: 20}}>
                            <li>Login pada aplikasi KlikBCA</li>
                            <li>Pilih “Transfer Dana”</li>
                            <li>Pilih “Transfer ke BCA Virtual Account”</li>
                            <li>Masukkan nomor BCA Virtual Account</li>
                            <li>Masukkan angka sesuai jumlah tagihan</li>
                            <li>Validasi pembayaran. Pastikan semua data sudah benar</li>
                        </ul>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="MBANKING" key="3">
                        <ul style={{paddingLeft: 20}}>
                            <li>Login pada aplikasi BCA Mobile</li>
                            <li>Pilih “m-Transfer”</li>
                            <li>Pilih “BCA Virtual Account”</li>
                            <li>Masukkan nomor BCA Virtual Account</li>
                            <li>Masukkan angka sesuai jumlah tagihan</li>
                            <li>Validasi pembayaran. Pastikan semua data sudah benar</li>
                        </ul>
                    </Tabs.TabPane>
                </Tabs>
            </div>

        </React.Fragment>
    );
}
