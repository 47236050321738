import React, {Component} from "react";
import {UpdateSpkStates} from "./types/UpdateSpkTypes";
import SpkLayout from "../../components/layouts/SpkLayout";
import {withRouter} from "../../hoc/withRouter";
import {offerApiServices} from "../../services/offer/OfferApiService";
import Spin from "antd/lib/spin";
import Empty from "antd/lib/empty";
import Tabs from "antd/lib/tabs";
import UpdateOwnerOffer from "../../components/offer/UpdateOwnerOffer";
import UpdateOrderMakerOffer from "../../components/offer/UpdateOrderMakerOffer";

class UpdateSpk extends Component<any, UpdateSpkStates> {
    private readonly initState!: UpdateSpkStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const offerCode = match.params.offercode;

        document.title = `Amartahonda | SPK | ${offerCode}`;

        this.initState = {
            offerCode: offerCode,
            dataOffer: {},
            fetchOffer: true,
        }
        this.state = {...this.initState}
    }

    fetchDetailOffer = async () => {
        await this.promisedSetState({fetchOffer: true});
        try {
            const dataFetch = await offerApiServices.getDetailOffer({offerCode: this.state.offerCode});
            this.setState({
                fetchOffer: false,
                dataOffer: dataFetch.data.data,
            })
        } catch (e) {
            this.setState({
                fetchOffer: false,
                dataOffer: null,
            })
        }
    }

    onCloseEditModal = async () => {
        await this.fetchDetailOffer();
    }

    async componentDidMount() {
        await this.fetchDetailOffer();
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <SpkLayout areaCode={this.state.dataOffer?.area} backToHome={``} headerTitle={`SPK ${this.state.offerCode}`}>

                <div className="spin-loading" style={(this.state.fetchOffer) ? {} : {display: 'none'}}>
                    <Spin size={"large"} tip="Loading..."/>
                </div>
                <div style={(!this.state.fetchOffer && this.state.dataOffer === null) ? {paddingTop: 20} : {display: 'none'}}>
                    <Empty description={`Kode SPK Tidak Ditemukan, pastikan Kode SPK benar`}/>
                </div>

                <div style={(!this.state.fetchOffer && this.state.dataOffer !== null) ? {paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, minHeight: '85vh'} : {display: 'none'}}>
                    <div className={`detail-product-title pt-10 pb-20`}>Update Surat Pesan Kendaraan</div>
                    <i className={`detail-product-caption`}>Nomor SPK:</i><br/>
                    <span style={{fontSize: "120%", fontWeight: 500}}>{this.state.offerCode}</span>

                    <Tabs defaultActiveKey="1" style={{marginTop: 30}}>
                        <Tabs.TabPane tab="Update KTP Pemilik" key="1">
                            <UpdateOwnerOffer onCloseUpdateOwnerIdCardProps={this.onCloseEditModal} companyCode={`amarta`} offerCode={this.state.offerCode} time={Math.floor(+new Date() / 1000)}/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Update KTP Pemohon" key="2">
                            <UpdateOrderMakerOffer onCloseUpdateOwnerIdCardProps={this.onCloseEditModal} companyCode={`amarta`} offerCode={this.state.offerCode} time={Math.floor(+new Date() / 1000)}/>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </SpkLayout>
        );
    }
}

export default withRouter(UpdateSpk);