import React, {Component} from 'react';
import {Buffer} from "buffer";
import {Layout, Divider} from "antd";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import Col from "antd/lib/col";
import MyProfileLoginHeader from "../../components/layouts/MyProfileLoginHeader";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import Image from "antd/lib/image";
import Spin from "antd/lib/spin";
import Row from "antd/lib/row";
import Empty from "antd/lib/empty";
import Badge from "antd/lib/badge";
import {appVersion} from "../../config/dataConfig/appConfig";
import FooterSection from "../../components/footer/FooterSection";

export interface MyDealProps {
}

export interface MyDealStates extends MyDealFields {
    showDrawerMenu: boolean;
}

export interface MyDealFields {
    name: string;
    email: string;
    phoneNumber: string;
    listBookmarkFinal: any,
    fetchBookmarkProcessing: boolean,
}

class MyDeal extends Component<MyDealProps & any, MyDealStates> {
    private cookies = new Cookies();

    constructor(props: MyDealProps & any) {
        super(props);

        this.state = {
            showDrawerMenu: false,
            name: Buffer.from(this.cookies.get('_amh-n'), 'base64').toString(),
            email: Buffer.from(this.cookies.get('_amh-e'), 'base64').toString(),
            phoneNumber: Buffer.from(this.cookies.get('_amh-p'), 'base64').toString(),
            listBookmarkFinal: [],
            fetchBookmarkProcessing: true,
        }
    }

    async componentDidMount() {
        const listDeal = [];
        try {
            const listBookmark = await trimitraCatalogServices.getDealList();
            const tempData = listBookmark?.data?.data ?? [];
            for (const item of tempData) {
                listDeal.push(item);
            }
        } catch (e) {
            console.log(e);
        }

        await this.promisedSetState({
            listBookmarkFinal: listDeal,
            fetchBookmarkProcessing: false
        });

    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Row style={{backgroundColor: '#fff', paddingBottom: 5, borderBottom: "#f0f2f5 2px solid"}}>
                        <Col xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <MyProfileLoginHeader title={"My Deal"}/>
                        </Col>
                    </Row>
                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={{minHeight: '79vh', backgroundColor: "#fff", padding: "1vh"}}>

                            <div style={this.state.fetchBookmarkProcessing ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                            </div>

                            <div style={!this.state.fetchBookmarkProcessing ? {} : {display: 'none'}}>

                                <Divider orientation="left" plain>
                                    List Deal
                                </Divider>

                                <div style={this.state.listBookmarkFinal.length > 0 ? {} : {display: 'none'}}>
                                    <div style={{textAlign: "right", color: "#64b48f", paddingRight: 20}}>
                                        <i>*Klik image, untuk melihat detail DEAL</i>
                                    </div>

                                    <Row className={`home-row-chosen-product`}>
                                        {this.state.listBookmarkFinal.map((item: any, i: number) => {
                                            return (
                                                <Col key={i} xxl={{span: 6}} xl={{span: 6}} md={{span: 6}} sm={{span: 12}} xs={{span: 12}} style={{padding: 5}}>
                                                    <a target={"_blank"} rel="noreferrer" href={"https://deal.amartahonda.com/" + item?.deal_code + "?utm_source=amartahonda"}>
                                                        <div className={`card-product`}>
                                                            <Badge.Ribbon style={{zIndex: 300, fontSize: '110%'}} text={`${item?.area[0]}`} color={"#64b48f"}/>
                                                            <div className={`product-img-center`}>
                                                                <Image className={`img-product-card`} preview={false} alt={`Loading Image...`} width={'100%'} src={item?.url_image} fallback={'https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/vehicle-not-found.png'}/>
                                                            </div>
                                                            <span className={`p-chosen-name`}>
                                                            {item?.deal_code}
                                                        </span><br/>
                                                            <div style={{fontSize: "90%", color: "grey", padding: 10, minHeight: 70}}>
                                                                {item?.caption}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </div>
                                <div style={this.state.listBookmarkFinal.length === 0 ? {} : {display: 'none'}}>
                                    <Empty/>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <FooterSection areaCode={'bandung'} version={appVersion} lite={true}/>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withAuth(MyDeal);
