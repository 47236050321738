import React, {Component} from "react";
import Select from "antd/lib/select";

class LeasingSurveyTime extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            leasingSurveyTime: null,
        }
        this.state = {...this.initState}
    }

    onChange = (value: any, option: any) => {
        this.setState({
            leasingSurveyTime: value,
        });
        this.props.onLeasingSurveyTimeChangeProps(option);
    }

    render() {
        return (
            <Select
                placeholder="Pilih Tenor"
                showSearch
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.props.value ?? this.state.leasingSurveyTime}
            >
                <Select.Option value={'10:00'}>10:00</Select.Option>
                <Select.Option value={'10:30'}>10:30</Select.Option>
                <Select.Option value={'11:00'}>11:00</Select.Option>
                <Select.Option value={'11:30'}>11:30</Select.Option>
                <Select.Option value={'12:00'}>12:00</Select.Option>
                <Select.Option value={'12:30'}>12:30</Select.Option>
                <Select.Option value={'13:00'}>13:00</Select.Option>
                <Select.Option value={'13:30'}>13:30</Select.Option>
                <Select.Option value={'14:00'}>14:00</Select.Option>
                <Select.Option value={'14:30'}>14:30</Select.Option>
                <Select.Option value={'15:00'}>15:00</Select.Option>
                <Select.Option value={'15:30'}>15:30</Select.Option>
            </Select>
        );
    }
}

export default LeasingSurveyTime;
