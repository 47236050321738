import axios from "axios";

class IdealApiService {
    private axios = axios.create({
        baseURL: "",
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    public async getPricelistJson(params?: any) {
        const priceListCode = `${params?.slice(0, 4)}/${params}.json`
        const axioLogUrl = axios.create({
            baseURL: `https://storage.googleapis.com`,
        });

        try {
            return await axioLogUrl.get<any>(`/chat-trimitra-ideal/create_pricelist/${priceListCode}`, {
                headers: {
                    // "Content-Type": "application/json",
                },
                params: {},
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }
}

export const idealApiService = new IdealApiService();
