import React, {Component} from "react";
import Select from "antd/lib/select";

class CustomerOccupation extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            customerOccupationStatus: null,
        }
        this.state = {...this.initState}
    }

    onChange = (value: any, option: any) => {
        this.setState({
            customerOccupationStatus: value,
        });
        this.props.onCustomerOccupationChangeProps(option);
    }

    render() {
        return (
            <Select
                placeholder="Jenis Pekerjaan"
                showSearch
                style={{width: "100%"}}
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.props.value ?? this.state.customerOccupationStatus}
            >
                <Select.Option value="000001">belum/tidak bekerja</Select.Option>
                <Select.Option value="000002">mengurus rumah tangga</Select.Option>
                <Select.Option value="000003">pelajar/mahasiswa</Select.Option>
                <Select.Option value="000004">pensiunan</Select.Option>
                <Select.Option value="000005">pegawai negeri sipil</Select.Option>
                <Select.Option value="000006">tentara nasional indonesia</Select.Option>
                <Select.Option value="000007">kepolisian ri</Select.Option>
                <Select.Option value="000008">perdagangan</Select.Option>
                <Select.Option value="000009">petani/pekebun</Select.Option>
                <Select.Option value="000010">peternak</Select.Option>
                <Select.Option value="000011">nelayan/perikanan</Select.Option>
                <Select.Option value="000012">industri</Select.Option>
                <Select.Option value="000013">konstruksi</Select.Option>
                <Select.Option value="000014">transportasi</Select.Option>
                <Select.Option value="000015">karyawan swasta</Select.Option>
                <Select.Option value="000016">karyawan bumn</Select.Option>
                <Select.Option value="000017">karyawan bumd</Select.Option>
                <Select.Option value="000018">karyawan honorer</Select.Option>
                <Select.Option value="000019">buruh harian lepas</Select.Option>
                <Select.Option value="000020">buruh tani/perkebunan</Select.Option>
                <Select.Option value="000021">buruh nelayan/perikanan</Select.Option>
                <Select.Option value="000022">buruh peternakan</Select.Option>
                <Select.Option value="000023">pembantu rumah tangga</Select.Option>
                <Select.Option value="000024">tukang cukur</Select.Option>
                <Select.Option value="000025">tukang listrik</Select.Option>
                <Select.Option value="000026">tukang batu</Select.Option>
                <Select.Option value="000027">tukang kayu</Select.Option>
                <Select.Option value="000028">tukang sol sepatu</Select.Option>
                <Select.Option value="000029">tukang las/pandai besi</Select.Option>
                <Select.Option value="000030">tukang jahit</Select.Option>
                <Select.Option value="000031">tukang gigi</Select.Option>
                <Select.Option value="000032">penata rias</Select.Option>
                <Select.Option value="000033">penata busana</Select.Option>
                <Select.Option value="000034">penata rambut</Select.Option>
                <Select.Option value="000035">mekanik</Select.Option>
                <Select.Option value="000036">seniman</Select.Option>
                <Select.Option value="000037">tabib</Select.Option>
                <Select.Option value="000038">paraji</Select.Option>
                <Select.Option value="000039">perancang busana</Select.Option>
                <Select.Option value="000040">penterjemah</Select.Option>
                <Select.Option value="000041">imam mesjid</Select.Option>
                <Select.Option value="000042">pendeta</Select.Option>
                <Select.Option value="000043">pastor</Select.Option>
                <Select.Option value="000044">wartawan</Select.Option>
                <Select.Option value="000045">ustadz/mubaligh</Select.Option>
                <Select.Option value="000046">juru masak</Select.Option>
                <Select.Option value="000047">promotor acara</Select.Option>
                <Select.Option value="000048">anggota dpr-ri</Select.Option>
                <Select.Option value="000049">anggota dpd</Select.Option>
                <Select.Option value="000050">anggota bpk</Select.Option>
                <Select.Option value="000051">presiden</Select.Option>
                <Select.Option value="000052">wakil presiden</Select.Option>
                <Select.Option value="000053">anggota mahkamah konstitusi</Select.Option>
                <Select.Option value="000054">anggota kabinet/kementerian</Select.Option>
                <Select.Option value="000055">duta besar</Select.Option>
                <Select.Option value="000056">gubernur</Select.Option>
                <Select.Option value="000057">wakil gubernur</Select.Option>
                <Select.Option value="000058">bupati</Select.Option>
                <Select.Option value="000059">wakil bupati</Select.Option>
                <Select.Option value="000060">walikota</Select.Option>
                <Select.Option value="000061">wakil walikota</Select.Option>
                <Select.Option value="000062">anggota dprd provinsi</Select.Option>
                <Select.Option value="000063">anggota dprd kabupaten/kota</Select.Option>
                <Select.Option value="000064">dosen</Select.Option>
                <Select.Option value="000065">guru</Select.Option>
                <Select.Option value="000066">pilot</Select.Option>
                <Select.Option value="000067">pengacara</Select.Option>
                <Select.Option value="000068">notaris</Select.Option>
                <Select.Option value="000069">arsitek</Select.Option>
                <Select.Option value="000070">akuntan</Select.Option>
                <Select.Option value="000071">konsultan</Select.Option>
                <Select.Option value="000072">dokter</Select.Option>
                <Select.Option value="000073">bidan</Select.Option>
                <Select.Option value="000074">perawat</Select.Option>
                <Select.Option value="000075">apoteker</Select.Option>
                <Select.Option value="000076">psikiater/psikolog</Select.Option>
                <Select.Option value="000077">penyiar televisi</Select.Option>
                <Select.Option value="000078">penyiar radio</Select.Option>
                <Select.Option value="000079">pelaut</Select.Option>
                <Select.Option value="000080">peneliti</Select.Option>
                <Select.Option value="000081">sopir</Select.Option>
                <Select.Option value="000082">pialang</Select.Option>
                <Select.Option value="000083">paranormal</Select.Option>
                <Select.Option value="000084">pedagang</Select.Option>
                <Select.Option value="000085">perangkat desa</Select.Option>
                <Select.Option value="000086">kepala desa</Select.Option>
                <Select.Option value="000087">biarawati</Select.Option>
                <Select.Option value="000088">wiraswasta formal</Select.Option>
                <Select.Option value="000090">wiraswasta non formal</Select.Option>
                <Select.Option value="000089">lainnya</Select.Option>
            </Select>
        );
    }
}

export default CustomerOccupation;
