import React, {Component} from "react";
import {Link} from "react-router-dom";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import currencyMillionFormat from "../../helper/currencyFormat";
import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Image from "antd/lib/image";
import Skeleton from "antd/lib/skeleton";

class CardVariantPromo extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            company: "amarta",
            dataVariant: {},
            fetching: true,
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await trimitraCatalogServices.getCatalogVariant({
            areaCode: this.props.areaCode,
            variantCode: this.props.variantCode,
        }).then(dataVariantHome => {
            if (dataVariantHome.data?.data[0]) {
                this.setState({
                    dataVariant: dataVariantHome.data?.data[0],
                    fetching: false,
                })
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <Col span={12} style={!this.state.fetching ? {display: 'none'} : {}}>
                    <Skeleton active/>
                </Col>
                <Col span={12} style={this.state.fetching ? {display: 'none'} : {}}>
                    <Link to={`/baru/${this.props.areaCode}/${this.props.variantCode.replace('/', '')}?promo=${this.props.promoCode}`}>
                        <div className={`card-product`}>
                            <div className={`product-img-center`}>
                                <Image preview={false} alt={`Loading Image...`} width={'100%'} src={this.state.dataVariant?.url_image} fallback={'https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/vehicle-not-found.png'}/>
                            </div>
                            <span className={`p-chosen-name`} style={{color: "grey"}}>
                                {this.state.dataVariant?.variant_name}
                            </span><br/>
                            <span className={`p-chosen-price`}>
                                {currencyMillionFormat(this.state.dataVariant?.price)}
                            </span>
                            <Button style={{marginTop: 10, background: "#64b48f", borderColor: "#64b48f"}} block={true} type="primary">
                                Gunakan Promo
                            </Button>
                        </div>
                    </Link>
                </Col>
            </React.Fragment>
        );
    }
}

export default CardVariantPromo;
