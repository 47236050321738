import React, {Component} from 'react';
import {Buffer} from "buffer";
import Cookies from 'universal-cookie';
import {Layout, Image} from "antd";
import withAuth from "../../hoc/withAuth";
import Col from "antd/lib/col";
import MyProfileLoginHeader from "../../components/layouts/MyProfileLoginHeader";
import Avatar from "antd/lib/avatar";
import Row from "antd/lib/row";
import {Link} from "react-router-dom";
import Badge from "antd/lib/badge";
import {appVersion} from "../../config/dataConfig/appConfig";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import CheckCircleTwoTone from '@ant-design/icons/CheckCircleTwoTone';
import MobileOutlined from '@ant-design/icons/MobileOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';
import Tooltip from "antd/lib/tooltip";
import Tag from "antd/lib/tag";
import WhatsAppOutlined from "@ant-design/icons/WhatsAppOutlined";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import {userServices} from "../../services/amartaVip/UserServices";
import FooterSection from "../../components/footer/FooterSection";

export interface MyProfileProps {
}

export interface MyProfileStates extends MyProfileFields {
    showDrawerMenu: boolean;
}

export interface MyProfileFields {
    name: string;
    email: string;
    phoneNumber: string;
    userType: string;
    userCode: string;
    token: string;
    fetchingBiodata: boolean,
    isBiodataComplate: boolean,
    dataBiodata: any,
    emailShowModalVerification: boolean;
    emailSendOtpProcess: boolean,
    emailInputOtpProcess: boolean,
    emailCheckOtpProcess: boolean,
    emailOtpCode: string,
    pageSource: string,
}

class MyProfile extends Component<MyProfileProps & any, MyProfileStates> {
    private cookies = new Cookies();

    constructor(props: MyProfileProps & any) {
        super(props);

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let foo = params.get('internal-page-source');

        this.state = {
            showDrawerMenu: false,
            name: Buffer.from(this.cookies.get('_amh-n'), 'base64').toString(),
            email: Buffer.from(this.cookies.get('_amh-e'), 'base64').toString(),
            phoneNumber: Buffer.from(this.cookies.get('_amh-p'), 'base64').toString(),
            userType: Buffer.from(this.cookies.get('_amh-ut'), 'base64').toString() ?? "",
            userCode: Buffer.from(this.cookies.get('_amh-uc'), 'base64').toString() ?? "",
            token: this.cookies.get('_amh-t'),
            fetchingBiodata: true,
            isBiodataComplate: false,
            dataBiodata: {},
            emailShowModalVerification: false,
            emailSendOtpProcess: false,
            emailInputOtpProcess: false,
            emailCheckOtpProcess: false,
            emailOtpCode: "",
            pageSource: (foo) ? foo : ""
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "phoneNumber" | "emailOtpCode" | "imei">>(target: T, key: number, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "emailOtpCode":
                currentState.emailOtpCode = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });


        if (target === 'emailOtpCode' && value.length === 6) {
            this.setState({
                emailCheckOtpProcess: true,
            });

            try {
                await userServices.verifyAccount({
                    media: "email",
                    email: this.state.email,
                    otp: this.state.emailOtpCode
                }).then(async successData => {
                    Modal.success({
                        title: 'Proses Sukses',
                        content: 'Terimakasih telah melakukan verifikasi email'
                    });
                    setTimeout(function () {
                        window.location.reload();
                    }, 1000);
                });
            } catch (e) {
                Modal.error({
                    title: 'Proses Gagal',
                    content: 'Server Menolak Inputan Anda :' + e
                });
                this.setState({
                    emailCheckOtpProcess: false,
                });
            }
        }
    }

    getBiodata = async () => {
        try {
            await authApiService.setToken(this.state.token);
            const fetchResult = await authApiService.getUserBiodata();
            const dataBiodata = fetchResult?.data?.data;
            const isBiodataComplate = !!(dataBiodata?.id_card?.id_card_number && dataBiodata?.address?.sub_district_code);
            this.setState({
                fetchingBiodata: false,
                isBiodataComplate: isBiodataComplate,
                dataBiodata: dataBiodata
            });
        } catch (e) {
            this.setState({
                fetchingBiodata: false,
                isBiodataComplate: false,
                dataBiodata: {}
            });
        }

        // await authApiService.validateJwt();
    }

    emailVerification = async () => {
        this.setState({
            emailShowModalVerification: true,
            emailInputOtpProcess: false,
            emailOtpCode: "",
        })
    }

    sendOtp = async () => {
        this.setState({
            emailSendOtpProcess: true,
        });

        try {
            await userServices.sendVerifyAccount({
                app_name: "Amartahonda",
                media: "email",
                email: this.state.email
            }).then(successData => {
                this.setState({
                    emailSendOtpProcess: false,
                    emailInputOtpProcess: true,
                    emailCheckOtpProcess: false,
                    emailOtpCode: ""
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            await this.setState({
                emailSendOtpProcess: false
            });
        }
    }

    componentDidMount() {
        this.getBiodata();
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Row style={{backgroundColor: '#fff', paddingBottom: 5, borderBottom: "#f0f2f5 2px solid"}}>
                        <Col xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <MyProfileLoginHeader title={"My Profile"}/>
                        </Col>
                    </Row>
                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={{minHeight: '79vh', backgroundColor: "#fff", padding: "2vh"}}>
                            <Badge.Ribbon text={`User ${this.state.userType}`} style={(this.state.userType === "internal" || this.state.userType === "affiliate") ? {} : {display: 'none'}}>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 10}}>
                                    <Avatar size={70}>{this.state.name?.substring(0, 1)?.toUpperCase()}</Avatar>
                                </div>
                                <div style={{textAlign: "center", paddingTop: 10}}>
                                    <span style={{fontSize: "125%", fontWeight: 480}}>{this.state.name}</span><br/>
                                    <div style={(this.state.userType === "internal" || this.state.userType === "affiliate") ? {} : {display: 'none'}}>
                                        <i className={`text-small-grey-nopadding`}>Kode Agen: {this.state.userCode}</i>
                                    </div>

                                    <MailOutlined/>
                                    <span style={{color: "gray", padding: 5}}>{this.state.email}</span>
                                    <span style={(!this.state.fetchingBiodata) ? {} : {display: 'none'}}>
                                    {this.state?.dataBiodata?.email_verified
                                        ? <Tooltip title="Verified Email"><CheckCircleTwoTone twoToneColor="#52c41a"/></Tooltip>
                                        : <Tag onClick={() => this.emailVerification()} style={{cursor: "pointer"}} color="error">Lakukan Verifikasi</Tag>
                                    }
                                    </span>
                                    <br/>

                                    <MobileOutlined/>
                                    <span style={{color: "gray", padding: 5}}>{this.state.phoneNumber}</span>
                                    <span style={(!this.state.fetchingBiodata) ? {} : {display: 'none'}}>
                                    {this.state?.dataBiodata?.phone_verified
                                        ? <Tooltip title="Verified Phone Number"><CheckCircleTwoTone twoToneColor="#52c41a"/></Tooltip>
                                        : null
                                    }
                                    </span>
                                    <br/>
                                </div>

                                <div style={(this.state.pageSource) ? {textAlign: "center", paddingTop: 50} : {display: 'none'}}>
                                    <a href={this.state.pageSource}>
                                        <Button type="primary">Kembali ke halaman sebelum Login</Button>
                                    </a>
                                </div>

                                <div style={{marginTop: 30}}>
                                    <Row>
                                        <Col xxl={{span: 6}} xl={{span: 6}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} style={{padding: 5}}>
                                            <Link to={`/my-biodata`}>
                                                <div className={`card-product`}>
                                                    <div className={`product-img-center`}>
                                                        <Image className={`img-product-card`} preview={false} alt={`Loading Image...`} width={'100%'} src={`https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/asset/monochromatic-icon/biodata.png`}/>
                                                    </div>
                                                    <p className={`p-chosen-name`}>
                                                        <span style={{color: "black"}}>Biodata</span><br/>
                                                        <span style={{color: "gray", fontSize: "85%"}}>mempercepat proses pemesanan motor</span>
                                                    </p>
                                                    <br/>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={{span: 6}} xl={{span: 6}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} style={{padding: 5}}>
                                            <Link to={`/my-order`}>
                                                <div className={`card-product`}>
                                                    <div className={`product-img-center`}>
                                                        <Image className={`img-product-card`} preview={false} alt={`Loading Image...`} width={'100%'} src={`https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/asset/monochromatic-icon/order.png`}/>
                                                    </div>
                                                    <p className={`p-chosen-name`}>
                                                        <span style={{color: "black"}}>List Order</span><br/>
                                                        <span style={{color: "gray", fontSize: "85%"}}>lihat history pemesanan anda</span>
                                                    </p>
                                                    <br/>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={{span: 6}} xl={{span: 6}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} style={{padding: 5}}>
                                            <Link to={`/my-promo`}>
                                                <div className={`card-product`}>
                                                    <div className={`product-img-center`}>
                                                        <Image className={`img-product-card`} preview={false} alt={`Loading Image...`} width={'100%'} src={`https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/asset/monochromatic-icon/promo.png`}/>
                                                    </div>
                                                    <p className={`p-chosen-name`}>
                                                        <span style={{color: "black"}}>My Promo</span><br/>
                                                        <span style={{color: "gray", fontSize: "85%"}}>lihat promo khusus untuk anda</span>
                                                    </p>
                                                    <br/>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={{span: 6}} xl={{span: 6}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} style={{padding: 5}}>
                                            <Link to={`/my-deal`}>
                                                <div className={`card-product`}>
                                                    <div className={`product-img-center`}>
                                                        <Image className={`img-product-card`} preview={false} alt={`Loading Image...`} width={'100%'} src={`https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/asset/monochromatic-icon/deal.png`}/>
                                                    </div>
                                                    <p className={`p-chosen-name`}>
                                                        <span style={{color: "black"}}>Deal</span><br/>
                                                        <span style={{color: "gray", fontSize: "85%"}}>penawaran menarik amartahonda</span>
                                                    </p>
                                                    <br/>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xxl={{span: 6}} xl={{span: 6}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} style={{padding: 5}}>
                                            <Link to={`/my-bookmark`}>
                                                <div className={`card-product`}>
                                                    <div className={`product-img-center`}>
                                                        <Image className={`img-product-card`} preview={false} alt={`Loading Image...`} width={'100%'} src={`https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/asset/monochromatic-icon/bookmark.png`}/>
                                                    </div>
                                                    <p className={`p-chosen-name`}>
                                                        <span style={{color: "black"}}>Bookmark</span><br/>
                                                        <span style={{color: "gray", fontSize: "85%"}}>list bookmark motor amartahonda</span>
                                                    </p>
                                                    <br/>
                                                </div>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </Badge.Ribbon>
                        </div>
                    </Col>
                    <FooterSection areaCode={'bandung'} version={appVersion} lite={true}/>
                </Layout>

                <Modal
                    title={'Verifikasi Email'}
                    open={this.state.emailShowModalVerification}
                    onCancel={() => this.setState({emailShowModalVerification: false})}
                    footer={[
                        <Button key="close" onClick={() => this.setState({emailShowModalVerification: false})}>
                            Close
                        </Button>
                    ]}
                >
                    <div className={`form-description`} style={(!this.state.emailInputOtpProcess) ? {marginTop: 10, textAlign: "center"} : {display: 'none'}}>
                        System akan mengirimkan kode OTP via Email, silakan tekan kirim OTP, untuk mendapatkan kode OTP. <br/><br/>
                        Email: <b>{this.state.email}</b>
                        <br/><br/>
                        <Button
                            danger={true}
                            type="primary"
                            icon={<WhatsAppOutlined/>}
                            onClick={() => this.sendOtp()}
                            loading={this.state.emailSendOtpProcess}
                        >Kirim OTP </Button>
                    </div>

                    <div className={`form-description`} style={(this.state.emailInputOtpProcess) ? {marginTop: 10} : {display: 'none'}}>
                        Silakan masukan kode OTP yang anda terima. <br/><br/>

                        <Input
                            value={this.state.emailOtpCode}
                            maxLength={6}
                            disabled={this.state.emailCheckOtpProcess}
                            placeholder="Kode OTP"
                            onChange={event => this.onFieldChange("emailOtpCode", 0, event.target.value)}
                        />
                    </div>
                </Modal>

            </React.Fragment>
        );
    }
}

export default withAuth(MyProfile);
