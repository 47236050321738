import axios from "axios";

class DevMomotorService {
    private axios = axios.create({
        baseURL: "https://apidev.momotor.id",
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async getBrand() {
        try {
            return await this.axios.get<any>(`/amartha/brand`, {
                headers: {}
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const devMomotorService = new DevMomotorService();
