import React, {Component} from "react";
import {connect} from "react-redux";
import '../../screen/newVehicleVariantDetailCredit/NewVehicleVariantDetailFormCredit.css';
import TextArea from "antd/es/input/TextArea";
import Checkbox from "antd/lib/checkbox";
import Col from "antd/lib/col";
import Collapse from "antd/lib/collapse";
import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import Typography from "antd/lib/typography";

class CreditSubmissionResume extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {}
        this.state = {...this.initState}
    }

    onFieldChange = <T extends keyof Pick<any, "contactOwnerPhoneNumber" | "contactGuarantorPhoneNumber" | "contactOwnerAlternativePhoneNumber" | "offerNote">>(target: T, value: any) => {
        const currentState = {...this.state};
        switch (target) {
            case "contactOwnerPhoneNumber":
                this.props.changeContactOwnerPhoneNumber(value.replace(/[^0-9.]/g, ''));
                break;
            case "contactGuarantorPhoneNumber":
                this.props.changeContactGuarantorPhoneNumber(value.replace(/[^0-9.]/g, ''));
                if (this.props.guarantorIdCardNumber === this.props.ownerIdCardNumber) {
                    this.props.changeContactOwnerPhoneNumber(value.replace(/[^0-9.]/g, ''));
                }
                break;
            case "contactOwnerAlternativePhoneNumber":
                this.props.changeContactOwnerAlternativePhoneNumber(value.replace(/[^0-9.]/g, ''));
                break;
            case "offerNote":
                this.props.changeOfferNote(value);
                break;
        }
        this.setState({
            ...currentState,
        });
    }

    render() {
        return (
            <React.Fragment>
                <Collapse collapsible="header" defaultActiveKey={['1']} ghost>
                    <Collapse.Panel header="Kepemilikan Kendaraan" key="1">
                        <div style={{paddingLeft: 10}}>
                            <Row><Col span={6}><i className={`text-small-grey-nopadding`}>Nama:</i></Col><Col className={`text-small-black-nopadding`} span={18}> {this.props.ownerIdCardName}</Col></Row>
                            <Row><Col span={6}><i className={`text-small-grey-nopadding`}>Nomor KTP:</i></Col><Col className={`text-small-black-nopadding`} span={18}> {this.props.ownerIdCardNumber}</Col></Row>
                            <Row><Col span={6}><i className={`text-small-grey-nopadding`}>Nomor KK:</i></Col><Col className={`text-small-black-nopadding`} span={18}> {this.props.familyCardNumber}</Col></Row>
                            <Row><Col span={6}><i className={`text-small-grey-nopadding`}>Pernikahan:</i></Col><Col className={`text-small-black-nopadding`} span={18}> {this.props.ownerMaritalStatusName}</Col></Row>
                            <Row><Col span={6}><i className={`text-small-grey-nopadding`}>Pekerjaan:</i></Col><Col className={`text-small-black-nopadding`} span={18}> {this.props.ownerOccupationName}</Col></Row>
                            <Row><Col span={6}><i className={`text-small-grey-nopadding`}>Alamat:</i></Col><Col className={`text-small-black-nopadding`} span={18}> {this.props.ownerFullAddress}</Col></Row>
                            {/*{this.props?.vehicleData?.image_url}*/}
                        </div>
                    </Collapse.Panel>
                </Collapse>

                <Collapse collapsible="header" ghost>
                    <Collapse.Panel header="Pemohon" key="1">
                        <div style={{paddingLeft: 10}}>
                            <Row><Col span={6}><i className={`text-small-grey-nopadding`}>Nama:</i></Col><Col span={18}> {this.props.guarantorIdCardName}</Col></Row>
                            <Row><Col span={6}><i className={`text-small-grey-nopadding`}>Nomor KTP:</i></Col><Col span={18}> {this.props.guarantorIdCardNumber}</Col></Row>
                            <Row><Col span={6}><i className={`text-small-grey-nopadding`}>Alamat:</i></Col><Col span={18}> {this.props.guarantorFullAddress}</Col></Row>
                        </div>
                    </Collapse.Panel>
                </Collapse>

                <Collapse collapsible="header" ghost>
                    <Collapse.Panel header={`Kelengkapan Dokumen`} key="1">
                        <div style={{paddingLeft: 10}}>
                            <Row>
                                <Col span={20}><Typography.Text type="secondary"> KTP Pemilik: </Typography.Text></Col>
                                <Col span={4}><Checkbox checked={!!(this.props.ownerIdCardImage)} disabled={(!this.props.ownerIdCardImage)}/></Col>
                            </Row>
                            <Row>
                                <Col span={20}><Typography.Text type="secondary"> KTP {`${(this.props.ownerMaritalStatus === 'married') ? 'Pasangan' : 'Pemohon'}`}: </Typography.Text></Col>
                                <Col span={4}><Checkbox checked={!!(this.props.guarantorIdCardImage)} disabled={(!this.props.guarantorIdCardImage)}/></Col>
                            </Row>
                            <Row>
                                <Col span={20}><Typography.Text type="secondary"> Kartu Keluarga: </Typography.Text></Col>
                                <Col span={4}><Checkbox checked={!!(this.props.familyCardImage)} disabled={(!this.props.familyCardImage)}/></Col>
                            </Row>
                            <Row>
                                <Col span={20}><Typography.Text type="secondary"> Dokumen Penghasilan: </Typography.Text></Col>
                                <Col span={4}><Checkbox checked={!!(this.props.incomeDocumentImage)} disabled={(!this.props.incomeDocumentImage)}/></Col>
                            </Row>
                            <Row>
                                <Col span={20}><Typography.Text type="secondary"> Dokumen Tempat Tinggal: </Typography.Text></Col>
                                <Col span={4}><Checkbox checked={!!(this.props.placeToStayDocumentImage)} disabled={(!this.props.placeToStayDocumentImage)}/></Col>
                            </Row>
                            <Row>
                                <Col span={20}><Typography.Text type="secondary"> Dokumen Perkawinan: </Typography.Text></Col>
                                <Col span={4}><Checkbox checked={!!(this.props.maritalDocumentImage)} disabled={(!this.props.maritalDocumentImage)}/></Col>
                            </Row>
                        </div>
                    </Collapse.Panel>
                </Collapse>

                <Form layout="vertical">
                    <Divider/>
                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Nomor Telpon Pemohon *</i>
                        <Input
                            maxLength={17}
                            value={this.props.contactGuarantorPhoneNumber}
                            placeholder="Format: 08xxxxxxxx"
                            onChange={event => this.onFieldChange("contactGuarantorPhoneNumber", event.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Nomor Telpon Alternatif</i>
                        <Input
                            maxLength={17}
                            value={this.props.contactOwnerAlternativePhoneNumber}
                            placeholder="Format: 08xxxxxxxx"
                            onChange={event => this.onFieldChange("contactOwnerAlternativePhoneNumber", event.target.value)}
                        />
                    </Form.Item>
                    <Form.Item style={{display: (this.props.guarantorIdCardNumber === this.props.ownerIdCardNumber) ? 'none' : 'block'}}>
                        <i className={`text-small-grey-nopadding`}>Nomor Telpon Pemilik *</i>
                        <Input
                            maxLength={17}
                            value={this.props.contactOwnerPhoneNumber}
                            placeholder="Format: 08xxxxxxxx"
                            onChange={event => this.onFieldChange("contactOwnerPhoneNumber", event.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Catatan *</i>
                        <TextArea showCount maxLength={200} value={this.props.offerNote} onChange={event => this.onFieldChange("offerNote", event.target.value)} placeholder="Catatan jika dibutuhkan"/>
                    </Form.Item>
                </Form><br/>
            </React.Fragment>
        );
    }
}

const reduxState = (state: any) => ({
    vehicleData: state.RvehicleData,

    ownerMaritalStatus: state.RownerMaritalStatus,

    ownerIdCardName: state.RownerIdCardName,
    ownerIdCardNumber: state.RownerIdCardNumber,
    familyCardNumber: state.RfamilyCardNumber,
    ownerMaritalStatusName: state.RownerMaritalStatusName,
    ownerOccupationName: state.RownerOccupationName,
    ownerFullAddress: state.RownerFullAddress,

    guarantorIdCardName: state.RguarantorIdCardName,
    guarantorIdCardNumber: state.RguarantorIdCardNumber,
    guarantorFullAddress: state.RguarantorFullAddress,

    ownerIdCardImage: state.RownerIdCardImage,
    guarantorIdCardImage: state.RguarantorIdCardImage,
    familyCardImage: state.RfamilyCardImage,
    incomeDocumentImage: state.RincomeDocumentImage,
    placeToStayDocumentImage: state.RplaceToStayDocumentImage,
    maritalDocumentImage: state.RmaritalDocumentImage,

    contactOwnerPhoneNumber: state.RcontactOwnerPhoneNumber,
    contactGuarantorPhoneNumber: state.RcontactGuarantorPhoneNumber,
    contactOwnerAlternativePhoneNumber: state.RcontactOwnerAlternativePhoneNumber,
    offerNote: state.RofferNote,
});

const reduxDispatch = (dispatch: any) => ({
    changeContactOwnerPhoneNumber: (data: string) => dispatch({type: 'CHANGE_CONTACT_OWNERPHONENUMBER', value: data}),
    changeContactGuarantorPhoneNumber: (data: string) => dispatch({type: 'CHANGE_CONTACT_GUARANTORPHONENUMBER', value: data}),
    changeContactOwnerAlternativePhoneNumber: (data: string) => dispatch({type: 'CHANGE_CONTACT_OWNERALTERNATIVEPHONENUMBER', value: data}),
    changeOfferNote: (data: string) => dispatch({type: 'CHANGE_OFFER_NOTE', value: data}),
});

export default connect(reduxState, reduxDispatch)(CreditSubmissionResume);
