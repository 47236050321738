import React, {Component} from "react";
import Divider from "antd/lib/divider";
import currencyFormat from "../../helper/currencyFormat";

class MultipleStockDealer extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {}
        this.state = {...this.initState}
    }

    chosenStockDealer = async (data: any, textBadge: string, colorBadge: string) => {
        this.props.onClickMultipleDealerStock({
            data: data,
            textBadge: textBadge,
            colorBadge: colorBadge,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        const availableItem = []
        const unAvailableItem = []
        for (const item of this.props.multipleStockData) {
            const stockColor = item.stock?.data?.color?.[this.props.colorCode] ?? 0;
            if (stockColor > 0) {
                availableItem.push(item)
            } else {
                unAvailableItem.push(item)
            }
        }
        const filteredMultipleStockData = [...availableItem, ...unAvailableItem];

        return (
            <>
                {filteredMultipleStockData.map((item: any, i: number) => {
                    let textBadge = "";
                    let colorBadge = "#64b48f";
                    if (item.stock?.data?.custom_availability) {
                        textBadge = item.stock?.data?.custom_availability;
                    } else {
                        const stockColor = item.stock?.data?.color?.[this.props.colorCode] ?? 0;
                        if (stockColor === 0) {
                            textBadge = 'NA / Pre-Order ';
                            colorBadge = "#e57373";
                        } else if (stockColor < item.stock?.meta?.availability_limit) {
                            textBadge = 'Tersisa ' + stockColor + ' unit ';
                            colorBadge = "#64b48f";
                        } else {
                            textBadge = 'Ready ';
                            colorBadge = "#2196f3";
                        }
                    }

                    return (
                        <div key={i} style={{cursor: "pointer"}} onClick={() => this.chosenStockDealer(item, textBadge, colorBadge)}>
                            <div className={`card-promo-dealer`}>
                                Wilayah {item.city_group?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}
                                <i style={{paddingLeft: 10, color: colorBadge}}>{textBadge}</i>
                            </div>
                            <span style={this.props?.price ? {} : {display: 'none'}} className={`pt-10 detail-product-price`}>
                                {currencyFormat((this.props?.price + item.shipment_cost))}
                            </span>
                            <i className={`card-promo-dealer`} style={{marginLeft: 7}}>(Termasuk Biaya Ongkir {currencyFormat(item.shipment_cost)})</i>
                            <Divider style={{marginTop: 10, marginBottom: 10}}/>
                        </div>
                    );
                })}
            </>
        );
    }
}

export default MultipleStockDealer;
