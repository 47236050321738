import React, {FunctionComponent, useState} from "react";
import "./css/DivClassProduct.css";
import titleCase from "../../helper/titleCase";
import {Dropdown, MenuProps, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";

export const DivClassProduct: FunctionComponent<any> = (props: any) => {
    function onClickClass(data: any) {
        props.onClickClass(data);
    }

    const [selectedItem, setSelectedItem] = useState<string>("Stock Seluruh Wilayah");
    const handleItemClick = (item: string) => {
        setSelectedItem(item);
        if (item === "Stock Seluruh Wilayah") {
            props.onChangeStockSource("ALL");
        } else {
            props.onChangeStockSource(props.areaCode?.toUpperCase());
        }
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <span onClick={() => handleItemClick("Stock Seluruh Wilayah")}>
                    Stock Seluruh Wilayah
                </span>
            ),
        },
        {
            key: '2',
            label: (
                <span onClick={() => handleItemClick(`Stock ${titleCase((props.areaCode || ""))}`)}>
                    Stock {titleCase((props.areaCode || ""))}
                </span>
            ),
        },
    ];

    return (
        <div className={`d-class-prod`}>
            <span onClick={() => onClickClass('ALL')} className={`t-class-prod` + (props.newProductClass === `ALL` ? '-active' : '')}>Semua</span>
            <span onClick={() => onClickClass('MATIC')} className={`t-class-prod` + (props.newProductClass === `MATIC` ? '-active' : '')}>Matic</span>
            <span onClick={() => onClickClass('SPORT')} className={`t-class-prod` + (props.newProductClass === `SPORT` ? '-active' : '')}>Sport</span>
            <span onClick={() => onClickClass('CUB')} className={`t-class-prod` + (props.newProductClass === `CUB` ? '-active' : '')}>Cub</span>
            <span style={{float: "right"}}>
                <Dropdown menu={{items}}>
                    <Space style={{cursor: "pointer"}} className={`text-small-grey-nopadding`}>
                        {selectedItem}
                        <DownOutlined/>
                    </Space>
                </Dropdown>
            </span>
        </div>
    );
}
