import React, {Component} from 'react';
import {Buffer} from "buffer";
import Form from "antd/lib/form";
import Layout from "antd/lib/layout";
import Card from "antd/lib/card";
import SpkInformation2 from "../../components/offer/SpkInformation2";
import withAuth from "../../hoc/withAuth";
import moment from "moment";
import Cookies from 'universal-cookie';
import Col from "antd/lib/col";
import MyProfileLoginHeader from "../../components/layouts/MyProfileLoginHeader";
import DatePicker from "antd/lib/date-picker";
import Button from "antd/lib/button";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import Input from "antd/lib/input";
import Spin from "antd/lib/spin";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import Empty from "antd/lib/empty";
import Badge from "antd/lib/badge";
import {appVersion} from "../../config/dataConfig/appConfig";
import Row from "antd/lib/row";
import FooterSection from "../../components/footer/FooterSection";
import Modal from "antd/lib/modal";
import {MyOrderFields} from "./types/MyOrderTypes";
import Result from "antd/lib/result";
import Tag from "antd/lib/tag";
import LeftOutlined from "@ant-design/icons/LeftOutlined";
import ShoppingOutlined from "@ant-design/icons/ShoppingOutlined";

export interface MyOrderProps {
}

export interface MyOrderStates extends MyOrderFields {
    showDrawerMenu: boolean;
}

class MyOrder extends Component<MyOrderProps & any, MyOrderStates> {
    private cookies = new Cookies();

    constructor(props: MyOrderProps & any) {
        super(props);

        this.state = {
            showDrawerMenu: false,
            isDesktop: false,
            name: Buffer.from(this.cookies.get('_amh-n'), 'base64').toString(),
            email: Buffer.from(this.cookies.get('_amh-e'), 'base64').toString(),
            phoneNumber: Buffer.from(this.cookies.get('_amh-p'), 'base64').toString(),
            userType: Buffer.from(this.cookies.get('_amh-ut'), 'base64').toString() ?? "",
            startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
            endDate: moment(new Date(), 'YYYY-MM-DD'),
            submitting: false,
            transactionString: "Transaksi satu minggu terakhir",
            openDrawer: false,
            token: this.cookies.get('_amh-t'),
            listOrder: [],
            openDetailDrawer: false,
            spkInOffer: false,
            b2bStatus: "",
            dataDetailOrder: {},
            fetchDetailOrder: false,
            b2bLeasingCode: "",
        }
    }

    onFieldChange = <T extends keyof Pick<any, "startDate" | "endDate">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "startDate":
                if (value) {
                    currentState.startDate = value;
                }
                break;
            case "endDate":
                if (value) {
                    currentState.endDate = value;
                }
                break;
        }
        this.setState({
            ...currentState,
        });
    }

    searchOption = async () => {
        const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.state.endDate).format("YYYY-MM-DD");

        await this.promisedSetState({
            transactionString: `${startDate} sampai ${endDate}`,
            openDrawer: false,
        });

        await this.showOrder(moment(this.state.startDate).format("YYYY-MM-DD"), moment(this.state.endDate).format("YYYY-MM-DD"), this.state.transactionString);
    }

    showFilterDrawer = () => {
        this.setState({
            openDrawer: true,
        }, () => {
        });
    }

    showOrder = async (startDate: string, endDate: string, text: string) => {
        this.setState({
            transactionString: text,
            submitting: true,
            showDrawerMenu: false
        })

        await authApiService.setToken(this.state.token);
        try {
            const data = await authApiService.getUserOrder({
                startDate: startDate,
                endDate: endDate,
            });
            this.setState({
                submitting: false,
                listOrder: data?.data?.data,
            });
        } catch (e) {
            this.setState({
                submitting: false,
                listOrder: [],
            });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();

        this.showOrder(moment(this.state.startDate).format("YYYY-MM-DD"), moment(this.state.endDate).format("YYYY-MM-DD"), this.state.transactionString);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    searchText = async (text: string) => {
        switch (text) {
            case "thisday":
                await this.promisedSetState({
                    startDate: moment(new Date(), 'YYYY-MM-DD'),
                    endDate: moment(new Date(), 'YYYY-MM-DD'),
                    transactionString: "Transaksi hari ini",
                    openDrawer: false
                });
                break;
            case "thisweek":
                await this.promisedSetState({
                    startDate: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'weeks'),
                    endDate: moment(new Date(), 'YYYY-MM-DD'),
                    transactionString: "Transaksi minggu ini",
                    openDrawer: false
                });
                break;
            case "thismonth":
                await this.promisedSetState({
                    startDate: moment(new Date(), 'YYYY-MM-DD').startOf('month'),
                    endDate: moment(new Date(), 'YYYY-MM-DD').endOf('month'),
                    transactionString: "Transaksi bulan ini",
                    openDrawer: false
                });
                break;
            case "lastmonth":
                await this.promisedSetState({
                    startDate: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'months').startOf('month'),
                    endDate: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'months').endOf('month'),
                    transactionString: "Transaksi 1 bulan lalu",
                    openDrawer: false
                });
                break;
        }

        await this.showOrder(moment(this.state.startDate).format("YYYY-MM-DD"), moment(this.state.endDate).format("YYYY-MM-DD"), this.state.transactionString);
    }

    getDetailOrder = async (offerCode: string) => {
        this.setState({
            openDetailDrawer: true,
            dataDetailOrder: {},
            spkInOffer: false,
            b2bStatus: "",
            fetchDetailOrder: true,
        });

        await authApiService.setToken(this.state.token);
        try {
            const data = await authApiService.getDetailUserOrder({offerCode: offerCode,});
            this.setState({
                dataDetailOrder: data?.data?.data,
                spkInOffer: data?.data?.offer,
                b2bStatus: data?.data?.b2b_status ?? "",
                b2bLeasingCode: data?.data?.data_full_b2b?.survey?.adira_host_2_host?.submitDataResponse?.OrderNo ?? "",
                fetchDetailOrder: false,
            });
        } catch (e) {
            this.setState({
                dataDetailOrder: {},
                spkInOffer: false,
                b2bStatus: "",
                fetchDetailOrder: false,
            });
            console.log(e);
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <div style={(!this.state.openDetailDrawer) ? {} : {display: 'none'}}>
                        <Row style={{backgroundColor: '#fff', paddingBottom: 5, borderBottom: "#f0f2f5 2px solid"}}>
                            <Col xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <MyProfileLoginHeader title={"My Order"}/>
                            </Col>
                        </Row>
                        <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <div style={{minHeight: '77vh', backgroundColor: "#fff", padding: "2vh"}}>
                                <div onClick={this.showFilterDrawer} className={"search-box-home"}>
                                    <Input
                                        style={{borderRadius: 7}}
                                        size="large"
                                        placeholder={this.state.transactionString}
                                        prefix={<SearchOutlined/>}
                                        value={""}
                                    />
                                </div>
                                <div style={this.state.submitting ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                                </div>
                                <div style={!this.state.submitting ? {padding: 7} : {display: 'none'}}>
                                    <div style={this.state.listOrder.length > 0 ? {} : {display: 'none'}}>
                                        <Row>
                                            {this.state.listOrder.map((item: any, i: number) => {
                                                let offerStatus = item?.transaction_status;
                                                if (item?.transaction_status === "open") {
                                                    offerStatus = <Tag style={{fontSize: "70%", display: "inline"}} color="#108ee9">OnProcess</Tag>;
                                                } else if (item?.transaction_status === "cancel") {
                                                    offerStatus = <Tag style={{fontSize: "70%", display: "inline"}} color="#f50">Cacnel</Tag>
                                                } else if (item?.transaction_status === "order") {
                                                    offerStatus = <Tag style={{fontSize: "70%", display: "inline"}} color="#87d068">Ordered</Tag>;
                                                } else if (item?.transaction_status === "handover") {
                                                    offerStatus = <Tag style={{fontSize: "70%", display: "inline"}} color="#7D50C7">Handover</Tag>;
                                                }

                                                return (<Col style={{padding: 10}} key={i} xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                    <Badge.Ribbon text={(item?.transaction_type === "cash" ? "Tunai" : "Kredit")} color={(item?.transaction_type === "cash" ? "blue" : "magenta")}>
                                                        <Card onClick={() => this.getDetailOrder(item?.transaction_code)} key={i} hoverable size={"small"} style={{width: "100%"}}>
                                                            <div style={{minHeight: 85}}>
                                                                <div>
                                                                    <i className={`text-extra-small-grey-nopadding`}>Nomor SPK:</i><br/> {item?.transaction_code} &nbsp;
                                                                    {offerStatus}
                                                                </div>
                                                                <div style={item?.transaction_leasing_code ? {} : {display: 'none'}}>
                                                                    <div><i className={`text-extra-small-grey-nopadding`}>Kode Transaksi Leasing:</i><br/> {item?.transaction_leasing_code}</div>
                                                                </div>
                                                                <div style={item?.notes ? {padding: 7} : {display: 'none'}}>
                                                                    <span className={`text-extra-small-grey-nopadding`}>{item?.notes}</span>
                                                                </div>
                                                            </div>
                                                            <div style={{padding: 5}}/>
                                                            <span><i className={`text-extra-small-grey-nopadding`}><ShoppingOutlined /> {item?.transaction_time?.slice(0, 10)}</i></span>
                                                            <span style={{float: "right", cursor: "pointer", fontSize: "95%", fontWeight: 500, color: "#64b48f"}}>Lihat Detail Transaksi</span>
                                                        </Card>
                                                    </Badge.Ribbon>
                                                </Col>);
                                            })}
                                        </Row>
                                    </div>
                                    <div style={this.state.listOrder.length === 0 ? {} : {display: 'none'}}>
                                        <Empty/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>

                    <div style={(this.state.openDetailDrawer) ? {} : {display: 'none'}}>
                        <Col className={`p-10`} xxl={{span: 17, offset: 3}} xl={{span: 17, offset: 3}} lg={{span: 20, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <div className={`variant-header`}>
                                <LeftOutlined onClick={() => this.setState({openDetailDrawer: false})} className={'hedaer2-back'}/>
                                <b className={`header2-title`}>Detail SPK</b>
                            </div>
                            <div style={this.state.fetchDetailOrder ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                            </div>
                            <div style={!this.state.fetchDetailOrder ? {} : {display: 'none'}}>
                                <div style={!this.state.dataDetailOrder?.transaction_code ? {} : {display: 'none'}}>
                                    <Result status="warning" title="Data Tidak Ditemukan."/>
                                </div>
                                <div style={this.state.dataDetailOrder?.transaction_code ? {padding: 20} : {display: 'none'}}>
                                    <SpkInformation2 codeSpk={this.state.dataDetailOrder?.transaction_code} spkInOffer={this.state.spkInOffer} userType={this.state.userType} b2bStatus={this.state.b2bStatus} dataSpk={this.state.dataDetailOrder} b2bLeasingCode={this.state.b2bLeasingCode}/>
                                </div>
                            </div>
                        </Col>
                    </div>

                    <FooterSection areaCode={'bandung'} version={appVersion} lite={true}/>


                    <Modal
                        title=""
                        onCancel={() => this.setState({openDrawer: false})}
                        open={this.state.openDrawer}
                        getContainer={false}
                        style={{top: 10}}
                        width={(this.state.isDesktop ? "50%" : "90%")}
                        footer={[]}
                    >
                        <div className="outer-wrapper">
                            <div className="inner-wrapper">
                                <Button onClick={() => this.searchText("thisday")} key={1} style={{margin: 5}} shape="round" icon={<SearchOutlined/>}> Hari Ini </Button>
                                <Button onClick={() => this.searchText("thisweek")} key={2} style={{margin: 5}} shape="round" icon={<SearchOutlined/>}> Minggu Ini </Button>
                                <Button onClick={() => this.searchText("thismonth")} key={4} style={{margin: 5}} shape="round" icon={<SearchOutlined/>}> Bulan Ini </Button>
                                <Button onClick={() => this.searchText("lastmonth")} key={5} style={{margin: 5}} shape="round" icon={<SearchOutlined/>}> Bulan Lalu </Button>
                            </div>
                        </div>
                        <div className="pseduo-track"></div>
                        <br/>
                        <Form.Item className={'padTop-7'}>
                            <i className={`text-small-grey-nopadding`}>Tanggal Awal</i>
                            <DatePicker value={moment(this.state.startDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`text-small-grey-nopadding`}>Tanggal Akhir</i>
                            <DatePicker value={moment(this.state.endDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("endDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                        </Form.Item>
                        <Button style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} loading={this.state.submitting} onClick={() => this.searchOption()} type="primary"> Cari Transaksi</Button>
                    </Modal>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withAuth(MyOrder);