import React, {Component} from "react";
import {NewVehicleVariantDetailInformationStates} from "./types/NewVehicleVariantDetailInformationTypes";
import {withRouter} from "../../hoc/withRouter";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import VariantDetailLayout from "../../components/layouts/VariantDetailLayout";
import variantCodeUrlFormat from "../../helper/variantCodeUrlFormat";
import './NewVehicleVariantDetailInformation.css';
import Tabs from "antd/lib/tabs";
import Image from "antd/lib/image";
import Spin from "antd/lib/spin";
import Empty from "antd/lib/empty";
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import {trackLogServices} from "../../services/track/TrackLogService";
const {TabPane} = Tabs;

class NewVehicleVariantDetailInformation extends Component<any, NewVehicleVariantDetailInformationStates> {
    private readonly initState!: NewVehicleVariantDetailInformationStates;
    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const areaCode = match.params.area;
        const variantCode = variantCodeUrlFormat(match.params.productcode);
        document.title = `Amartahonda | Info | Detail Variant Kendaraan Baru | ${areaCode} | ${variantCode}`;

        this.initState = {
            areaCode: areaCode,
            variantCode: variantCode,
            showOfferCard: false,
            completeFetchVariant: false,
            listNewVariantColor: [],
            chosenNewVariantColor: {},
            chosenVariantColorIndex: 0,
            dataInstallment: {},
            detailImage: [],
            detailVideo: [],
            detailDocument: [],
            detailSocialMedia: [],
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await trimitraCatalogServices.getCatalogVariant({
            areaCode: this.state.areaCode,
            variantCode: this.state.variantCode,
        }).then(dataVariantDetail => {
            this.setState({
                listNewVariantColor: dataVariantDetail.data.data,
                chosenNewVariantColor: dataVariantDetail.data.data[0],
            });
        });

        try {
            await trimitraCatalogServices.getCatalogDetailVariant({
                variantCode: this.state.variantCode,
            }).then(dataDetail => {
                let detailImage = (dataDetail?.data?.data[0]?.detail_image) ? dataDetail?.data?.data[0]?.detail_image : [];
                let detailVideo = (dataDetail?.data?.data[0]?.detail_video) ? dataDetail?.data?.data[0]?.detail_video : [];
                let detailSocialMedia = (dataDetail?.data?.data[0]?.detail_social_media) ? dataDetail?.data?.data[0]?.detail_social_media : [];
                let detailDocument = (dataDetail?.data?.data[0]?.detail_document) ? dataDetail?.data?.data[0]?.detail_document : [];

                this.setState({
                    completeFetchVariant: true,
                    detailImage: detailImage,
                    detailVideo: detailVideo,
                    detailSocialMedia: detailSocialMedia,
                    detailDocument: detailDocument,
                });
            });
        } catch (e) {
            this.setState({
                completeFetchVariant: true,
                detailImage: [],
                detailVideo: [],
                detailSocialMedia: [],
                detailDocument: [],
            });
        }

        trackLogServices.sendTrackLog({
            eventName: `vehicle detail information page`,
            eventSourceUrl: window.location.href,
            customData: null
        });
    }

    render() {
        return (
            <VariantDetailLayout parameterCash={null} parameterCredit={null} hideTop={false} linkBack={`/baru/${this.state.areaCode}/${this.state.variantCode.replace('/','')}`} areaCode={this.state.areaCode} productCode={this.state.variantCode + "-" + this.state.chosenNewVariantColor?.color_code} productTitle={this.state.chosenNewVariantColor?.variant_name}>
                <React.Fragment>
                    <Tabs className={`height-90`} defaultActiveKey="tabVideo" style={{paddingLeft: 10, paddingRight: 10}}>
                        <TabPane tab="Video" key="tabVideo">
                            {
                                !this.state.completeFetchVariant
                                    ? <div style={{textAlign: "center", padding: 70}}>
                                        <Spin tip="Loading..."/>
                                    </div>
                                    : ''
                            }

                            {
                                this.state.completeFetchVariant && this.state.detailVideo.length === 0
                                    ? <div style={{textAlign: "center", padding: 70}}>
                                        <Empty/> <br/>
                                    </div>
                                    : ''
                            }

                            {
                                this.state.detailVideo.map((item: any, i: number) =>
                                    <a href={item.url} target={'_blank'} rel="noreferrer" key={i}>
                                        <div className="acontainer">
                                            <Image style={{width: '100%'}} preview={false} src={item.url_thumbnail} alt={`iamge`} fallback={'https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/asset/banner-not-found.jpg'}/>
                                            <div className="bottom-left">{item.caption}</div>
                                            <div className="centered">
                                                <Image style={{width: '50px'}} preview={false} src={'https://trimitra-catalog.s3-ap-southeast-1.amazonaws.com/asset/playvideo.png'} alt={`playvideo-logo`}/>
                                            </div>
                                        </div>
                                    </a>
                                )
                            }
                        </TabPane>
                        <TabPane tab="Foto" key="tabImage">
                            {
                                !this.state.completeFetchVariant
                                    ? <div style={{textAlign: "center", padding: 70}}>
                                        <Spin tip="Loading..."/>
                                    </div>
                                    : ''
                            }

                            {
                                this.state.completeFetchVariant && this.state.detailImage.length === 0
                                    ? <div style={{textAlign: "center", padding: 70}}>
                                        <Empty/> <br/>
                                    </div>
                                    : ''
                            }

                            {
                                this.state.detailImage.map((item: any, i: number) =>
                                    <div className="acontainer" key={i}>
                                        <Image style={{width: '100%'}} preview={true} src={item.url} alt={`iamge`} fallback={'https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/asset/banner-not-found.jpg'}/>
                                        <div className="bottom-left">{item.caption}</div>
                                    </div>
                                )
                            }
                        </TabPane>
                        <TabPane tab="Dokumen" key="tabDocument">
                            {
                                !this.state.completeFetchVariant
                                    ? <div style={{textAlign: "center", padding: 70}}>
                                        <Spin tip="Loading..."/>
                                    </div>
                                    : ''
                            }

                            {
                                this.state.completeFetchVariant && this.state.detailDocument.length === 0
                                    ? <div style={{textAlign: "center", padding: 70}}>
                                        <Empty/> <br/>
                                    </div>
                                    : ''
                            }

                            {
                                this.state.detailDocument.map((item: any, i: number) =>
                                    <div key={i}>
                                        <text>{item.caption}</text>
                                        <text style={{float: "right", paddingRight:20, fontSize: "125%"}}>
                                            <a href={item.url} target={'_blank'} rel="noreferrer">
                                                <DownloadOutlined style={{color: '#64b48f'}}/>
                                            </a>
                                        </text>
                                    </div>
                                )
                            }
                            <br/>
                        </TabPane>
                        <TabPane tab="Media Sosial" key="tabsocialMedia">
                            {
                                !this.state.completeFetchVariant
                                    ? <div style={{textAlign: "center", padding: 70}}>
                                        <Spin tip="Loading..."/>
                                    </div>
                                    : ''
                            }

                            {
                                this.state.completeFetchVariant && this.state.detailSocialMedia.length === 0
                                    ? <div style={{textAlign: "center", padding: 70}}>
                                        <Empty/> <br/>
                                    </div>
                                    : ''
                            }

                            {
                                this.state.detailSocialMedia.map((item: any, i: number) =>
                                    <div key={i}>
                                        <text>{item.caption}</text>
                                        <text style={{float: "right", paddingRight:20, fontSize: "125%"}}>
                                            <a href={item.url} target={'_blank'} rel="noreferrer">
                                                <ArrowRightOutlined style={{color: '#64b48f'}}/>
                                            </a>
                                        </text>
                                    </div>
                                )
                            }
                            <br/>
                        </TabPane>
                    </Tabs>
                </React.Fragment>
            </VariantDetailLayout>
        );
    }
}

export default withRouter(NewVehicleVariantDetailInformation);
