import axios from "axios";
import {baseUrlAmartaVip, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";

class VipPromoApiService {
    private axios = axios.create({
        baseURL: baseUrlAmartaVip,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }


    public async getUserPromo() {
        try {
            return await this.axios.get<any>(`/profile/promo/amartahonda`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const vipPromoApiService = new VipPromoApiService();
