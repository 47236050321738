import React, {Component} from 'react';
import "./css/SimpleLayout.css";
import Col from "antd/lib/col";
import Layout from "antd/lib/layout";

const {Content} = Layout;

class CashSubmissionLayout extends Component<any> {
    render() {
        return (
            <Layout className="layout">
                <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}}>
                    <div className={`variant-header`}>
                        &nbsp; &nbsp;
                        <b className={`header2-title`}>{this.props.headerTitle}</b>
                    </div>
                    <Content style={{backgroundColor: "white"}}>
                        {this.props.children}
                    </Content>
                </Col>
            </Layout>
        );
    }
}

export default CashSubmissionLayout;
