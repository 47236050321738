import React, {Component} from 'react';
import {withRouter} from "../../hoc/withRouter";
import "../../screen/home/Home.css";
import {MokasStates} from "./types/MokasTypes";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import NormalHeader from "../../components/layouts/NormalHeader";
import FooterSection from "../../components/footer/FooterSection";
import {Breadcrumb, Button} from "antd";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import Spin from "antd/lib/spin";
import Empty from "antd/lib/empty";
import {CardProduct} from "../../components/product/CardProduct";
import {Link} from "react-router-dom";

class Mokas extends Component<any, MokasStates> {
    private readonly initState!: MokasStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        document.title = "Amartahonda | Mokas | " + match.params.area.trim().toUpperCase();

        this.initState = {
            company: "AMARTA",
            areaCode: match.params.area.trim().toUpperCase(),
            isDesktop: false,
            processingFetchData: false,
            dataUsedVehicle: [],
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    async componentDidMount() {
        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();
        this.fetchRequestUsedVehicleData();
    }

    fetchRequestUsedVehicleData = async () => {
        this.setState({
            processingFetchData: true,
        })
        try {
            const tableData = await trimitraCatalogServices.getPublicUsedVehicleMaster({
                company: "amarta",
                state: "active",
                area: this.state.areaCode
            });
            await this.promisedSetState({
                dataUsedVehicle: tableData.data.data,
                processingFetchData: false,
            });
        } catch (e: any) {
            await this.promisedSetState({
                dataUsedVehicle: [],
                processingFetchData: false,
            });
        }
    }

    onAreaChange = async (data: any) => {
        await this.promisedSetState({
            areaCode: data.city_group,
        });
        document.title = "Amartahonda | Motor Bekas | " + data?.city_group?.toUpperCase();
        this.props.history.push("/" + data?.city_group?.toLowerCase() + "/mokas");
        this.fetchRequestUsedVehicleData();
    }
    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <NormalHeader areaCode={this.state.areaCode} onAreaChangeProps={this.onAreaChange} isDesktop={this.state.isDesktop}/>
                <Row>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>AmartaHonda</Breadcrumb.Item>
                            <Breadcrumb.Item>Motor Bekas</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row style={{backgroundColor: "#f8f8f8"}}>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col className={`p-20`} xxl={{span: 16}} xl={{span: 16}} md={{span: 16}} sm={{span: 24}} xs={{span: 24}}>
                                <div className={`detail-product-title`}>AmartaHonda - Motor Bekas</div>
                                <br/>
                                <div className={`detail-product-caption`}>Jual motor anda dengan mudah di amartahonda.com Jangkau ribuan calon pembeli dengan mudah. <br/><br/> Ingin menjual motor Anda? lengkapi data pemilik dan data kendaraan.</div>
                                <br/>

                                <Link to={`/jual-motor`}><Button style={{background: "#64b48f", color: "#fff", borderColor: "#64b48f", marginTop: 10, marginBottom: 20}}> Jual Motor Honda Saya </Button></Link>
                            </Col>
                            <Col className={`p-10`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 0}} xs={{span: 0}}>
                                <img src={`https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/money-transfer.png`} style={{height: 200}} alt={`logo`}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div style={this.state.processingFetchData ? {textAlign: "center", padding: 20, minHeight: 300} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                </div>

                <div style={(!this.state.processingFetchData && this.state.dataUsedVehicle.length < 1) ? {minHeight: 300} : {display: 'none'}}>
                    <Empty/>
                </div>

                <div style={!this.state.processingFetchData ? {} : {display: 'none'}}>
                    <Row>
                        <Col style={{minHeight: 300}} className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>

                            <Row className={`home-row-chosen-product`}>
                                {this.state.dataUsedVehicle.map((item: any, i: number) => {
                                    return (
                                        <Col xl={{span: 6}} lg={{span: 5}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} key={i}>
                                            <CardProduct option={'MOKAS'} uid={item.used_vehicle_uid} registration_year={item.vehicle_year} productImageUrl={item.url_image} product={item.model_name} productArea={item.vehicle_city_name} productName={item.vehicle_caption} productPrice={item.price} productStrikethroughtPrice={false}/>
                                        </Col>
                                    );
                                })}
                            </Row>

                        </Col>
                    </Row>
                </div>

                <FooterSection areaCode={this.state.areaCode}/>
            </React.Fragment>
        );
    }
}

export default withRouter(Mokas);
