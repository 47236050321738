import React, {Component} from 'react';
import Col from "antd/lib/col";
import Layout from "antd/lib/layout";
import LeftOutlined from '@ant-design/icons/LeftOutlined';
const {Content} = Layout;

class SimpleLayout extends Component<any> {
    render() {
        let onChange = (value: any) => {
            this.props.onClickBack({
                source: value
            });
        };
        return (
            <Layout className="layout">
                <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}}>
                    <div className={`variant-header`}>
                        <LeftOutlined style={(this.props.backToHome === "") ? {display: 'none'} : {}} onClick={() => onChange('backToHome')} className={'hedaer2-back'}/>
                        <b className={`header2-title`} style={(this.props.backToHome === "") ? {} : {display: 'none'}} >{this.props.headerTitle}</b>
                        <b className={`header2-title`} style={(this.props.backToHome === "deposit") ? {} : {display: 'none'}} >Pembayaran Tanda Jadi</b>
                        <b className={`header2-title`} style={(this.props.backToHome === "settlement") ? {} : {display: 'none'}} >Pembayaran Pelunasan</b>
                    </div>
                    <Content style={{backgroundColor: "white"}}>
                        {this.props.children}
                    </Content>

                </Col>
            </Layout>
        );
    }
}

export default SimpleLayout;
