import React, {Component} from 'react';
import {Buffer} from "buffer";
import {Layout, Divider} from "antd";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import Col from "antd/lib/col";
import MyProfileLoginHeader from "../../components/layouts/MyProfileLoginHeader";
import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import Empty from "antd/lib/empty";
import Badge from "antd/lib/badge";
import {appVersion} from "../../config/dataConfig/appConfig";
import FooterSection from "../../components/footer/FooterSection";
import {vipPromoApiService} from "../../services/amartaVip/VipPromoApiService";
import CardMyPromo from "../../components/promo/CardMyPromo";

export interface MyBookmarkProps {
}

class MyBookmark extends Component<MyBookmarkProps & any, any> {
    private cookies = new Cookies();

    constructor(props: MyBookmarkProps & any) {
        super(props);

        this.state = {
            name: Buffer.from(this.cookies.get('_amh-n'), 'base64').toString(),
            email: Buffer.from(this.cookies.get('_amh-e'), 'base64').toString(),
            phoneNumber: Buffer.from(this.cookies.get('_amh-p'), 'base64').toString(),
            fetching: true,
            methodSelected: "all",
            modelSelected: "all",
            allowAgent: false,
            listModel: [],
            dataPromo: [],
        }
    }

    async componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);

        await this.fetchPromoData();
    }

    fetchPromoData = async () => {
        const listUserPromo = [];

        await vipPromoApiService.setToken(this.cookies.get('_amh-t'));
        try {
            const listPromo = await vipPromoApiService.getUserPromo();
            const tempData = listPromo?.data?.data ?? [];
            for (const item of tempData) {
                listUserPromo.push(item);
            }
            this.setState({
                dataPromo: listUserPromo,
                fetching: false,
            });
        } catch (e) {
            this.setState({
                dataPromo: [],
                fetching: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Row style={{backgroundColor: '#fff', paddingBottom: 5, borderBottom: "#f0f2f5 2px solid"}}>
                        <Col xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <MyProfileLoginHeader title={"My Promo"}/>
                        </Col>
                    </Row>
                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>

                        <div style={{minHeight: '79vh', backgroundColor: "#fff", padding: "1vh"}}>
                            <Divider orientation="left" plain>
                                List Promo
                            </Divider>

                            <div style={this.state.fetching ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                            </div>

                            <div style={!this.state.fetching ? {} : {display: 'none'}}>

                                <Row>
                                    {this.state.dataPromo.map((item: any, i: number) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <Col style={{padding: 10}} className={`p-10`} xxl={{span: 8}} xl={{span: 8}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                                    <Badge.Ribbon color={(item?.purchase_method[0] === "credit" ? "#64b48f" : "blue")} text={(item?.purchase_method[0] === "credit" ? "Kredit" : "Tunai")}>
                                                        <CardMyPromo data={item}/>
                                                    </Badge.Ribbon>
                                                </Col>
                                            </React.Fragment>
                                        );
                                    })}
                                </Row>

                                <div style={this.state.dataPromo.length === 0 ? {} : {display: 'none'}}>
                                    <Empty/>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <FooterSection areaCode={'bandung'} version={appVersion} lite={true}/>
                </Layout>

            </React.Fragment>
        );
    }
}

export default withAuth(MyBookmark);
