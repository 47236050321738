import React from "react";
import "./Testimonial.css";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

const Testimonial = () => {
    return (
        <div className={`chosen-product`} style={{marginBottom: 20}}>
            <span className={`chosen-product-text`}>TESTIMONI</span>
            <div className={`card-testimony`}>
                <Row>
                    <Col xl={{span: 4}} lg={{span: 4}} md={{span: 6}} sm={{span: 6}} xs={{span: 6}}>
                        <img className={`testi-img`} alt={`imageamarta`} width={'60'} src={'/assets/img/others/testimoni.png'}/>
                    </Col>
                    <Col xl={{span: 20}} lg={{span: 20}} md={{span: 18}} sm={{span: 18}} xs={{span: 18}}>
                        <div className={`testi-name`}>Devi Wardhani</div>
                        <div className={`testi-info`}>Pegawai Swasta</div>
                    </Col>
                    <Col span={24}>
                        <hr style={{color: '#bfbfbf', backgroundColor: '#bfbfbf', height: 1, marginLeft: 15, marginRight: 15}}/>
                        <p className={`testi-p`}>Pelayanan puas dgn bapak ferdi tepat waktu yg seperti di janjikan pokoknya puas bgt bli motor di amartahonda warna sesuai apa yg saya mau</p>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Testimonial;
