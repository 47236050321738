import React, {Component} from 'react';
import {Buffer} from "buffer";
import {Layout, Divider} from "antd";
import Image from "antd/lib/image";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import Col from "antd/lib/col";
import MyProfileLoginHeader from "../../components/layouts/MyProfileLoginHeader";
import {bookmarkApiService} from "../../services/amartaVip/BookmarkApiService";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import Row from "antd/lib/row";
import currencyMillionFormat from "../../helper/currencyFormat";
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Spin from "antd/lib/spin";
import Empty from "antd/lib/empty";
import {appVersion} from "../../config/dataConfig/appConfig";
import FooterSection from "../../components/footer/FooterSection";

export interface MyBookmarkProps {
}

export interface MyBookmarkStates extends MyBookmarkFields {
    showDrawerMenu: boolean;
}

export interface MyBookmarkFields {
    name: string;
    email: string;
    phoneNumber: string;
    listBookmarkFinal: any;
    modalConfirm: boolean;
    removeProcessing: boolean;
    dataToRemove: any;
    fetchBookmarkProcessing: boolean;
}

class MyBookmark extends Component<MyBookmarkProps & any, MyBookmarkStates> {
    private cookies = new Cookies();

    constructor(props: MyBookmarkProps & any) {
        super(props);

        this.state = {
            showDrawerMenu: false,
            name: Buffer.from(this.cookies.get('_amh-n'), 'base64').toString(),
            email: Buffer.from(this.cookies.get('_amh-e'), 'base64').toString(),
            phoneNumber: Buffer.from(this.cookies.get('_amh-p'), 'base64').toString(),
            listBookmarkFinal: [],
            modalConfirm: false,
            removeProcessing: false,
            dataToRemove: {},
            fetchBookmarkProcessing: true,
        }
    }

    async componentDidMount() {
        const listVehicleBookmark = [];
        const finalBookmark: any = [];
        await bookmarkApiService.setToken(this.cookies.get('_amh-t'));
        try {
            const listBookmark = await bookmarkApiService.getUserBookmark();
            const tempData = listBookmark?.data?.data ?? [];
            for (const item of tempData) {
                listVehicleBookmark.push(item?.custom_data);
            }
        } catch (e) {
            console.log(e);
        }

        await Promise.all(listVehicleBookmark.map(async (data) => {
            try {
                const dataVariant = await trimitraCatalogServices.getCatalogVariant({
                    areaCode: data?.area,
                    variantCode: data?.code,
                });

                finalBookmark.push({
                    area: data?.area,
                    type: data?.type,
                    code: data?.code,
                    name: dataVariant?.data?.meta?.VariantName,
                    price: dataVariant?.data?.meta?.Price,
                    image_url: dataVariant?.data?.meta?.UrlImage,
                });
            } catch (e) {
                console.log(e);
            }
        }));

        await this.promisedSetState({
            listBookmarkFinal: finalBookmark,
            fetchBookmarkProcessing: false
        });
    }

    onClickProduct = async (data: any) => {
        if (data?.type === "VEHICLE_VARIANT") {
            window.location.href = `/baru/${data.area.toLowerCase()}/${data?.code?.replace('/', '')}`;
        }
    }

    removeBookmark = async (data: any) => {
        await this.promisedSetState({
            dataToRemove: data,
            modalConfirm: true,
        });
    }

    okModalConfirm = async () => {
        this.setState({
            removeProcessing: true,
        });

        await bookmarkApiService.setToken(this.cookies.get('_amh-t'));
        try {
            await bookmarkApiService.removeBookmark({
                "source": "amartahonda",
                "code": this.state.dataToRemove.type + "#" + this.state.dataToRemove.code
            });

            const oldBookmarkList = [...this.state.listBookmarkFinal];
            const newBookmarkList = [];
            for (const item of oldBookmarkList) {
                if (item?.code !== this.state.dataToRemove.code) {
                    newBookmarkList.push(item);
                }
            }

            this.setState({
                removeProcessing: false,
                modalConfirm: false,
                listBookmarkFinal: newBookmarkList
            });

            Modal.success({
                title: 'Proses Sukses',
                content: 'data berhasil di hapus dari bookmarkAndSubsciption'
            });
        } catch (e) {
            Modal.error({
                title: 'Register Failed',
                content: 'error: ' + e,
            });

            this.setState({
                removeProcessing: false,
                modalConfirm: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Row style={{backgroundColor: '#fff', paddingBottom: 5, borderBottom: "#f0f2f5 2px solid"}}>
                        <Col xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <MyProfileLoginHeader title={"My Bookmark"}/>
                        </Col>
                    </Row>
                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>

                        <div style={{minHeight: '79vh', backgroundColor: "#fff", padding: "1vh"}}>
                            <Divider orientation="left" plain>
                                List Bookmark
                            </Divider>

                            <div style={this.state.fetchBookmarkProcessing ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                            </div>

                            <div style={!this.state.fetchBookmarkProcessing ? {} : {display: 'none'}}>
                                <div style={this.state.listBookmarkFinal.length > 0 ? {} : {display: 'none'}}>
                                    <Row className={`home-row-chosen-product`}>
                                        {this.state.listBookmarkFinal.map((item: any, i: number) => {
                                            return (
                                                <Col key={i} xxl={{span: 6}} xl={{span: 6}} md={{span: 6}} sm={{span: 12}} xs={{span: 12}} style={{padding: 5}}>
                                                    <div className={`card-product`}>
                                                        <div className={`product-img-center`} onClick={() => this.onClickProduct(item)}>
                                                            <Image className={`img-product-card`} preview={false} alt={`Loading Image...`} width={'100%'} src={item?.image_url} fallback={'https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/vehicle-not-found.png'}/>
                                                        </div>
                                                        <p className={`p-chosen-name`} onClick={() => this.onClickProduct(item)}>
                                                            {item?.name}
                                                        </p>
                                                        <p className={`p-chosen-price`} onClick={() => this.onClickProduct(item)}>
                                                            {currencyMillionFormat(item?.price)}
                                                        </p>
                                                        <div style={{textAlign: "right"}}>
                                                            <DeleteOutlined onClick={() => this.removeBookmark(item)} style={{padding: 10, fontSize: "120%", color: "#d03d3d"}}/>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </div>
                                <div style={this.state.listBookmarkFinal.length === 0 ? {} : {display: 'none'}}>
                                    <Empty/>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <FooterSection areaCode={'bandung'} version={appVersion} lite={true}/>
                </Layout>

                <Modal
                    open={this.state.modalConfirm}
                    title="Konfirmasi"
                    closeIcon={true}
                    footer={[
                        <Button key="1" type="primary" loading={this.state.removeProcessing} onClick={this.okModalConfirm}>
                            Hapus Bookmark
                        </Button>,
                        <Button key="close" onClick={() => {
                            this.setState({modalConfirm: false})
                        }}>Cancel</Button>
                    ]}
                >
                    <Form layout={"vertical"}>
                        Anda Yakin akan menghapus bookmark {this.state.dataToRemove?.name}?
                    </Form>
                </Modal>

            </React.Fragment>
        );
    }
}

export default withAuth(MyBookmark);
