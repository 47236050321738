export default function reformatPhoneNumber(phoneNumber: any) {
    if (phoneNumber) {
        const prefix = phoneNumber.substring(0, 2);
        if (prefix === "08") {
            return phoneNumber.replace(/^.{2}/g, '628');
        } else {
            return phoneNumber;
        }
    } else {
        return "";
    }
}
