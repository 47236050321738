import React, {Component} from 'react';
import {withRouter} from "../../hoc/withRouter";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Image from "antd/lib/image";
import FooterSection from "../../components/footer/FooterSection";
import AppLayout from "../../components/layouts/AppLayout";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import LiveSubscription from "../../components/bookmarkAndSubsciption/LiveSubscription";
import Spin from "antd/lib/spin";
import Badge from "antd/lib/badge";
import {liveBqLogService} from "../../services/track/LiveBqLogService";
import {Divider} from "antd";

class Live extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            isDesktop: false,
            areaCode: "bandung",
            listDealInLiveFinal: [],
            listNextLive: [],
            fetchLiveProcessing: true,
            processClick: false,
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    onAreaChange = async (data: any) => {
        await this.promisedSetState({
            areaCode: data.city_group,
        });
    }

    async onClickLive(data: any) {
        if (!this.state.processClick) {
            await this.promisedSetState({
                processClick: true,
            });

            await liveBqLogService.sendTrackLog({
                sourceUrl: window.location.href,
                dealCode: this.state.pricelistCode,
                offerCode: "",
                category: "click_button",
                name: "live button",
                parameter: data.deal_code,
            });

            window.location.href = `https://deal.amartahonda.com/${data?.deal_code}?utm_source=amartahonda&utm_medium=live&utm_campaign=${data?.live_code}`;
        }
    }

    formatDate(dateStr: any, timeStr: any) {
        const daysOfWeek = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
        const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

        const date = new Date(`${dateStr}T${timeStr}`);

        const dayOfWeek = daysOfWeek[date.getDay()];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours();

        return `${dayOfWeek} ${day} ${month} ${year} Jam ${hours}`;
    };

    async componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);

        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();

        const listDeal = [];
        const listNextLive = [];
        try {
            const listDealInLive = await trimitraCatalogServices.getLiveList();
            const tempData = listDealInLive?.data?.data ?? [];
            const tempDat2 = listDealInLive?.data?.meta?.next_live ?? [];
            for (const item of tempData) {
                listDeal.push(item);
            }
            for (const item of tempDat2) {
                listNextLive.push(item);
            }
        } catch (e) {
            console.log(e);
        }

        await this.promisedSetState({
            listDealInLiveFinal: listDeal,
            listNextLive: listNextLive,
            fetchLiveProcessing: false
        });

        await liveBqLogService.initTrackLog({
            sourceUrl: window.location.href,
            dealCode: this.state.pricelistCode,
            offerCode: "",
            category: "landing",
            name: "landing",
            parameter: null,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout hideAreaCode={true} areaCode={this.state.areaCode} onAreaChangeProps={this.onAreaChange} isDesktop={this.state.isDesktop}>
                    <Row>
                        <Col xl={{span: 24}} lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <div style={{minHeight: '79vh', backgroundColor: "#fff", padding: "1vh", paddingTop: 30}}>

                                <div style={this.state.fetchLiveProcessing ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                                </div>

                                <div style={!this.state.fetchLiveProcessing ? {} : {display: 'none'}}>
                                    <div style={this.state.listDealInLiveFinal.length > 0 ? {} : {display: 'none'}}>
                                        <Row className={`home-row-chosen-product`}>
                                            {this.state.listDealInLiveFinal.map((item: any, i: number) => {
                                                return (
                                                    <Col onClick={() => this.onClickLive(item)} key={i} xxl={{span: 8}} xl={{span: 8}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} style={{padding: 5}}>
                                                        {/*<a target={"_blank"} rel="noreferrer" href={"https://deal.amartahonda.com/" + item?.deal_code + `?utm_source=amartahonda&utm_medium=live&utm_campaign=${item?.live_code}`}>*/}
                                                        <div className={`card-product boxHover`}>
                                                            <Badge.Ribbon style={{zIndex: 300, fontSize: '110%'}} text={`${item?.area[0].replace(/\b\w/g, (s: string) => s.toUpperCase())}`} color={"#64b48f"}/>
                                                            <div className={`product-img-center`}>
                                                                <Image className={`img-product-card`} preview={false} alt={`Loading Image...`} width={'100%'} src={item?.url_image} fallback={'https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/vehicle-not-found.png'}/>
                                                            </div>
                                                            <div className={`p-chosen-name`} style={{fontSize: "120%", color: "#525252", paddingTop: 5}}>
                                                                <b>{(this.state.isDesktop) ? item?.deal_code : item?.deal_code?.slice(0, 10) + "..."}</b>
                                                            </div>
                                                            <div style={{fontSize: "90%", color: "grey", paddingLeft: 10, paddingRight: 10, paddingTop: 7, minHeight: 10}}>
                                                                {item?.caption?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}
                                                            </div>
                                                        </div>
                                                        {/*</a>*/}
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </div>
                                    <div style={this.state.listDealInLiveFinal.length === 0 ? {} : {display: 'none'}}>
                                        <Row>
                                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                <div className={`product-img-center`} style={{paddingTop: 60}}>
                                                    <Image preview={false} alt={`Loading Image...`} width={'200px'} src={`https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/asset/no-live.png`}/>
                                                </div>
                                                <div style={{textAlign: "center", paddingTop: 30}}>
                                                    <h2><b>NO LIVE SCHEDULE</b></h2>
                                                    <h3 style={{color: "gray"}}>Saat ini live sedang tidak berlangsung</h3><br/>
                                                    <LiveSubscription
                                                        bookmarkCode={"VEHICLE_VARIANT#" + this.state.variantCode}
                                                        bookmarkUrl={window.location.href}
                                                        bookmarkTitle={this.state.chosenNewVariantColor?.variant_name}
                                                        bookmarkArea={this.state.areaCode}
                                                        bookmarkType={"VEHICLE_VARIANT"}
                                                        bookmarkProductCode={this.state.variantCode}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                <Divider style={{marginTop: 7, marginBottom: 7}} plain>Live Akan Datang</Divider>
                                                <Row>
                                                    <Col span={12}>
                                                        {this.state.listNextLive.map((item: any, i: number) => {
                                                            const xx = {
                                                                start_period: '2024-08-08T00:00:00',
                                                                start_time: '14:00'
                                                            };
                                                            const dateFormatted = this.formatDate(xx.start_period.substring(0, 10), xx.start_time)

                                                            return (
                                                                <div className={`card-product boxHover`} key={i}>
                                                                    <div className={`product-img-center`}>
                                                                        <Image className={`img-product-card`} preview={false} alt={`Loading Image...`} width={'100%'} src={item?.url_image} fallback={'https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/vehicle-not-found.png'}/>
                                                                    </div>
                                                                    <div className={`p-chosen-name`} style={{fontSize: "120%", color: "#525252", paddingTop: 5}}>
                                                                        <b>{item?.live_caption?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</b>
                                                                    </div>
                                                                    <div style={{fontSize: "90%", color: "grey", paddingLeft: 10, paddingRight: 10, paddingTop: 2, minHeight: 10}}>
                                                                        {dateFormatted}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </AppLayout>
                <FooterSection areaCode={this.state.areaCode}/>
            </React.Fragment>
        );
    }
}

export default withRouter(Live);