import React, {Component} from "react";
import Select from "antd/lib/select";

class CustomerPlaceToStay extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            customerPlaceToStayStatus: null,
        }
        this.state = {...this.initState}
    }

    onChange = (value: any, option: any) => {
        this.setState({
            customerPlaceToStayStatus: value,
        });
        this.props.onCustomerPlaceToStayChangeProps(option);
    }

    render() {
        return (
            <Select
                placeholder="Status Tempat Tinggal"
                showSearch
                style={{width: "100%"}}
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.props.value ?? this.state.customerPlaceToStayStatus}
            >
                <Select.Option value="000001">milik sendiri</Select.Option>
                <Select.Option value="000002">milik keluarga</Select.Option>
                <Select.Option value="000003">kontrak</Select.Option>
                <Select.Option value="000004">kost</Select.Option>
                <Select.Option value="000005">rumah dinas</Select.Option>
                <Select.Option value="000006">rumah status kredit(kpr)</Select.Option>
            </Select>
        );
    }
}

export default CustomerPlaceToStay;
