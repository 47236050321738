import axios from "axios";
import {baseUrlAmartaVip, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";

class BookmarkApiService {
    private axios = axios.create({
        baseURL: baseUrlAmartaVip,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async createBookmark(params: any) {
        try {
            return await this.axios.post<any>('/bookmark', params, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async removeBookmark(params: any) {
        try {
            return await this.axios.delete<any>('bookmark', {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                    "Content-Type": "application/json"
                },
                data: params
            });
        } catch (e: any) {
            console.log(e.response.data.error.message);
            throw new Error(e.response.data.error.message);
        }
    }

    public async getUserBookmark() {
        try {
            return await this.axios.get<any>(`/bookmark/amartahonda`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const bookmarkApiService = new BookmarkApiService();
