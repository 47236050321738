import React, {Component} from "react";
import {SpkStates} from "./types/SpkTypes";
import SpkLayout from "../../components/layouts/SpkLayout";
import {SpkPaymentHistory} from "../../components/offer/SpkPaymentHistory";
import {SpkOutStanding} from "../../components/offer/SpkOutStanding";
import {PaymentInstruction} from "../../components/offer/PaymentInstruction";
import {withRouter} from "../../hoc/withRouter";
import {offerApiServices} from "../../services/offer/OfferApiService";
import currencyFormat from "../../helper/currencyFormat";
import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Empty from "antd/lib/empty";
import Image from "antd/lib/image";
import List from "antd/lib/list";
import message from "antd/lib/message";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import Typography from "antd/lib/typography";
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import {trackLogServices} from "../../services/track/TrackLogService";
import Tooltip from "antd/lib/tooltip";
import CopyOutlined from "@ant-design/icons/CopyOutlined";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";

class Spk extends Component<any, SpkStates> {
    private readonly initState!: SpkStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const offerCode = match.params.offercode;

        document.title = `Amartahonda | SPK | ${offerCode}`;

        this.initState = {
            offerCode: offerCode,
            dataOffer: {},
            fetchOffer: true,
            paymentMethodProcess: false,
            modalConfirmPaymentMethod: false,
            confirmPaymentMethod: false,
            chosenPaymentType: "",
            chosenPaymentMethod: "",
            previewPaymentInstructionModalInfo: false,
            previewPaymentInstructionVaNumber: "",
            previewPaymentInstructionVaPaymentType: "",
            previewPaymentInstructionVaPaymentMethod: "",
            previewPaymentHistory: false,
            previewOutStanding: false,
            fetchPaymentHistory: false,
            dataPaymentHistory: [],

            masterPaymentMethod: [],
            fetchMasterPaymentMethod: false,
        }
        this.state = {...this.initState}
    }

    fetchDetailOffer = () => {
        this.setState({
            fetchOffer: true,
        });
        offerApiServices.getDetailOffer({offerCode: this.state.offerCode})
            .then(async dataFetchPromo => {
                await this.promisedSetState({
                    fetchOffer: false,
                    dataOffer: dataFetchPromo.data.data,
                })

                if ((dataFetchPromo?.data?.data?.purchase_method === "cash") && !dataFetchPromo?.data?.data?.price_and_bill?.bill_code) {
                    this.onClickPayment('settlement')
                }
            }).catch(e => {
            this.setState({
                fetchOffer: false,
                dataOffer: null,
            })
        });
    }

    fetchMasterPayment = async () => {
        await this.promisedSetState({
            masterPaymentMethod: [],
            fetchMasterPaymentMethod: true,
        });
        try {
            const listDealInLive = await trimitraCatalogServices.getMasterPaymentConfig();
            this.setState({
                masterPaymentMethod: listDealInLive?.data?.data ?? [],
                fetchMasterPaymentMethod: false,
            });
        } catch (e) {
            console.log(e);
            this.setState({
                masterPaymentMethod: [],
                fetchMasterPaymentMethod: false,
            });
        }
    }

    async componentDidMount() {
        await this.fetchDetailOffer();
        await this.fetchMasterPayment();

        trackLogServices.sendTrackLog({
            eventName: `spk page`,
            eventSourceUrl: window.location.href,
            customData: null
        });
    }

    copyToClipboard = async (data: any) => {
        await navigator.clipboard.writeText(data);
        message.success('Nomor SPK berhasil dicopy');
    }

    onClickPayment = (type: string) => {
        this.setState({
            paymentMethodProcess: true,
            chosenPaymentType: type,
        });
    }

    onBackLayout = (data: any) => {
        this.setState({
            paymentMethodProcess: false,
            chosenPaymentType: "",
        });
    }

    onClickPaymentMethod = (chosenPaymentMethod: string) => {
        this.setState({
            chosenPaymentMethod: chosenPaymentMethod,
            modalConfirmPaymentMethod: true,
            confirmPaymentMethod: false,
        });
    }

    closeConfirmPaymentMethod = () => {
        this.setState({
            modalConfirmPaymentMethod: false,
        });
    }

    handleConfirm = () => {
        this.setState({
            confirmPaymentMethod: true,
        });

        const input: any = {
            company: "amarta",
            transaction_code: this.state.offerCode,
            payment_type: this.state.chosenPaymentType,
            payment_method: this.state.chosenPaymentMethod
        }

        offerApiServices.createInvoice(input)
            .then(invoiceResp => {
                const respInv = invoiceResp.data.data;
                if (respInv.channel === 'xendit') {
                    window.location.href = respInv.url;
                } else {
                    this.previewPaymentInstruction(respInv.transaction_code, this.state.chosenPaymentType, this.state.chosenPaymentMethod);
                }
            }).catch(e => {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            this.setState({
                confirmPaymentMethod: false,
            });
        });
    }

    closePaymentInstruction = () => {
        this.setState({
            previewPaymentInstructionModalInfo: false,
            previewPaymentInstructionVaNumber: "",
            previewPaymentInstructionVaPaymentType: "",
            previewPaymentInstructionVaPaymentMethod: "",
            paymentMethodProcess: false,
            chosenPaymentType: "",
            chosenPaymentMethod: ""
        });
        this.fetchDetailOffer();
    }

    previewPaymentInstruction = (paymentVaNumber: string, paymentType: string, paymentMethod: string) => {
        this.setState({
            previewPaymentInstructionVaPaymentType: paymentType,
            previewPaymentInstructionVaNumber: paymentVaNumber,
            previewPaymentInstructionVaPaymentMethod: paymentMethod,
            modalConfirmPaymentMethod: false,
            confirmPaymentMethod: false,
            previewPaymentInstructionModalInfo: true,
        });
    }

    onClickOutStanding = () => {
        this.fetchPaymentHistoryOffer();
        this.setState({
            previewOutStanding: true,
        });
    }

    onClickHistoryPayment = () => {
        this.fetchPaymentHistoryOffer();
        this.setState({
            previewPaymentHistory: true,
        });
    }

    fetchPaymentHistoryOffer = () => {
        this.setState({
            fetchPaymentHistory: true,
        })

        offerApiServices.getPaymentHistoryOffer({
                offerCode: this.state.offerCode
            }
        ).then(dataFetchPaymentHistory => {
            this.setState({
                fetchPaymentHistory: false,
                dataPaymentHistory: dataFetchPaymentHistory.data.data,
            });
        }).catch(e => {
            this.setState({
                fetchPaymentHistory: false,
                dataPaymentHistory: [],
            });
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        const paymentMethodDeposit = this.state.masterPaymentMethod.find((o: { code: string; }) => o.code === this.state.dataOffer?.payment?.deposit_method?.toUpperCase());
        const paymentMethodSettlement = this.state.masterPaymentMethod.find((o: { code: string; }) => o.code === this.state.dataOffer?.payment?.settlement_method?.toUpperCase());
        const ownerAddress = this.state.dataOffer?.address_owner?.full_address?.replace(new RegExp(`.{${Math.ceil(0.6 * this.state.dataOffer?.address_owner?.full_address?.length)}}$`), '*'?.repeat(Math.ceil(0.6 * this.state.dataOffer?.address_owner?.full_address?.length))) ?? "";
        const shippingAddress = this.state.dataOffer?.address_shipping?.full_address?.replace(new RegExp(`.{${Math.ceil(0.6 * this.state.dataOffer?.address_shipping?.full_address?.length)}}$`), '*'?.repeat(Math.ceil(0.6 * this.state.dataOffer?.address_shipping?.full_address?.length))) ?? "";
        return (
            <SpkLayout areaCode={this.state.dataOffer?.area} onClickBack={this.onBackLayout} backToHome={this.state.chosenPaymentType} headerTitle={`SPK ${this.state.offerCode}`}>
                <div style={(!this.state.paymentMethodProcess) ? {minHeight: '80vh'} : {display: 'none'}}>
                    <div className="spin-loading" style={(this.state.fetchOffer) ? {} : {display: 'none'}}>
                        <Spin size={"large"} tip="Loading..."/>
                    </div>
                    <div style={(!this.state.fetchOffer && this.state.dataOffer === null) ? {paddingTop: 20} : {display: 'none'}}>
                        <Empty description={`Kode SPK Tidak Ditemukan, pastikan Kode SPK benar`}/>
                    </div>
                    <div style={(!this.state.fetchOffer && this.state.dataOffer !== null) ? {paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20} : {display: 'none'}}>
                        <div style={((this.state.dataOffer?.purchase_method === "credit") && (!this.state.dataOffer?.price_and_bill?.bill_code)) ? {} : {display: 'none'}}>
                            <Card size={'small'}>
                                SPK anda dalam proses konfirmasi admin.
                            </Card>
                        </div>
                        <div style={(this.state.dataOffer?.price_and_bill?.bill_code || this.state.dataOffer?.purchase_method === "cash") ? {} : {display: 'none'}}>
                            <Row>
                                <Col xxl={{span: 16}} xl={{span: 16}} lg={{span: 16}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <div className={`detail-product-title`}>Surat Pesan Kendaraan</div>
                                    <div className={`detail-product-caption pt-10 pb-20`}><i>*Simpan nomor SPK anda untuk memudahkan pencarian.</i></div>
                                    <i className={`detail-product-caption`}>Nomor SPK:</i><br/>
                                    <span style={{fontSize: "120%", fontWeight: 500}}>{this.state.offerCode}</span>
                                    <Tooltip title="Click untuk copy nomor SPK" color={'cyan'}><CopyOutlined style={{color: "#259261", paddingLeft: 7}} className={'small-avatar-link'} onClick={event => this.copyToClipboard(this.state.offerCode)}/></Tooltip>

                                    <div style={this.state.dataOffer?.cancel_order_time ? {paddingTop: 20} : {display: 'none'}}>
                                        <b style={{color: 'red'}}>SPK Telah Dibatalkan, silakan hubungi admin untuk info lebih lanjut</b><br/><br/>
                                    </div>

                                    <div style={{fontSize: "120%", fontWeight: 480, paddingTop: 40, paddingBottom: 10}}>Kendaraan</div>
                                    <Typography.Text type="secondary" style={{paddingRight: 86}}> Merk: </Typography.Text> Honda <br/>
                                    <Typography.Text type="secondary" style={{paddingRight: 79}}> Model: </Typography.Text> {this.state.dataOffer?.vehicle?.model_name.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())} <br/>
                                    <Typography.Text type="secondary" style={{paddingRight: 76}}> Variant: </Typography.Text> {this.state.dataOffer?.vehicle?.variant_name.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())} <br/>
                                    <Typography.Text type="secondary" style={{paddingRight: 40}}> Kode Variant: </Typography.Text> {this.state.dataOffer?.vehicle?.variant_code} <br/>
                                    <Typography.Text type="secondary" style={{paddingRight: 78}}> Warna: </Typography.Text> {this.state.dataOffer?.vehicle?.color_code} ({this.state.dataOffer?.vehicle?.color_name}) <br/>

                                    <div style={{fontSize: "120%", fontWeight: 480, paddingTop: 40, paddingBottom: 10}}>Kepemilikan</div>
                                    <Typography.Text type="secondary" style={{paddingRight: 82}}> Nama: </Typography.Text> {this.state.dataOffer?.id_card_owner?.full_name} <br/>
                                    <Typography.Text type="secondary" style={{paddingRight: 30}}> Nomor Telpon: </Typography.Text> {this.state.dataOffer?.contact?.phone_number_owner?.replace(/(.{3}).{5}(.{3})/, '$1******$2')} <br/>
                                    <Typography.Text type="secondary" style={{paddingRight: 76}}> Alamat: </Typography.Text> {ownerAddress} <br/>
                                    <Typography.Text type="secondary" style={{paddingRight: 118}}> &nbsp; </Typography.Text> {this.state.dataOffer?.address_owner?.city_name?.toUpperCase()} - {this.state.dataOffer?.address_owner?.district_name?.toUpperCase()}, {this.state.dataOffer?.address_owner?.province_name?.toUpperCase()} {this.state.dataOffer?.address_owner?.zip_code} <br/>

                                    <div style={{fontSize: "120%", fontWeight: 480, paddingTop: 40, paddingBottom: 10}}>Alamat Pengiriman</div>

                                    <Row style={(!this.state.dataOffer?.take_vehicle_in_dealer) ? {} : {display: 'none'}}>
                                        <Col span={24}>
                                            {(shippingAddress) ? shippingAddress : ownerAddress}<br/>
                                            {(this.state.dataOffer?.address_shipping?.city_name?.toUpperCase()) ? this.state.dataOffer?.address_shipping?.city_name?.toUpperCase() : this.state.dataOffer?.address_owner?.city_name?.toUpperCase()} -
                                            {(this.state.dataOffer?.address_shipping?.district_name?.toUpperCase()) ? this.state.dataOffer?.address_shipping?.district_name?.toUpperCase() : this.state.dataOffer?.address_owner?.district_name?.toUpperCase()}, &nbsp;
                                            {(this.state.dataOffer?.address_shipping?.province_name?.toUpperCase()) ? this.state.dataOffer?.address_shipping?.province_name?.toUpperCase() : this.state.dataOffer?.address_owner?.province_name?.toUpperCase()} &nbsp;
                                            {(this.state.dataOffer?.address_shipping?.zip_code) ? this.state.dataOffer?.address_shipping?.zip_code : this.state.dataOffer?.address_owner?.zip_code}
                                        </Col>
                                    </Row>
                                    <Row style={this.state.dataOffer?.take_vehicle_in_dealer ? {} : {display: 'none'}}>
                                        <Col span={24}>
                                            Diambil di delaer Amartahonda area {this.state.dataOffer?.area?.toUpperCase()}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xxl={{span: 8}} xl={{span: 8}} lg={{span: 8}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <div style={{padding: 10, backgroundColor: "#f5f5f5", marginTop: 40}}>
                                        <div style={{fontSize: "120%", fontWeight: 480, paddingBottom: 10}}>Resume Tagihan</div>
                                        <Typography.Text type="secondary" style={{paddingRight: 64}}> Metode: </Typography.Text> {(this.state.dataOffer?.purchase_method === "cash") ? "Tunai" : "Kredit"} <br/>
                                        <span style={this.state.dataOffer?.purchase_method === 'credit' ? {} : {display: 'none'}}><Typography.Text type="secondary" style={{paddingRight: 64}}> Leasing: </Typography.Text> {this.state.dataOffer?.credit?.finco_name?.toUpperCase()} <br/></span>
                                        <Typography.Text type="secondary" style={{paddingRight: 85}}> OTR: </Typography.Text> {currencyFormat(this.state.dataOffer?.price_and_bill?.otr)} <br/>
                                        <Typography.Text type="secondary" style={{paddingRight: 69}}> Diskon: </Typography.Text> {(this.state.dataOffer?.purchase_method === "cash") ? currencyFormat(this.state.dataOffer?.price_and_bill?.promo_discount) : "****"} <br/>
                                        <Divider style={{marginTop: 7, marginBottom: 7}} plain></Divider>
                                        <Typography.Text type="secondary" style={{paddingRight: 40}}> Biaya Order: </Typography.Text> {currencyFormat(this.state.dataOffer?.price_and_bill?.order_fee)} <br/>
                                        <div style={(this.state.dataOffer?.price_and_bill?.payment_fee) ? {} : {display: 'none'}}>
                                            <Typography.Text type="secondary" style={{paddingRight: 0}}> Biaya Pembayaran: </Typography.Text> {currencyFormat(this.state.dataOffer?.price_and_bill?.payment_fee)} <br/>
                                        </div>
                                        <Divider style={{marginTop: 7, marginBottom: 7}} plain></Divider>
                                        <Typography.Text type="secondary" style={{paddingRight: 14}}> <b>Total Tagihan <span style={this.state.dataOffer?.purchase_method === 'credit' ? {} : {display: 'none'}}> DP </span>:</b> </Typography.Text> {currencyFormat(this.state.dataOffer?.price_and_bill?.total_bill)} <br/>
                                    </div>

                                    <div style={(this.state.dataOffer?.price_and_bill?.total_bill > 0) ? {paddingTop: 20} : {display: 'none'}}>
                                        <Divider orientation="left">Pembayaran</Divider>

                                        <div style={this.state.dataOffer?.purchase_method === 'credit' ? {} : {display: 'none'}}>
                                            <Card size={'small'} style={(this.state.dataOffer?.price_and_bill?.deposit) ? {} : {display: 'none'}}>
                                                <Row>
                                                    <Col span={12}><b>Tanda Jadi</b></Col>
                                                    <Col span={12} style={{textAlign: "right"}}><b>{currencyFormat(this.state.dataOffer?.price_and_bill?.deposit_amount)}</b></Col>
                                                </Row>
                                                <div style={(this.state.dataOffer?.payment?.deposit_method === '') ? {paddingTop: 20} : {display: 'none'}}>
                                                    <Button style={{borderRadius: 12}} size={"middle"} onClick={() => this.onClickPayment('deposit')} type="primary">Pilih Pembayaran</Button>
                                                </div>
                                                <div style={(this.state.dataOffer?.payment?.deposit_method !== '') ? {paddingTop: 20} : {display: 'none'}}>
                                                    <Typography.Text type="secondary">Metode Pembayaran:</Typography.Text> {paymentMethodDeposit?.name}
                                                    <div style={(this.state.dataOffer?.payment?.deposit_channel === 'xendit') ? {} : {display: 'none'}}>
                                                        <Typography.Link href={this.state.dataOffer?.payment?.deposit_note}> Detail Pembayaran </Typography.Link>
                                                    </div>
                                                    <div style={(this.state.dataOffer?.payment?.deposit_channel === 'fin-amartahonda') ? {} : {display: 'none'}}>
                                                        <span style={{color: "#6290ff", cursor: "pointer"}} onClick={() => this.previewPaymentInstruction(this.state.dataOffer?.payment?.deposit_transaction_code, "deposit", this.state.dataOffer?.payment?.deposit_method)}> Detail Pembayaran </span>
                                                    </div>
                                                </div>
                                            </Card><br/>
                                            <Card size={'small'}>
                                                <Row>
                                                    <Col span={12}><b>Pelunasan <span style={this.state.dataOffer?.purchase_method === 'credit' ? {} : {display: 'none'}}> DP </span></b></Col>
                                                    <Col span={12} style={{textAlign: "right"}}><b>{currencyFormat((this.state.dataOffer?.price_and_bill?.total_bill) - (this.state.dataOffer?.price_and_bill?.deposit_amount))}</b></Col>
                                                </Row>
                                                <div style={(this.state.dataOffer?.payment?.settlement_method === '') ? {paddingTop: 20} : {display: 'none'}}>
                                                    <Button style={{borderRadius: 12}} size={"middle"} onClick={() => this.onClickPayment('settlement')} type="primary">Pilih Pembayaran</Button>
                                                </div>
                                                <div style={(this.state.dataOffer?.payment?.settlement_method !== '') ? {paddingTop: 20} : {display: 'none'}}>
                                                    <Typography.Text type="secondary">Metode Pembayaran:</Typography.Text> {paymentMethodSettlement?.name}
                                                    <div style={(this.state.dataOffer?.payment?.settlement_channel === 'xendit') ? {} : {display: 'none'}}>
                                                        <Typography.Link href={this.state.dataOffer?.payment?.settlement_note}> Detail Pembayaran </Typography.Link>
                                                    </div>
                                                    <div style={(this.state.dataOffer?.payment?.settlement_channel === 'fin-amartahonda' || this.state.dataOffer?.payment?.settlement_channel === 'cod') ? {} : {display: 'none'}}>
                                                        <span style={{color: "#6290ff", cursor: "pointer"}} onClick={() => this.previewPaymentInstruction(this.state.dataOffer?.payment?.settlement_transaction_code, "settlement", this.state.dataOffer?.payment?.settlement_method)}> Detail Pembayaran </span>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>

                                        <div style={this.state.dataOffer?.purchase_method === 'cash' ? {} : {display: 'none'}}>
                                            <Card size={'small'}>
                                                <Row>
                                                    <Col span={12}><b>Pelunasan Tunai</b></Col>
                                                    <Col span={12} style={{textAlign: "right"}}><b>{currencyFormat(this.state.dataOffer?.price_and_bill?.total_bill)}</b></Col>
                                                </Row>
                                                <div style={(this.state.dataOffer?.payment?.settlement_method === '') ? {paddingTop: 20} : {display: 'none'}}>
                                                    <Button style={{borderRadius: 12}} size={"middle"} onClick={() => this.onClickPayment('settlement')} type="primary">Pilih Pembayaran</Button>
                                                </div>
                                                <div style={(this.state.dataOffer?.payment?.settlement_method !== '') ? {paddingTop: 20} : {display: 'none'}}>
                                                    <Typography.Text type="secondary">Metode Pembayaran:</Typography.Text> {paymentMethodSettlement?.name}
                                                    <div style={(this.state.dataOffer?.payment?.settlement_channel === 'xendit') ? {} : {display: 'none'}}>
                                                        <Typography.Link href={this.state.dataOffer?.payment?.settlement_note}> Detail Pembayaran </Typography.Link>
                                                    </div>
                                                    <div style={(this.state.dataOffer?.payment?.settlement_channel === 'fin-amartahonda' || this.state.dataOffer?.payment?.settlement_channel === 'cod') ? {} : {display: 'none'}}>
                                                        <span style={{color: "#6290ff", cursor: "pointer"}} onClick={() => this.previewPaymentInstruction(this.state.dataOffer?.payment?.settlement_transaction_code, "settlement", this.state.dataOffer?.payment?.settlement_method)}> Detail Pembayaran </span>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>

                                        <br/><br/>
                                        <Row>
                                            <Col span={12} style={{padding: '5px'}}>
                                                <Divider orientation="left" style={{fontSize: "95%", color: "#8d8d8d"}}>Riwayat Pembayaran</Divider>
                                                <Button size={"small"} type="primary" style={{fontSize: "95%", borderRadius: 12}} icon={<ProfileOutlined/>} onClick={() => this.onClickHistoryPayment()}>Lihat Pembayaran</Button>
                                            </Col>
                                            <Col span={12} style={{padding: '5px'}}>
                                                <Divider orientation="left" style={{fontSize: "95%", color: "#8d8d8d"}}>Sisa Tagihan</Divider>
                                                <Button size={"small"} type="primary" style={{fontSize: "95%", borderRadius: 12}} icon={<ProfileOutlined/>} onClick={() => this.onClickOutStanding()}>Lihat Sisa Tagihan</Button>
                                            </Col>
                                        </Row>
                                    </div>


                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                <div style={(this.state.paymentMethodProcess) ? {minHeight: '90vh', padding: 20} : {display: 'none'}}>
                    <List style={(this.state.chosenPaymentType === 'deposit') ? {} : {display: 'none'}}>
                        {this.state.masterPaymentMethod.map((item: any, i: number) =>
                            <List.Item key={i} onClick={() => this.onClickPaymentMethod(item.code.toLowerCase())} style={((item.type === "ALL" || item.type === "DEPOSIT") && (item.purchase_method === "ALL" || item.purchase_method === this.state.dataOffer?.purchase_method?.toUpperCase())) ? {cursor: "pointer"} : {display: 'none'}}>
                                <List.Item.Meta
                                    avatar={<Image preview={false} style={{width: 70, marginTop: 5}} src={item.logo}/>}
                                    title={<b>{item.name}</b>}
                                    description={`Biaya Tambahan:  ${(this.state.dataOffer?.purchase_method === "cash") ? (item.fee_type === "AMOUNT") ? currencyFormat(item.fee) : item.fee + " %" : currencyFormat(0)}`}
                                />
                            </List.Item>
                        )}
                    </List>
                    <List style={(this.state.chosenPaymentType === 'settlement') ? {} : {display: 'none'}}>
                        {this.state.masterPaymentMethod.map((item: any, i: number) =>
                            <List.Item key={i} onClick={() => this.onClickPaymentMethod(item.code.toLowerCase())} style={((item.type === "ALL" || item.type === "SETTLEMENT") && (item.purchase_method === "ALL" || item.purchase_method === this.state.dataOffer?.purchase_method?.toUpperCase())) ? {cursor: "pointer"} : {display: 'none'}}>
                                <List.Item.Meta
                                    avatar={<Image preview={false} style={{width: 70, marginTop: 5}} src={item.logo}/>}
                                    title={<b>{item.name}</b>}
                                    description={`Biaya Tambahan:  ${(this.state.dataOffer?.purchase_method === "cash") ? (item.fee_type === "AMOUNT") ? currencyFormat(item.fee) : item.fee + " %" : currencyFormat(0)}`}
                                />
                            </List.Item>
                        )}
                    </List>
                </div>

                <Modal
                    open={this.state.modalConfirmPaymentMethod}
                    title="Konfirmasi"
                    onCancel={this.closeConfirmPaymentMethod}
                    maskClosable={false}
                    closable={false}
                    footer={[
                        <Button key={1} type="primary" loading={this.state.confirmPaymentMethod} onClick={this.handleConfirm}> Bayar </Button>,
                        <Button key={2} loading={this.state.confirmPaymentMethod} onClick={this.closeConfirmPaymentMethod}> Cancel </Button>,
                    ]}
                >
                    <p>{'Anda yakin akan melakukan pembayaran melalui ' + this.state.chosenPaymentMethod + ' ?'}</p>
                </Modal>

                <Modal
                    open={this.state.previewPaymentInstructionModalInfo}
                    title="Instruksi Pembayaran"
                    onCancel={this.closePaymentInstruction}
                    maskClosable={false}
                    closable={false}
                    footer={[
                        <Button loading={this.state.confirmPaymentMethod} onClick={this.closePaymentInstruction}> OK </Button>,
                    ]}
                >
                    <PaymentInstruction
                        payemntType={this.state.previewPaymentInstructionVaPaymentType}
                        paymentMethod={this.state.previewPaymentInstructionVaPaymentMethod}
                        virtualAccountNumber={this.state.previewPaymentInstructionVaNumber}
                        totalInvoice={this.state.previewPaymentInstructionVaPaymentType === 'deposit'
                            ? currencyFormat(this.state.dataOffer?.price_and_bill?.deposit_amount)
                            : currencyFormat((this.state.dataOffer?.purchase_method === 'cash') ? this.state.dataOffer?.price_and_bill?.total_bill : ((this.state.dataOffer?.price_and_bill?.total_bill) - (this.state.dataOffer?.price_and_bill?.deposit_amount)))}
                        invoiceMethodLogo={`https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/asset/logo/payment/bca.jpg`}
                    />
                </Modal>

                <Modal
                    open={this.state.previewPaymentHistory}
                    title={`Riwayat Pembayaran`}
                    onCancel={() => {
                        this.setState({previewPaymentHistory: false})
                    }}
                    footer={[
                        <Button loading={this.state.confirmPaymentMethod} onClick={() => {
                            this.setState({previewPaymentHistory: false})
                        }}> OK </Button>,
                    ]}
                >
                    <SpkPaymentHistory fetchPaymentHistory={this.state.fetchPaymentHistory} dataPaymentHistory={this.state.dataPaymentHistory}/>
                </Modal>

                <Modal
                    open={this.state.previewOutStanding}
                    title={`Sisa Pembayaran`}
                    onCancel={() => {
                        this.setState({previewOutStanding: false})
                    }}
                    footer={[
                        <Button loading={this.state.confirmPaymentMethod} onClick={() => {
                            this.setState({previewOutStanding: false})
                        }}> OK </Button>,
                    ]}
                >
                    <SpkOutStanding fetchPaymentHistory={this.state.fetchPaymentHistory} dataSpk={this.state.dataOffer} dataPaymentHistory={this.state.dataPaymentHistory}/>
                </Modal>
            </SpkLayout>
        );
    }
}

export default withRouter(Spk);