import axios from "axios";
import {baseUrlCrm} from "../../config/apiConfig/apiConfig";

class CrmService {
    private axios = axios.create({
        baseURL: baseUrlCrm,
    });

    public async checkPhoneNumberCrm(params?: any) {
        try {
            return await this.axios.post<any>(`/check-phone-number/${params.phoneNumber}`,
                params, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
        } catch (e) {
            throw e;
        }
    }
}

export const crmService = new CrmService();
