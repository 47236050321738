import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Button from "antd/lib/button";
import Layout from "antd/lib/layout";
import Col from "antd/lib/col";
import ShoppingOutlined from '@ant-design/icons/ShoppingOutlined';
import "./Home.css";
import {trackLogServices} from "../../services/track/TrackLogService";

class NotFound extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            areaCode: ""
        }
        this.state = {...this.initState}
    }

    componentDidMount() {
        trackLogServices.sendTrackLog({
            eventName: `not found page`,
            eventSourceUrl: window.location.href,
            customData: null
        });
    }

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}}>
                        <div style={{marginTop: 150, textAlign: "center"}}>
                            <img src={'/assets/img/notfound.png'} alt={`amartahonda-logo`}/>
                            <br/><br/><br/><br/>
                            <Link to={`/`}>
                                <Button type={`primary`} size={`large`} icon={<ShoppingOutlined/>} style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}}>Kembali Ke Halaman Utama</Button>
                            </Link>
                        </div>
                    </Col>
                </Layout>
            </React.Fragment>
        );
    }
}

export default NotFound;
