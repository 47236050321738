import React, {Component} from 'react';
import {withRouter} from "../../hoc/withRouter";
import Layout from "antd/lib/layout";
import Col from "antd/lib/col";
import "../home/Home.css";
import Spin from "antd/lib/spin";

class WaAgent extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const agentCode = match.params.agentCode;
        this.initState = {
            agentCode: agentCode
        }
        this.state = {...this.initState}

        const url = 'https://wa.me/628112341361/?lang=id&text=' + window.location.href;
        window.open(url,"_self");
    }

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}}>
                        <div style={{marginTop: 150, textAlign: "center"}}>
                            {this.state.agentCode}
                            <br/><br/><br/><br/>
                            <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                        </div>
                    </Col>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withRouter(WaAgent);
