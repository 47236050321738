import React, {Component} from "react";
import {momotorVehicleService} from "../../services/momotor/MomotorVehicleService";
import {Select} from "antd";

class MomotorModelSelect extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            brandCode: "",
            dataVehicleModel: [],
            selectVehicleModelValue: null,
        }
        this.state = {...this.initState}
    }

    fetchVehicleModelData = (brandCode: any) => {
        if (brandCode) {
            // FETCH API
            try {
                momotorVehicleService.getVehicleModel({brand: brandCode}).then((dataFetchModel: any) => {
                    const dataFetch = dataFetchModel.data.data;
                    dataFetch.sort((item1: { description: string; }, item2: { description: any; }) => item1.description.localeCompare(item2.description));

                    const dataForState: any = dataFetchModel.data.data.map((model: { model_code: string, description: string }) => ({
                        value: model.model_code,
                        name: model.description,
                    }));

                    this.setState({
                        brandCode: brandCode,
                        dataVehicleModel: dataForState
                    });
                });
            } catch (e) {
                this.setState({
                    brandCode: brandCode,
                    dataVehicleModel: [],
                    selectVehicleModelValue: null,
                });
            }
        } else {
            this.setState({
                brandCode: "",
                dataVehicleModel: [],
                selectVehicleModelValue: null,
            });
        }
    }

    onChange = async (value: any, option: any) => {
        this.setState({
            selectVehicleModelValue: value,
        });
        this.props.onVehicleModelChangeProps(option);
    }

    async componentDidMount() {
        await this.promisedSetState({
            brandCode: this.props.brandCode
        });
        await this.fetchVehicleModelData(this.props.brandCode);
        await this.onValueChange(this.props.value);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.brandCode !== prevProps.brandCode) {
            this.fetchVehicleModelData(this.props.brandCode);
        }
        if (this.props.value !== prevProps.value) {
            this.onValueChange(this.props.value);
        }
        if (this.props.time !== prevProps.time) {
            this.onValueChange(this.props.value);
        }
    }

    onValueChange = (value: any) => {
        this.setState({
            selectVehicleModelValue: value,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Select
                    showSearch
                    placeholder="Pilih Model Kendaraan"
                    optionFilterProp="children"
                    onChange={this.onChange}
                    style={{width: "100%"}}
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    value={this.state.selectVehicleModelValue}
                >
                    {
                        this.state.dataVehicleModel.map((item: any, i: number) =>
                            <Select.Option key={item?.value} value={item?.value?.toLowerCase()}>{item?.name}</Select.Option>
                        )
                    }
                </Select>
            </React.Fragment>
        );
    }
}

export default MomotorModelSelect;