import React, {Component} from 'react';
import whatsappLink from "../../helper/whatsappLink";
import Col from "antd/lib/col";
import Layout from "antd/lib/layout";
import LeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import {Header} from "antd/es/layout/layout";
import Row from "antd/lib/row";
import {Link} from "react-router-dom";
import EnvironmentFilled from "@ant-design/icons/EnvironmentFilled";

const {Content} = Layout;

class SpkLayout extends Component<any> {
    render() {
        let onChange = (value: any) => {
            this.props.onClickBack({
                source: value
            });
        };
        return (
            <Layout className="layout">
                <Col className={`p-10`} xxl={{span: 17, offset: 3}} xl={{span: 17, offset: 3}} lg={{span: 20, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    <div className={`spk-header`} style={(this.props.backToHome === "") ? {} : {display: 'none'}}>
                        <Header className={`desktop-mobile`}>
                            <Row>
                                <Col span={16}>
                                    <Link to={`/${this.props.areaCode}`}>
                                        <img style={{height: 22, paddingLeft: 15, marginTop: "-10px", cursor: "pointer"}} className={`header-logo`} src={'https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/header-text.png'} alt={`amartahonda-logo`}/>
                                    </Link>
                                </Col>
                                <Col span={8} style={{textAlign: "right", cursor: "pointer"}}>
                                    <span style={{fontSize: "90%", color: "#4b4b4b", fontWeight: 500, paddingRight: 7}}>Area {this.props.areaCode?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</span>
                                    <EnvironmentFilled style={{fontSize: "140%"}} className={`header-home-top`}/>
                                </Col>
                            </Row>
                        </Header>
                    </div>

                    <div className={`variant-header`} style={(this.props.backToHome === "") ? {display: 'none'} : {}}>
                        <LeftOutlined style={(this.props.backToHome === "") ? {display: 'none'} : {}} onClick={() => onChange('backToHome')} className={'hedaer2-back'}/>
                        <b className={`header2-title`} style={(this.props.backToHome === "deposit") ? {} : {display: 'none'}}>Pembayaran Tanda Jadi</b>
                        <b className={`header2-title`} style={(this.props.backToHome === "settlement") ? {} : {display: 'none'}}>Metode Pembayaran Pelunasan</b>
                    </div>
                    <Content style={{backgroundColor: "white"}}>
                        {this.props.children}
                    </Content>
                    <footer style={{backgroundColor: '#fff', paddingBottom: 20, paddingTop: 10, textAlign: "center"}}>
                        Butuh Bantuan? <span onClick={() => whatsappLink('628112341361', `Saya membutuhkan info mengenai ${this.props.headerTitle} %0a${window.location.href}`)} style={{color: "#59c997", cursor: "pointer"}}>Hubungi Amartahonda Care</span>
                    </footer>
                </Col>
            </Layout>
        );
    }
}

export default SpkLayout;
