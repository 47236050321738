import Cookies from "universal-cookie";
import moment from "moment";
import axios from "axios";
import {baseUrlLogBq} from "../../config/apiConfig/apiConfig";
import {getUA, osName, osVersion, browserName, browserVersion, deviceType, mobileVendor, mobileModel} from 'react-device-detect';

class CreditSimulationLogService {
    private axios = axios.create({
        baseURL: baseUrlLogBq,
    });

    sendTrackLog = async (params: any) => {
        const cookies = new Cookies();
        let latCookies = cookies.get('_lat') ?? null;
        let lngCookies = cookies.get('_lng') ?? null;
        let ipCookies = cookies.get('_ip') ?? null;
        const ipAddr = (ipCookies === "null") ? null : ipCookies;
        let uniqueDeal = cookies.get('_ud') ?? null;

        const dataBody = {
            event: {
                time: moment().format("YYYY-MM-DD HH:mm:ss"),
                source_url: params?.source_url,
                category: params?.category,
                name: params?.name,
                parameter: params?.parameter,
                score: (params?.score) ? Number(params?.score) : null,
            },
            user_data: {
                client_ip_address: ipAddr,
                client_user_agent: getUA?.toString(),
                client_os: osName?.toString()?.toLowerCase() ?? null,
                client_os_version: osVersion?.toString()?.toLowerCase() ?? null,
                client_browser_name: browserName?.toString()?.toLowerCase() ?? null,
                client_browser_type: browserVersion?.toString()?.toLowerCase() ?? null,
                client_device_type: deviceType?.toString()?.toLowerCase() ?? null,
                client_device_vendor: mobileVendor?.toString()?.toLowerCase() ?? null,
                client_device_model: mobileModel?.toString()?.toLowerCase() ?? null,
                cookies: {
                    deal: uniqueDeal,
                    deal_season: params?.offerCode
                },
                location_latitude: parseFloat(latCookies),
                location_longitude: parseFloat(lngCookies)
            },
            custom_data: {
                search_target: null,
                search_info: null,
                campaign_code: params?.dealCode,
                campaign_data: params?.dealData ?? null,

                city_group: params.city_group || null,
                brand: params.brand || null,
                model_name: params.model_name || null,
                variant_code: params.variant_code || null,
                variant_name: params.variant_name || null,
                color_code: params.color_code || null,
                color_name: params.color_name || null,
                down_payment: params.down_payment || null,
                tenor: params.tenor || null,
                installment: params.installment || null,
                finco_code: params.finco_code || null,
                credit_start_date: params.credit_start_date || null,
            }
        }

        try {
            await this.axios.post<any>('/log/creditsimulation.amartahonda.com',
                dataBody, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": ""
                    }
                });
        } catch (e: any) {
            console.log(e);
        }
    }
}

export const creditSimulationLogService = new CreditSimulationLogService();