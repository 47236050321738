import React, {FunctionComponent} from "react";
import currencyFormat from "../../helper/currencyFormat";
import Empty from "antd/lib/empty";
import Spin from "antd/lib/spin";
import Timeline from "antd/lib/timeline";
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';

export const SpkPaymentHistory: FunctionComponent<any> = (props: any) => {
    return (
        <React.Fragment>
            <div style={props.fetchPaymentHistory ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
            </div>
            <div style={!props.fetchPaymentHistory ? {} : {display: 'none'}}>
                <div style={props.dataPaymentHistory.length > 0 ? {} : {display: 'none'}}>
                    <Timeline>
                        {props.dataPaymentHistory.map((dt: any, x: number) =>
                            <Timeline.Item key={x} dot={<ClockCircleOutlined style={{ fontSize: '16px' }}/>}>
                                <i style={{fontSize:"85%", color:"#59c997"}}>{dt.timestamp?.replace('T','').substring(0,10)}</i><br/>
                                {dt.institution_type} {dt.institution_name}<br/>
                                {currencyFormat(dt.amount)}<br/>
                            </Timeline.Item>
                        )}
                    </Timeline>
                </div>
                <div style={props.dataPaymentHistory.length === 0 ? {} : {display: 'none'}}>
                    <Empty/>
                </div>
            </div>
        </React.Fragment>
    );
}
