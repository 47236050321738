import React, {FunctionComponent} from "react";
import currencyFormat from "../../helper/currencyFormat";
import Spin from "antd/lib/spin";
import Typography from "antd/lib/typography";

export const SpkOutStanding: FunctionComponent<any> = (props: any) => {

    let paymentTotal = 0;
    for (const element of props.dataPaymentHistory) {
      paymentTotal += element?.amount;
    }
    const outStanding = props.dataSpk?.price_and_bill?.total_bill - paymentTotal;

    return (
        <React.Fragment>
            <div style={props.fetchPaymentHistory ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
            </div>
            <div style={!props.fetchPaymentHistory ? {} : {display: 'none'}}>
                <Typography.Text type="secondary" style={{paddingRight: 58}}> Total Tagihan <span style={props.dataSpk?.purchase_method === 'credit' ? {} : {display: 'none'}}> DP </span>: </Typography.Text> {currencyFormat(props.dataSpk?.price_and_bill?.total_bill)} <br/>
                <Typography.Text type="secondary" style={{paddingRight: 29}}> Total Pembayaran <span style={props.dataSpk?.purchase_method === 'credit' ? {} : {display: 'none'}}> DP </span>: </Typography.Text> {currencyFormat(paymentTotal)} <br/>
                <b><Typography.Text type="secondary" style={{paddingRight: 20}}> Total Sisa Tagihan <span style={props.dataSpk?.purchase_method === 'credit' ? {} : {display: 'none'}}> DP </span>: </Typography.Text> {currencyFormat(outStanding)}</b> <br/>
            </div>
        </React.Fragment>
    );
}
