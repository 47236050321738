import React, {Component} from "react";
import {NewVehicleVariantCreditSimulationStates} from "./types/NewVehicleVariantCreditSimulationTypes";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import {withRouter} from "../../hoc/withRouter";
import variantCodeUrlFormat from "../../helper/variantCodeUrlFormat";
import FincoSelect from "../../components/submission/FincoSelect";
import VariantDetailLayout from "../../components/layouts/VariantDetailLayout";
import CurrencyInput from "react-currency-input-field";
import currencyFormat from "../../helper/currencyFormat";
import "./NewVehicleVariantCreditSimulation.css";
import moment from "moment";
import {simulationCreditService} from "../../services/simulationCredit/SimulationCreditService";
import ListPromoModal from "../../components/promo/ListPromoModal";
import Button from "antd/lib/button";
import Col from "antd/lib/col";
import DatePicker from "antd/lib/date-picker";
import Form from "antd/lib/form";
import Image from "antd/lib/image";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import Tag from "antd/lib/tag";
import Tooltip from "antd/lib/tooltip";
import Typography from "antd/lib/typography";
import CalculatorOutlined from '@ant-design/icons/CalculatorOutlined';
import PercentageOutlined from '@ant-design/icons/PercentageOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import UserSwitchOutlined from '@ant-design/icons/UserSwitchOutlined';
import ResumeMiniProduct from "../../components/product/ResumeMiniProduct";
import {trackLogServices} from "../../services/track/TrackLogService";

class NewVehicleVariantCreditSimulation extends Component<any, NewVehicleVariantCreditSimulationStates> {
    private readonly initState!: NewVehicleVariantCreditSimulationStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const areaCode = match.params.area;
        const urlVariantColor = match.params.productcode.split("-");
        const variantCode = variantCodeUrlFormat(urlVariantColor[0]);
        const variantColorCode = urlVariantColor[1];

        document.title = `Amartahonda | Info | Simulasi Kredit | ${areaCode} | ${variantCode}`;

        this.initState = {
            submitting: false,
            areaCode: areaCode,
            variantCode: variantCode,
            variantColorCode: variantColorCode,
            listNewVariantColor: [],
            chosenNewVariantColor: {},
            minimumDp: 100000,
            maximumDp: 99999999,
            completeFetchVariant: false,
            tenorList: [11, 17, 23, 29, 35],
            fincoChosenCode: "GLOBAL",
            downPayment: 0,
            tenor: null,
            creditStartDate: moment(new Date(), 'YYYY-MM-DD'),
            dataConfigInstallment: {},
            fetchingFincoConfig: true,
            fetchingCreditSimulation: false,
            showCreditSimulation: false,
            dataCreditSimulation: {
                rangeDown: 0,
                rangeUp: 0,
                tenorFirst: 1,
                tenorLast: 11,
                tenorDateFirst: 1,
                tenorDateLast: 11,
            },
            modalPromoShow: false,
            usedPromoData: {},
        }
        this.state = {...this.initState}
    }

    resetEstimate = () => {
        this.setState({
            fetchingCreditSimulation: false,
            showCreditSimulation: false,
            dataCreditSimulation: {
                rangeDown: 0,
                rangeUp: 0,
                tenorFirst: 1,
                tenorLast: 11,
                tenorDateFirst: 1,
                tenorDateLast: 11,
            },
        });
    }

    async componentDidMount() {
        await trimitraCatalogServices.getCatalogVariant({
            areaCode: this.state.areaCode,
            variantCode: this.state.variantCode,
        }).then(dataVariantDetail => {
            for (const item of dataVariantDetail.data.data) {
                if (item.color_code.toUpperCase() === this.state.variantColorCode.toUpperCase()) {
                    this.setState({
                        completeFetchVariant: true,
                        listNewVariantColor: dataVariantDetail.data.data,
                        chosenNewVariantColor: item,
                        downPayment: Math.round((item?.price * 10 / 100) / 100000) * 100000,
                        maximumDp: Math.round((50 / 100 * item?.price) / 100000) * 100000,
                    });
                }
            }
        });
        await this.fetchMasterFincoSimulationConfig();

        trackLogServices.sendTrackLog({
            eventName: `vehicle credit simulation page`,
            eventSourceUrl: window.location.href,
            customData: null
        });
    }

    fetchMasterFincoSimulationConfig = async () => {
        await this.promisedSetState({
            fetchingFincoConfig: true,
        })

        try {
            await trimitraCatalogServices.getSimulationCreditFinco({
                fincoCode: this.state.fincoChosenCode,
                vehicleModel: this.state.chosenNewVariantColor?.model_name?.replace(/ /g, ''),
                areaCode: this.state.areaCode,
            }).then(async dataResp => {
                const tenorList: any = [];
                const dataConfigInstallment: any = {};
                for (const item of dataResp.data.data) {
                    tenorList.push(item?.tenor);
                    if (item?.active) {
                        dataConfigInstallment[item?.tenor] = {
                            maximum_dp: item?.maximum_dp,
                            minimum_dp: item?.minimum_dp,
                            range: item?.range,
                            range_down: item?.range_down,
                            range_up: item?.range_up,
                            rate: item?.rate,
                            tenor: item?.tenor,
                        };
                    }
                }
                this.setState({
                    tenorList: (tenorList.length > 0) ? tenorList : [11, 17, 23, 29, 35],
                    dataConfigInstallment: (tenorList.length > 0) ? dataConfigInstallment : {},
                    fetchingFincoConfig: false,
                    tenor: null,
                });
            });
        } catch (e) {
            await this.promisedSetState({
                tenorList: [11, 17, 23, 29, 35],
                minimumDp: 0,
                maximumDp: Math.round((this.state.chosenNewVariantColor?.price * 10 / 100) / 100000) * 100000,
                dataConfigInstallment: {},
                fetchingFincoConfig: false,
                tenor: null,
            });
        }
    }

    onFieldChange = <T extends keyof Pick<any, "downPayment" | "tenor">>(target: T, value: any) => {
        const currentState = {...this.state};
        switch (target) {
            case "downPayment":
                currentState.downPayment = value;
                break;
            case "tenor":
                currentState.tenor = value;
                break;
        }
        this.setState({
            ...currentState,
        });

        this.resetEstimate();
    }

    onFincoSelectChange = async (data: any) => {
        this.setState({
            fincoChosenCode: data.value
        });
        await this.fetchMasterFincoSimulationConfig();
        this.resetEstimate();
    }

    changeDpPercentage = (data: number) => {
        let dpChosen = data / 100 * (this.state.chosenNewVariantColor?.price ?? 0);
        dpChosen = Math.round(dpChosen / 100000) * 100000;
        this.setState({
            downPayment: dpChosen
        });
    }

    blurDownPayment = () => {
        let dpChosen = Math.round(this.state.downPayment / 100000) * 100000;
        if (dpChosen < this.state.minimumDp) {
            dpChosen = this.state.minimumDp;
        }
        if (dpChosen > this.state.maximumDp) {
            dpChosen = this.state.maximumDp;
        }
        this.setState({
            downPayment: dpChosen
        });
    }

    onClickTenor = async (tenor: number) => {
        this.setState({
            tenor: tenor
        });
        this.resetEstimate();
    }

    startDateChange = (data: any) => {
        if (data) {
            this.setState({
                creditStartDate: data
            });
            this.resetEstimate();
        }
    }

    installmentEstimate = async () => {
        if (!this.state.tenor || !this.state.downPayment) {
            Modal.error({
                title: 'Error',
                content: 'Mohon Lengkapi data uang muka dan tenor'
            });
            return;
        }

        await this.resetEstimate();
        await this.promisedSetState({
            fetchingCreditSimulation: true
        });

        const tenor = this.state.tenor;
        const downPayment = this.state.downPayment;
        const rateData = this.state.dataConfigInstallment[tenor];

        if (!rateData) {
            this.setState({
                fetchingCreditSimulation: false,
            });
            Modal.info({
                title: 'Informasi',
                content: 'Nantikan fitur estimasi cicilan'
            });
            return;
        }

        try {
            await simulationCreditService.getEffectiveRate({
                tenor: tenor,
                downPayment: downPayment,
                price: this.state.chosenNewVariantColor?.price,
                insurance: 0,
                payment: 0,
                rate: rateData.rate
            }).then(async dataResp => {
                await this.promisedSetState({
                    fetchingCreditSimulation: false,
                    showCreditSimulation: true,
                    dataCreditSimulation: {
                        rangeDown: dataResp.data?.data?.PaymentPerMonth - rateData.range_down,
                        rangeUp: dataResp.data?.data?.PaymentPerMonth + rateData.range_up,
                        tenorFirst: 1,
                        tenorLast: tenor,
                        tenorDateFirst: moment(this.state.creditStartDate).add(1, 'month').format("ll"),
                        tenorDateLast: moment(this.state.creditStartDate).add((tenor + 1), 'month').format("ll"),
                    },
                });

                window.scrollTo({
                    top: document.body.scrollHeight,
                    left: 0,
                    behavior: 'smooth'
                });
            });
        } catch (e) {
            this.resetEstimate();
            Modal.info({
                title: 'Informasi',
                content: 'Nantikan fitur estimasi cicilan'
            });
        }
    }

    showModalPromo = () => {
        this.setState({
            modalPromoShow: true,
        })
    }

    closeModalPromo = () => {
        this.setState({
            usedPromoData: {},
            modalPromoShow: false,
        })
    }

    onPromoChange = (data: any) => {
        this.setState({
            usedPromoData: data,
            modalPromoShow: false,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <VariantDetailLayout hideTop={false} linkBack={`/baru/${this.state.areaCode}/${this.state.variantCode.replace('/', '')}`} areaCode={this.state.areaCode} productCode={this.state.variantCode + "-" + this.state.chosenNewVariantColor?.color_code} productTitle={this.state.chosenNewVariantColor?.variant_name}
                                 parameterCash={`${(this.state.usedPromoData?.promoCode) ? '?promo=' + this.state.usedPromoData?.promoCode : ''}`}
                                 parameterCredit={`?finco=${this.state.fincoChosenCode}&downpayment=${this.state.downPayment}${(this.state.tenor) ? '&tenor=' + this.state.tenor : ''}&creditstartdate=${moment(this.state.creditStartDate).format("YYYY-MM-DD")} ${(this.state.usedPromoData?.promoCode) ? '&promo=' + this.state.usedPromoData?.promoCode : ''}`}
            >
                <div className={`content-top-padding-20 height-100`} style={this.state.completeFetchVariant ? {display: 'none'} : {}}>
                    <div style={{textAlign: "center", padding: 70}}>
                        <Spin tip="Loading..."/>
                    </div>
                </div>

                <div className={`content-top-padding-20 height-100`} style={this.state.completeFetchVariant ? {} : {display: 'none'}}>
                    <Row style={{paddingLeft: 20}}>
                        <Col span={19}>
                            <ResumeMiniProduct
                                modelName={this.state.chosenNewVariantColor.model_name}
                                variantCode={this.state.chosenNewVariantColor.variant_code}
                                variantName={this.state.chosenNewVariantColor.variant_name}
                                variantColorCode={this.state.chosenNewVariantColor.color_code}
                                variantColorName={this.state.chosenNewVariantColor.color_name}
                                price={this.state.chosenNewVariantColor.price}
                                linkTarget={`/baru/${this.state.areaCode}/${this.state.variantCode.replace('/', '')}`}
                            />
                        </Col>
                        <Col span={4}>
                            <div style={{paddingTop: 30}}>
                                <Image alt={`variant color`} src={this.state.chosenNewVariantColor.url_image} style={{width: "100%"}}/>
                            </div>
                        </Col>
                        <Col span={1}>&nbsp;</Col>
                    </Row><br/>
                    <div className={`card-simple-simulation`} style={{marginLeft: '2%', marginRight: '2%'}}>
                        <Row>
                            <Col span={20}>
                                <UserSwitchOutlined className={`icon-simple-simulation`}/>
                                <span className={`title-simple-simulation`}>Leasing Yang Diinginkan</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                &nbsp;
                            </Col>
                            <Col span={22}>
                                <Form.Item style={{marginTop: 10}}>
                                    <FincoSelect onFincoSelectChangeProps={this.onFincoSelectChange} value={this.state.fincoChosenCode}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    <div className={`card-simple-simulation`} style={{marginLeft: '2%', marginRight: '2%'}}>
                        <Row>
                            <Col span={20}>
                                <CalculatorOutlined className={`icon-simple-simulation`}/>
                                <span className={`title-simple-simulation`}>Skema Kredit</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                &nbsp;
                            </Col>
                            <Col span={22}>
                                <Form.Item style={{marginTop: 12}}>
                                    <span style={{fontSize: '115%', color: '#828282'}}>Uang Muka</span> <i style={{color: "gray", fontSize: '80%'}}>(Kelipatan 100k)</i><br/>
                                    <div style={{padding: 10}}>
                                        <Tooltip placement="topLeft" title="jadikan uang muka 10% dari OTR">
                                            <Tag style={{cursor: "pointer"}} onClick={() => this.changeDpPercentage(10)} color="#f50">10%</Tag>
                                        </Tooltip>
                                        <Tooltip placement="topLeft" title="jadikan uang muka 15% dari OTR">
                                            <Tag style={{cursor: "pointer"}} onClick={() => this.changeDpPercentage(15)} color="#e2c430">15%</Tag>
                                        </Tooltip>
                                        <Tooltip placement="topLeft" title="jadikan uang muka 20% dari OTR">
                                            <Tag style={{cursor: "pointer"}} onClick={() => this.changeDpPercentage(20)} color="#87d068">20%</Tag>
                                        </Tooltip>
                                        <Tooltip placement="topLeft" title="jadikan uang muka 25% dari OTR">
                                            <Tag style={{cursor: "pointer"}} onClick={() => this.changeDpPercentage(25)} color="#87d068">25%</Tag>
                                        </Tooltip>
                                        <Tooltip placement="topLeft" title="jadikan uang muka 30% dari OTR">
                                            <Tag style={{cursor: "pointer"}} onClick={() => this.changeDpPercentage(30)} color="#87d068">30%</Tag>
                                        </Tooltip>
                                    </div>
                                    <CurrencyInput
                                        className={'ant-input'}
                                        groupSeparator={'.'}
                                        decimalSeparator={','}
                                        prefix={'Rp. '}
                                        style={{marginTop: 10}}
                                        value={this.state.downPayment}
                                        onValueChange={value => this.onFieldChange("downPayment", value ? parseFloat(value) : 0)}
                                        onBlur={this.blurDownPayment}
                                    />
                                </Form.Item>

                                <Form.Item style={{marginTop: 12}}>
                                    <span style={{fontSize: '115%', color: '#828282'}}>Tenor</span> <i style={{color: "gray", fontSize: '80%'}}>(bulan)</i><br/>
                                    <Row className={`row-btn-simple`}>
                                        {
                                            this.state.tenorList.map((item: any) =>
                                                <Col span={4} key={item}>
                                                    <div onClick={() => this.onClickTenor(item)} className={`btn-chose-tenor` + (this.state.tenor === item ? '-active' : '')}>
                                                        {item}
                                                    </div>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                    <span style={{fontSize: '115%', color: '#828282'}}>Tanggal Mulai Kredit</span> <i style={{color: "gray", fontSize: '80%'}}>(estimasi)</i><br/>
                                    <DatePicker value={moment(this.state.creditStartDate, 'YYYY-MM-DD')} onChange={event => this.startDateChange(event)} format={'YYYY-MM-DD'} style={{width: "100%", marginTop: 5}}/>
                                </Form.Item>
                                <Button loading={this.state.fetchingCreditSimulation} style={{float: "right", background: "#fff", color: "#64b48f", borderColor: "#64b48f"}} onClick={this.installmentEstimate}>Estimasi Cicilan</Button><br/>

                                <Col span={24} style={this.state.fetchingCreditSimulation ? {paddingTop: 25} : {display: 'none'}}>
                                    <div style={{textAlign: "center", padding: 70}}>
                                        <Spin tip="Loading..."/>
                                    </div>
                                </Col>

                                <Col span={24} style={this.state.showCreditSimulation ? {paddingTop: 25} : {display: 'none'}}>
                                    <b>Estimasi Angsuran / Cicilan</b><br/><br/>
                                    <span style={{color: "gray", fontSize: '90%'}}>Angsuran/bulan :</span> <i style={{paddingLeft: 3, color: '#64b48f', fontSize: '90%'}}>{currencyFormat(Math.round(this.state.dataCreditSimulation.rangeDown / 1000) * 1000)} - {currencyFormat(Math.round(this.state.dataCreditSimulation.rangeUp / 1000) * 1000)}</i><br/>
                                    <span style={{color: "gray", fontSize: '90%'}}>Durasi Cicilan :</span> <i style={{paddingLeft: 16}}>{this.state.dataCreditSimulation.tenorLast} Bulan</i><br/>
                                    <span style={{color: "gray", fontSize: '90%'}}>Cicilan ke-{this.state.dataCreditSimulation.tenorFirst} :</span> <i style={{paddingLeft: 28}}>{this.state.dataCreditSimulation.tenorDateFirst}</i><br/>
                                    <span style={{color: "gray", fontSize: '90%'}}>Cicilan ke-{this.state.dataCreditSimulation.tenorLast} :</span> <i style={{paddingLeft: 20}}>{this.state.dataCreditSimulation.tenorDateLast}</i><br/>
                                </Col>

                                <Col span={24}>
                                    <br/><i style={{fontSize: '80%'}}>*Angsuran bisa berubah sesuai anjuran finance company / leasing selama belum tanda tangan perjanjian kredit</i><br/>
                                </Col><br/>
                            </Col>
                        </Row>
                    </div>

                    <div className={`card-simple-simulation`} style={{marginLeft: '2%', marginRight: '2%'}}>
                        <Row>
                            <Col span={20}>
                                <PercentageOutlined className={`icon-simple-simulation`}/>
                                <span className={`title-simple-simulation`}>Promo</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                &nbsp;
                            </Col>
                            <Col span={22}>
                                <Input value={this.state.usedPromoData?.promoCode} onClick={this.showModalPromo} style={{cursor: "pointer"}} readOnly={true} size="large" placeholder="Lebih Hemat Dengan Promo" prefix={<SearchOutlined className={'grey-color'}/>}/>
                                <div style={this.state.usedPromoData?.note ? {} : {display: 'none'}}>
                                    <br/>
                                    <i><Typography.Text mark>{this.state.usedPromoData?.note}</Typography.Text></i>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <br/>
                </div>

                <Modal
                    title="Promo"
                    maskClosable={false}
                    open={this.state.modalPromoShow}
                    onOk={this.closeModalPromo}
                    onCancel={this.closeModalPromo}
                    footer={null}
                >
                    <ListPromoModal
                        onPromoChangeProps={this.onPromoChange}
                        company={"amarta"}
                        purchaseMethod={"credit"}
                        area={this.state.areaCode}
                        vehicle={this.state.chosenNewVariantColor}
                    />
                </Modal>
            </VariantDetailLayout>
        );
    }
}

export default withRouter(NewVehicleVariantCreditSimulation);
