import React, {Component} from "react";
import {Buffer} from "buffer";
import {connect} from "react-redux";
import CurrencyInput from "react-currency-input-field";
import '../../screen/newVehicleVariantDetailCredit/NewVehicleVariantDetailFormCredit.css';
import currencyFormat from "../../helper/currencyFormat";
import FincoSelect from "./FincoSelect";
import moment from "moment";
import LeasingSurveyTime from "./LeasingSurveyTime";
import ListPromoModal from "../promo/ListPromoModal";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import {simulationCreditService} from "../../services/simulationCredit/SimulationCreditService";
import {promoServices} from "../../services/promo/PromoService";
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Col from "antd/lib/col";
import Collapse from "antd/lib/collapse";
import DatePicker from "antd/lib/date-picker";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";
import Select from "antd/lib/select";
import Spin from "antd/lib/spin";
import Tag from "antd/lib/tag";
import Tooltip from "antd/lib/tooltip";
import Typography from "antd/lib/typography";
import {Checkbox} from "antd";
import {authApiService} from "../../services/amartaVip/AuthApiService";

class CreditSubmissionSurvey extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            modalPromoShow: false,
            tenorList: [11, 17, 23, 29, 35],
            minimumDp: 100000,
            maximumDp: Math.round((75 / 100 * this.props.vehicleData?.price) / 100000) * 100000,
            dataConfigInstallment: {},
            fetchingFincoConfig: true,

            fetchingCreditSimulation: false,
            showCreditSimulation: false,
            dataCreditSimulation: {
                rangeDown: 0,
                rangeUp: 0,
                tenorFirst: 1,
                tenorLast: 11,
                tenorDateFirst: 1,
                tenorDateLast: 11,
            },
            amartaVipUid: "",
        }

        this.state = {...this.initState}
    }

    resetEstimate = async () => {
        await this.props.changeCreditInstallmentAmount(0);
        await this.promisedSetState({
            fetchingCreditSimulation: false,
            showCreditSimulation: false,
            dataCreditSimulation: {
                rangeDown: 0,
                rangeUp: 0,
                tenorFirst: 1,
                tenorLast: 11,
                tenorDateFirst: 1,
                tenorDateLast: 11,
            },
        })
    }

    onFieldChange = <T extends keyof Pick<any, "installmentAmount" | "fincoId" | "downPayment" | "tenor" | "takeVehicleInDealer">>(target: T, value: any) => {
        const currentState = {...this.state};
        switch (target) {
            case "installmentAmount":
                this.props.changeInstallmentAmount(value);
                break;
            case "downPayment":
                this.props.changeDownPayment(value);
                break;
            case "tenor":
                this.props.changeTenor(value);
                const rateCredit = this.state.dataConfigInstallment[value]?.rate ?? 0;
                this.props.changeCreditRate(rateCredit);
                break;
            case "takeVehicleInDealer":
                this.props.changeTakeVehicleInDealer(value);
                break;
            case "fincoId":
                this.props.changeFincoId(value);
                break;
        }
        this.setState({
            ...currentState,
        });
        this.props.changePromoChosenData({});
        this.resetEstimate();
    }

    blurDownPayment = () => {
        let dpChosen = Math.round(this.props.downPayment / 100000) * 100000;

        if (dpChosen < this.state.minimumDp) {
            dpChosen = this.state.minimumDp;
        }
        if (dpChosen > this.state.maximumDp) {
            dpChosen = this.state.maximumDp;
        }
        this.props.changeDownPayment(dpChosen);
    }

    onFincoSelectChange = async (data: any) => {
        await this.props.changeFincoChosenCode(data.value);
        await this.props.changeFincoChosenName(data.children);
        await this.fetchMasterFincoSimulationConfig();
        this.resetEstimate();
    }

    onLeasingSurveyTimeChange = (data: any) => {
        this.props.changeSurveyTime(data.value);
    }

    startDateChange = (data: any) => {
        if (data) {
            this.props.changeCreditStartDate(data);
            this.resetEstimate();
        }
    }

    surveyDateChange = (data: any) => {
        if (data) {
            this.props.changeSurveyDate(data);
        }
    }

    changeDpPercentage = (data: number) => {
        let dpChosen = data / 100 * (this.props.vehicleData?.price ?? 0);
        dpChosen = Math.round(dpChosen / 100000) * 100000;
        this.props.changeDownPayment(dpChosen);
        this.props.changePromoChosenData({});
    }

    showModalPromo = () => {
        if (!this.props.tenor) {
            Modal.error({
                title: 'Error',
                content: 'Silakan pilih tenor terlebih dahulu'
            });
            return;
        }

        this.setState({
            modalPromoShow: true,
        })
    }

    closeModalPromo = () => {
        this.setState({
            // usedPromoData: {},
            modalPromoShow: false,
        })
    }

    onPromoChange = (data: any) => {
        this.setState({
            modalPromoShow: false,
        });

        const dataPromo = {
            discount_maximum: data.discountMaximum,
            discount_type: data.discountType,
            discount_value: data.discountValue,
            final_discount: data.finalDiscount,
            note: data.note,
            price: data.price,
            promo_code: data.promoCode,
            show_agent: data.showAgent,
        }

        this.props.changePromoChosenData(dataPromo);
    }

    installmentEstimate = async () => {
        if (!this.props.tenor || !this.props.downPayment) {
            Modal.error({
                title: 'Error',
                content: 'Mohon Lengkapi data uang muka dan tenor'
            });
            return;
        }

        await this.resetEstimate();
        await this.promisedSetState({
            fetchingCreditSimulation: true
        });

        const tenor = this.props.tenor;
        const downPayment = this.props.downPayment;
        const rateData = this.state.dataConfigInstallment[tenor];

        if (!rateData) {
            this.setState({
                fetchingCreditSimulation: false,
            });
            Modal.info({
                title: 'Informasi',
                content: 'Nantikan fitur estimasi cicilan'
            });
            return;
        }

        try {
            await simulationCreditService.getEffectiveRate({
                tenor: tenor,
                downPayment: downPayment,
                price: this.props.vehicleData?.price,
                insurance: 0,
                payment: 0,
                rate: rateData.rate
            }).then(async dataResp => {
                const creditInstallmentAmount = Math.round((dataResp.data?.data?.PaymentPerMonth ?? 0) / 1000) * 1000;
                await this.props.changeCreditInstallmentAmount(creditInstallmentAmount);
                await this.promisedSetState({
                    fetchingCreditSimulation: false,
                    showCreditSimulation: true,
                    dataCreditSimulation: {
                        rangeDown: dataResp.data?.data?.PaymentPerMonth - rateData.range_down,
                        rangeUp: dataResp.data?.data?.PaymentPerMonth + rateData.range_up,
                        tenorFirst: 1,
                        tenorLast: tenor,
                        tenorDateFirst: moment(this.props?.creditStartDate).add(1, 'month').format("ll"),
                        tenorDateLast: moment(this.props?.creditStartDate).add((tenor + 1), 'month').format("ll"),
                    },
                })
            });
        } catch (e) {
            await this.props.changeCreditInstallmentAmount(0);
            this.resetEstimate();
            Modal.info({
                title: 'Informasi',
                content: 'Nantikan fitur estimasi cicilan'
            });
        }
    }

    async componentDidMount() {
        await this.fetchMasterFincoSimulationConfig();

        const isExistAmartaVipUid = await authApiService.isExistAmartaVipUserId();
        if (isExistAmartaVipUid) {
            await this.promisedSetState({
                amartaVipUid: Buffer.from(await authApiService.isExistAmartaVipUserId(), 'base64').toString()
            });
        }

        // param from simulation or promo
        if (this.props?.paramData?.credit?.finco_code && this.props?.paramData?.credit?.finco_name) {
            await this.props.changeFincoChosenCode(this.props?.paramData?.credit?.finco_code?.toUpperCase());
            await this.props.changeFincoChosenName(this.props?.paramData?.credit?.finco_name?.toUpperCase());
        }
        if (this.props?.paramData?.credit?.tenor) {
            await this.props.changeTenor(this.props?.paramData?.credit?.tenor);
            const rateCredit = this.state.dataConfigInstallment[this.props?.paramData?.credit?.tenor]?.rate ?? 0;
            this.props.changeCreditRate(rateCredit);
        }
        if (this.props?.paramData?.credit?.credit_start_date) {
            await this.props.changeCreditStartDate(moment(new Date(this.props?.paramData?.credit?.credit_start_date), 'YYYY-MM-DD') ?? moment(new Date(), 'YYYY-MM-DD'));
        }
        if (this.props?.paramData?.credit?.dp_amount) {
            await this.props.changeDownPayment(this.props?.paramData?.credit?.dp_amount);
        }

        if (this.props?.paramData?.promo_code) {
            promoServices.getDetailPromo(
                {
                    transactionAmount: this.props.vehicleData?.price,
                    promoCode: this.props?.paramData?.promo_code,
                    cityGroup: this.props.areaProps,
                    purchaseMethod: 'credit',
                    brand: 'honda',
                    model: this.props.vehicleData?.model_name,
                    variant: this.props.vehicleData?.variant_code
                }
            ).then(dataFetchDetailPromo => {
                this.onPromoChange(dataFetchDetailPromo);
            }).catch(e => {
                console.log(e);
            });
        }

        const isExistAmartaVipAffiliateData = await authApiService.isExistAmartaVipInternalAffiliateData();
        if (isExistAmartaVipAffiliateData.affiliateFincoId) {
            this.props.changeFincoId(Buffer.from(isExistAmartaVipAffiliateData.affiliateFincoId, 'base64').toString());
        }
        if (isExistAmartaVipAffiliateData.affiliateCompanyCode) {
            this.props.changeFincoChosenCode(Buffer.from(isExistAmartaVipAffiliateData.affiliateCompanyCode, 'base64').toString());
            this.props.changeFincoChosenName(Buffer.from(isExistAmartaVipAffiliateData.affiliateCompanyCode, 'base64').toString());
        }
    }

    fetchMasterFincoSimulationConfig = async () => {
        await this.promisedSetState({
            fetchingFincoConfig: true,
        })

        try {
            await trimitraCatalogServices.getSimulationCreditFinco({
                fincoCode: this.props.fincoChosenCode,
                vehicleModel: this.props.vehicleData?.model_name?.replace(/ /g, ''),
                areaCode: this.props.areaProps,
            }).then(async dataResp => {
                const tenorList: any = [];
                const dataConfigInstallment: any = {};

                for (const item of dataResp.data.data) {
                    tenorList.push(item?.tenor);
                    if (item?.active) {
                        dataConfigInstallment[item?.tenor] = {
                            maximum_dp: item?.maximum_dp,
                            minimum_dp: item?.minimum_dp,
                            range: item?.range,
                            range_down: item?.range_down,
                            range_up: item?.range_up,
                            rate: item?.rate,
                            tenor: item?.tenor,
                        };
                    }
                }
                await this.promisedSetState({
                    tenorList: (tenorList.length > 0) ? tenorList : [11, 17, 23, 29, 35],
                    dataConfigInstallment: (tenorList.length > 0) ? dataConfigInstallment : {},
                    fetchingFincoConfig: false,
                });
                await this.props.changeTenor(null);
            });
        } catch (e) {
            await this.promisedSetState({
                tenorList: [11, 17, 23, 29, 35],
                minimumDp: 0,
                maximumDp: Math.round((75 / 100 * this.props.vehicleData?.price) / 100000) * 100000,
                dataConfigInstallment: {},
                fetchingFincoConfig: false,
            })
            await this.props.changeTenor(null);
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Collapse collapsible="header" defaultActiveKey={['1']} ghost>
                    <Collapse.Panel header="Detail Kendaraan" key="1">
                        <div style={{paddingLeft: 30}}>
                            <Row><Col span={6}><Typography.Text type="secondary"> Model: </Typography.Text></Col><Col span={18}> {this.props.vehicleData?.model_name} </Col></Row>
                            <Row><Col span={6}><Typography.Text type="secondary"> Variant: </Typography.Text></Col><Col span={18}> {this.props.vehicleData?.variant_name} </Col></Row>
                            <Row><Col span={6}><Typography.Text type="secondary"> Kode Variant: </Typography.Text></Col><Col span={18}> {this.props.vehicleData?.variant_code} </Col></Row>
                            <Row><Col span={6}><Typography.Text type="secondary"> Warna: </Typography.Text></Col><Col span={18}> ({this.props.vehicleData?.color_code}) {this.props.vehicleData?.color_name}  </Col></Row>
                            <Row><Col span={6}><Typography.Text type="secondary"> Harga OTR: </Typography.Text></Col><Col span={18}> {currencyFormat(this.props.vehicleData?.price)} </Col></Row>
                        </div>
                    </Collapse.Panel>
                </Collapse><br/>

                <Form layout="vertical">
                    <Card title="Leasing" size={"small"}>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Leasing Yang Diinginkan</i>
                            <FincoSelect onFincoSelectChangeProps={this.onFincoSelectChange} value={this.props.fincoChosenCode}/>
                        </Form.Item>
                        <Form.Item style={this.props.fincoChosenCode !== 'GLOBAL' ? {} : {display: 'none'}}>
                            <i className={`text-small-grey-nopadding`}>Finco ID</i>
                            <Input
                                value={this.props.fincoId}
                                placeholder="Dapat di Kosongkan"
                                onChange={event => this.onFieldChange("fincoId", event.target.value)}
                            />
                        </Form.Item>
                    </Card><br/>

                    <div style={this.state.fetchingFincoConfig ? {} : {display: 'none'}}>
                        <div style={{textAlign: "center", padding: 70}}>
                            <Spin tip="Loading..."/>
                        </div>
                    </div>
                    <div style={this.state.fetchingFincoConfig ? {display: 'none'} : {}}>
                        <Card title="Skema Kredit" size={"small"}>
                            <Tooltip placement="topLeft" title="jadikan uang muka 10% dari OTR">
                                <Tag style={{cursor: "pointer"}} onClick={() => this.changeDpPercentage(10)} color="#f50">10%</Tag>
                            </Tooltip>
                            <Tooltip placement="topLeft" title="jadikan uang muka 15% dari OTR">
                                <Tag style={{cursor: "pointer"}} onClick={() => this.changeDpPercentage(15)} color="#e2c430">15%</Tag>
                            </Tooltip>
                            <Tooltip placement="topLeft" title="jadikan uang muka 20% dari OTR">
                                <Tag style={{cursor: "pointer"}} onClick={() => this.changeDpPercentage(20)} color="#87d068">20%</Tag>
                            </Tooltip>
                            <Tooltip placement="topLeft" title="jadikan uang muka 25% dari OTR">
                                <Tag style={{cursor: "pointer"}} onClick={() => this.changeDpPercentage(25)} color="#87d068">25%</Tag>
                            </Tooltip>
                            <Tooltip placement="topLeft" title="jadikan uang muka 30% dari OTR">
                                <Tag style={{cursor: "pointer"}} onClick={() => this.changeDpPercentage(30)} color="#87d068">30%</Tag>
                            </Tooltip>
                            <Form.Item style={{marginTop: 10}}>
                                <i className={`text-small-grey-nopadding`}>Uang Muka</i> <i style={{color: "gray", fontSize: '75%'}}>(Kelipatan 100k)</i>
                                <CurrencyInput
                                    className={'ant-input'}
                                    groupSeparator={'.'}
                                    decimalSeparator={','}
                                    prefix={'Rp. '}
                                    style={{marginTop: 5}}
                                    value={this.props.downPayment}
                                    onValueChange={value => this.onFieldChange("downPayment", value ? parseFloat(value) : 0)}
                                    onBlur={this.blurDownPayment}
                                />
                            </Form.Item>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Tenor</i>
                                <Select
                                    placeholder="Pilih Tenor"
                                    showSearch
                                    style={{marginTop: 5}}
                                    optionFilterProp="children"
                                    onChange={value => this.onFieldChange("tenor", value)}
                                    value={this.props.tenor}
                                >
                                    {
                                        this.state.tenorList.map((item: any, i: number) =>
                                            <Select.Option key={i} value={item}>{item}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item style={this.state.amartaVipUid ? {marginTop: 10} : {display: 'none'}}>
                                <i className={`text-small-grey-nopadding`}>Pengajuan Angsuran</i>
                                <CurrencyInput
                                    className={'ant-input'}
                                    groupSeparator={'.'}
                                    decimalSeparator={','}
                                    prefix={'Rp. '}
                                    style={{marginTop: 5}}
                                    value={this.props.installmentAmount}
                                    onValueChange={value => this.onFieldChange("installmentAmount", value ? parseFloat(value) : 0)}
                                />
                            </Form.Item>
                            <Form.Item className={'padTop-7'}>
                                <i className={`text-small-grey-nopadding`}>Tanggal Mulai Kredit</i>
                                <DatePicker value={moment(this.props.creditStartDate, 'YYYY-MM-DD')} onChange={event => this.startDateChange(event)} format={'YYYY-MM-DD'} style={{width: "100%", marginTop: 5}}/>
                            </Form.Item>
                            <Row>
                                <Col span={24}>
                                    <Button loading={this.state.fetchingCreditSimulation} style={{float: "right", background: "#fff", color: "#64b48f", borderColor: "#64b48f"}} onClick={this.installmentEstimate}>Estimasi Cicilan</Button>
                                </Col>

                                <Col span={24} style={this.state.fetchingCreditSimulation ? {paddingTop: 25} : {display: 'none'}}>
                                    <div style={{textAlign: "center", padding: 70}}>
                                        <Spin tip="Loading..."/>
                                    </div>
                                </Col>

                                <Col span={24} style={this.state.showCreditSimulation ? {paddingTop: 25} : {display: 'none'}}>
                                    <b>Estimasi Angsuran / Cicilan</b><br/><br/>
                                    <span style={{color: "gray"}}>Angsuran/bulan :</span> <i style={{paddingLeft: 8}}>{currencyFormat(Math.round(this.state.dataCreditSimulation.rangeDown / 1000) * 1000)} - {currencyFormat(Math.round(this.state.dataCreditSimulation.rangeUp / 1000) * 1000)}</i><br/>
                                    <span style={{color: "gray"}}>Durasi Cicilan :</span> <i style={{paddingLeft: 21}}>{this.state.dataCreditSimulation.tenorLast} Bulan</i><br/>
                                    <span style={{color: "gray"}}>Cicilan ke-{this.state.dataCreditSimulation.tenorFirst} :</span> <i style={{paddingLeft: 32}}>{this.state.dataCreditSimulation.tenorDateFirst}</i><br/>
                                    <span style={{color: "gray"}}>Cicilan ke-{this.state.dataCreditSimulation.tenorLast} :</span> <i style={{paddingLeft: 25}}>{this.state.dataCreditSimulation.tenorDateLast}</i><br/>
                                </Col>

                                <Col span={24}>
                                    <br/><i style={{fontSize: '80%'}}>*Angsuran bisa berubah sesuai anjuran finance company / leasing selama belum tanda tangan perjanjian kredit</i><br/>
                                </Col>
                            </Row>
                        </Card><br/>
                    </div>

                    <Card size={"small"}>
                        <Col span={24}>
                            <Checkbox onChange={e => this.onFieldChange("takeVehicleInDealer", e.target.checked)} checked={this.props.takeVehicleInDealer}>
                                Saya ingin mengambil unit di dealer.
                            </Checkbox>
                            <br/><i style={{fontSize: '80%'}}>*Ceklis jika anda ingin mengambil kendaraan di dealer seacara langsung</i><br/>
                        </Col>
                    </Card><br/>

                    <Card title="Promo" size={"small"}>
                        <Row>
                            <Col span={24}>
                                <Input value={this.props.promoChosenData?.promo_code} onClick={this.showModalPromo} style={{cursor: "pointer"}} readOnly={true} size="large" placeholder="Lebih Hemat Dengan Promo" prefix={<SearchOutlined className={'grey-color'}/>}/>
                                <div style={this.props.promoChosenData?.note ? {} : {display: 'none'}}>
                                    <br/>
                                    <i><Typography.Text mark>{this.props.promoChosenData?.note}</Typography.Text></i>
                                </div>
                            </Col>
                        </Row>
                    </Card><br/>

                    <Card title="Jadwal Survey" size={"small"}>
                        <Form.Item className={'padTop-7'}>
                            <i className={`text-small-grey-nopadding`}>Tanggal Survey</i>
                            <DatePicker value={moment(this.props.surveyDate, 'YYYY-MM-DD')} onChange={event => this.surveyDateChange(event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Jam Survey</i>
                            <LeasingSurveyTime onLeasingSurveyTimeChangeProps={this.onLeasingSurveyTimeChange} value={this.props.surveyTime}/>
                        </Form.Item>
                    </Card>
                </Form>

                <Modal
                    title="Promo"
                    maskClosable={false}
                    open={this.state.modalPromoShow}
                    onOk={this.closeModalPromo}
                    onCancel={this.closeModalPromo}
                    footer={null}
                >
                    <ListPromoModal
                        onPromoChangeProps={this.onPromoChange}
                        company={"amarta"}
                        tenor={this.props.tenor}
                        transactionValue={this.props.downPayment}
                        purchaseMethod={"credit"}
                        area={this.props.areaProps}
                        fincoCode={this.props.fincoChosenCode}
                        vehicle={{
                            model_name: this.props.vehicleData?.model_name,
                            variant_code: this.props.vehicleData?.variant_code,
                            price: this.props.vehicleData?.price,
                        }}
                        alternativeColor={this.props.vehicleData?.alternative_color?.code}
                        amartaVipUid={this.state.amartaVipUid}
                    />
                </Modal>
            </React.Fragment>
        );
    }
}

const reduxState = (state: any) => ({
    vehicleData: state.RvehicleData,
    downPayment: state.RdownPayment,
    installmentAmount: state.RinstallmentAmount,
    tenor: state.Rtenor,
    fincoId: state.RfincoId,
    fincoChosenCode: state.RfincoChosenCode,
    fincoChosenName: state.RfincoChosenName,
    creditStartDate: state.RcreditStartDate,
    surveyDate: state.RsurveyDate,
    surveyTime: state.RsurveyTime,
    promoChosenData: state.RpromoChosenData,
    creditRate: state.RcreditRate,
    creditInstallmentAmount: state.RcreditInstallmentAmount,
    takeVehicleInDealer: state.RtakeVehicleInDealer,

    paramData: state.RparamData,
});

const reduxDispatch = (dispatch: any) => ({
    changeDownPayment: (data: string) => dispatch({type: 'CHANGE_DOWNPAYMENT', value: data}),
    changeInstallmentAmount: (data: string) => dispatch({type: 'CHANGE_INSTALLMENTAMOUNT', value: data}),
    changeTenor: (data: string) => dispatch({type: 'CHANGE_TENOR', value: data}),
    changeFincoId: (data: string) => dispatch({type: 'CHANGE_FINCOID', value: data}),
    changeFincoChosenCode: (data: string) => dispatch({type: 'CHANGE_FINCOCHOSENCODE', value: data}),
    changeFincoChosenName: (data: string) => dispatch({type: 'CHANGE_FINCOCHOSENNAME', value: data}),
    changeCreditStartDate: (data: string) => dispatch({type: 'CHANGE_CREDITSTARTDATE', value: data}),
    changeSurveyDate: (data: string) => dispatch({type: 'CHANGE_SURVEYDATE', value: data}),
    changeSurveyTime: (data: string) => dispatch({type: 'CHANGE_SURVEYTIME', value: data}),
    changePromoChosenData: (data: string) => dispatch({type: 'CHANGE_PROMOCHOSENDATA', value: data}),
    changeCreditRate: (data: string) => dispatch({type: 'CHANGE_CREDIT_RATE', value: data}),
    changeCreditInstallmentAmount: (data: string) => dispatch({type: 'CHANGE_CREDIT_INSTALLMENTAMOUNT', value: data}),
    changeTakeVehicleInDealer: (data: string) => dispatch({type: 'CHANGE_TAKE_VEHICLE_IN_DELAER', value: data}),

    changeParamData: (data: string) => dispatch({type: 'CHANGE_PARAM_DATA', value: data}),
});

export default connect(reduxState, reduxDispatch)(CreditSubmissionSurvey);
