import React, {Component} from "react";
import {PushOfferStates} from "./types/PushOfferTypes";
import currencyFormat from "../../helper/currencyFormat";
import {withRouter} from "../../hoc/withRouter";
import MyProfileLoginHeader from "../../components/layouts/MyProfileLoginHeader";
import {Button, Divider, Input, Layout} from "antd";
import Form from "antd/lib/form";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import {appVersion} from "../../config/dataConfig/appConfig";
import FooterSection from "../../components/footer/FooterSection";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import Select from "antd/lib/select";
import Modal from "antd/lib/modal";
import Typography from "antd/lib/typography";
import Spin from "antd/lib/spin";
import {Buffer} from "buffer";
import Cookies from 'universal-cookie';
import {b2bService} from "../../services/b2b/b2bService";
import Empty from "antd/lib/empty";
import {offerApiServices} from "../../services/offer/OfferApiService";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import VehicleModelSelect from "../../components/vehicle-copan/VehicleModelSelect";
import VehicleVariantSelect from "../../components/vehicle-copan/VehicleVariantSelect";
import VehicleVariantColorSelect from "../../components/vehicle-copan/VehicleVariantColorSelect";
import {promoServices} from "../../services/promo/PromoService";

class Spk extends Component<any, PushOfferStates> {
    private readonly initState!: PushOfferStates;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const offerCode = match.params.offercode;

        if (!this.cookies.get('_amh-t')) {
            window.location.href = "/login";
            return;
        }

        document.title = `Amartahonda | Push | ${offerCode}`;

        this.initState = {
            offerCode: offerCode,
            promoCode: "",
            promoInitCode: "",
            areaCode: null,
            listCityGroup: [],
            notes: "",
            freezeStatus: false,
            token: this.cookies.get('_amh-t'),
            amartaVipUid: Buffer.from(this.cookies.get('_amh-ui'), 'base64').toString() ?? "",
            amartaVipType: Buffer.from(this.cookies.get('_amh-ut'), 'base64').toString() ?? "",
            userCode: Buffer.from(this.cookies.get('_amh-uc'), 'base64').toString() ?? "",
            userName: Buffer.from(this.cookies.get('_amh-n'), 'base64').toString() ?? "",
            fetchData: true,
            offerNotFount: false,
            poNotFound: false,
            surveyData: {},
            successPage: false,
            noAccessPage: false,

            vehicleBrandUuid: "a",
            vehicleBrandName: "a",
            vehicleModelUuid: "a",
            vehicleModelName: "a",
            vehicleVariantUuid: "a",
            vehicleVariantCode: "a",
            vehicleVariantName: "a",
            vehicleColorCode: "a",
            vehicleColorName: "a",

            fetchSimulationTotal: false,
            tempSimulationDiscountPromo: 0,
            tempSimulationYearPromo: "",
            tempSimulationTotal: 0,

            offerExist: false,
        }
        this.state = {...this.initState}
    }

    onFieldChange = <T extends keyof Pick<any, "areaCode" | "promoCode" | "notes">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "areaCode":
                currentState.areaCode = value;
                break;
            case "promoCode":
                currentState.promoCode = value;
                break;
            case "notes":
                currentState.notes = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    async componentDidMount() {
        await authApiService.setToken(this.state.token);
        try {
            const a = await authApiService.getDetailUserOrder({offerCode: this.state.offerCode});
            if (a.data.offer) {
                this.setState({
                    offerExist: true
                });
                console.log("SPK already in offer");
            }
        } catch (e) {
            this.setState({
                noAccessPage: true,
            })
        }

        try {
            const resp = await b2bService.getDetailSurvey({offerCode: this.state.offerCode});
            const tempSurveyData = resp?.data?.data;
            await setTimeout(() => {
                this.setState({
                    offerNotFount: (!resp?.data?.data?.survey?.offer_code),
                    poNotFound: (!resp?.data?.data?.purchaseOrder?.poNumber),
                    surveyData: resp?.data?.data,
                    areaCode: resp?.data?.data?.survey?.area?.toUpperCase() ?? null,
                    promoCode: resp?.data?.data?.creditForm?.promo_codes?.toString() ?? "",
                    promoInitCode: resp?.data?.data?.creditForm?.promo_codes?.toString() ?? "",

                    vehicleBrandUuid: (tempSurveyData?.purchaseOrder?.po_vehicle?.brand?.uuid) ? tempSurveyData?.purchaseOrder?.po_vehicle?.brand?.uuid : tempSurveyData?.survey?.vehicle?.brand?.uuid,
                    vehicleBrandName: (tempSurveyData?.purchaseOrder?.po_vehicle?.brand?.name) ? tempSurveyData?.purchaseOrder?.po_vehicle?.brand?.name : tempSurveyData?.survey?.vehicle?.brand?.name,
                    vehicleModelUuid: (tempSurveyData?.purchaseOrder?.po_vehicle?.model?.uuid) ? tempSurveyData?.purchaseOrder?.po_vehicle?.model?.uuid : tempSurveyData?.survey?.vehicle?.model?.uuid,
                    vehicleModelName: (tempSurveyData?.purchaseOrder?.po_vehicle?.model?.name) ? tempSurveyData?.purchaseOrder?.po_vehicle?.model?.name : tempSurveyData?.survey?.vehicle?.model?.name,
                    vehicleVariantUuid: (tempSurveyData?.purchaseOrder?.po_vehicle?.variant?.uuid) ? tempSurveyData?.purchaseOrder?.po_vehicle?.variant?.uuid : tempSurveyData?.survey?.vehicle?.variant?.uuid,
                    vehicleVariantCode: (tempSurveyData?.purchaseOrder?.po_vehicle?.variant?.code) ? tempSurveyData?.purchaseOrder?.po_vehicle?.variant?.code : tempSurveyData?.survey?.vehicle?.variant?.code,
                    vehicleVariantName: (tempSurveyData?.purchaseOrder?.po_vehicle?.variant?.name) ? tempSurveyData?.purchaseOrder?.po_vehicle?.variant?.name : tempSurveyData?.survey?.vehicle?.variant?.name,
                    vehicleColorCode: (tempSurveyData?.purchaseOrder?.po_vehicle?.color_code) ? tempSurveyData?.purchaseOrder?.po_vehicle?.color_code : tempSurveyData?.survey?.vehicle?.color_code,
                    vehicleColorName: (tempSurveyData?.purchaseOrder?.po_vehicle?.color) ? tempSurveyData?.purchaseOrder?.po_vehicle?.color : tempSurveyData?.survey?.vehicle?.color,
                });
            }, 1000);
        } catch (error: any) {
            this.setState({
                offerNotFount: true,
                poNotFound: false,
                surveyData: {},
            });
        }

        try {
            const listCityGroup = await trimitraCatalogServices.getCityGroup({});
            this.setState({
                listCityGroup: listCityGroup.data.data
            });
        } catch (e) {
            console.log(e);
        }

        setTimeout(async () => {
            await this.promisedSetState({
                fetchData: false,
            });
            this.simulateTotal();
        }, 1000);
    }

    simulateTotal = async () => {
        const tempDp = this.state.surveyData?.purchaseOrder?.pricing_scheme?.down_payment ?? 0;
        const tempPromoCode = this.state.promoCode;
        let tempPromoAmount = 0;
        let tempTotalAmount = 0;
        let tempYear = "";

        this.setState({
            fetchSimulationTotal: true,
        });

        if (tempPromoCode) {
            try {
                const response: any = await promoServices.getInfoDetailPromo({
                    promoCode: tempPromoCode,
                });
                tempPromoAmount = response?.data?.data?.discount_value ?? 0;
                tempYear = response?.data?.data?.vehicle_year ?? "Semua Tahun";

            } catch (e) {
                console.log(e);
                tempPromoAmount = 0;
                tempYear = "";
            }
        }

        tempTotalAmount = tempDp - tempPromoAmount;

        this.setState({
            fetchSimulationTotal: false,
            tempSimulationDiscountPromo: tempPromoAmount,
            tempSimulationTotal: tempTotalAmount,
            tempSimulationYearPromo: tempYear
        });
    }

    sendPushOffer = async () => {
        if (!this.state.areaCode) {
            Modal.error({
                title: 'Proses Gagal',
                content: "silakan pilih area."
            });
            return false;
        }

        const initPromo = this.state.promoInitCode;
        let promoEvent = null;
        if (this.state.promoCode !== "") {
            if (initPromo?.toUpperCase()?.trim() !== this.state.promoCode?.toUpperCase()?.trim()) {
                promoEvent = "push_offer";
            }
        }

        const tempVehicleBrandUuid = (this.state.surveyData?.purchaseOrder?.po_vehicle?.brand?.uuid) ? this.state.surveyData?.purchaseOrder?.po_vehicle?.brand?.uuid : this.state.surveyData?.survey?.vehicle?.brand?.uuid;
        const tempVehicleBrandName = (this.state.surveyData?.purchaseOrder?.po_vehicle?.brand?.name) ? this.state.surveyData?.purchaseOrder?.po_vehicle?.brand?.name : this.state.surveyData?.survey?.vehicle?.brand?.name;
        const tempVehicleModelUuid = (this.state.surveyData?.purchaseOrder?.po_vehicle?.model?.uuid) ? this.state.surveyData?.purchaseOrder?.po_vehicle?.model?.uuid : this.state.surveyData?.survey?.vehicle?.model?.uuid;
        const tempVehicleModelName = (this.state.surveyData?.purchaseOrder?.po_vehicle?.model?.name) ? this.state.surveyData?.purchaseOrder?.po_vehicle?.model?.name : this.state.surveyData?.survey?.vehicle?.model?.name;
        const tempVehicleVariantUuid = (this.state.surveyData?.purchaseOrder?.po_vehicle?.variant?.uuid) ? this.state.surveyData?.purchaseOrder?.po_vehicle?.variant?.uuid : this.state.surveyData?.survey?.vehicle?.variant?.uuid;
        const tempVehicleVariantCode = (this.state.surveyData?.purchaseOrder?.po_vehicle?.variant?.code) ? this.state.surveyData?.purchaseOrder?.po_vehicle?.variant?.code : this.state.surveyData?.survey?.vehicle?.variant?.code;
        const tempVehicleVariantName = (this.state.surveyData?.purchaseOrder?.po_vehicle?.variant?.name) ? this.state.surveyData?.purchaseOrder?.po_vehicle?.variant?.name : this.state.surveyData?.survey?.vehicle?.variant?.name;
        const tempVehicleColorCode = (this.state.surveyData?.purchaseOrder?.po_vehicle?.color_code) ? this.state.surveyData?.purchaseOrder?.po_vehicle?.color_code : this.state.surveyData?.survey?.vehicle?.color_code;
        const tempVehicleColorName = (this.state.surveyData?.purchaseOrder?.po_vehicle?.color) ? this.state.surveyData?.purchaseOrder?.po_vehicle?.color : this.state.surveyData?.survey?.vehicle?.color;

        this.setState({
            freezeStatus: true,
            successPage: false,
        });

        const dataOffer = {
            company: "amarta",
            amarta_vip_uid: this.state.amartaVipUid,
            amarta_vip_type: this.state.amartaVipType,
            source: "b2b-pushamartahonda",
            purchase_method: "credit",
            offer_code: this.state.offerCode,
            area: this.state.areaCode,
            agent_code: this.state.userCode,
            agent_name: this.state.userName,
            survey_created_time: this.state.surveyData?.survey?.created_at?.replace('T', ' ').substring(0, 19),
            take_vehicle_in_dealer: this.state.surveyData?.survey?.take_vehicle_in_dealer ?? false,
            phone_number_owner: (this.state.surveyData?.purchaseOrder?.owner_phone_number) ? this.state.surveyData?.purchaseOrder?.owner_phone_number : this.state.surveyData?.survey?.phone_number_owner,
            phone_number_wa_available_owner: this.state.surveyData?.survey?.phone_number_owner_is_whatsapp_active ?? false,
            phone_number_alternative_owner: "",
            phone_number_guarantor: (this.state.surveyData?.purchaseOrder?.guarantor_phone_number) ? this.state.surveyData?.purchaseOrder?.guarantor_phone_number : this.state.surveyData?.survey?.phone_number_guarantor,
            phone_number_wa_available_guarantor: false,
            phone_number_order_maker: (this.state.surveyData?.purchaseOrder?.guarantor_phone_number) ? this.state.surveyData?.purchaseOrder?.guarantor_phone_number : this.state.surveyData?.survey?.phone_number_guarantor,
            phone_number_wa_available_order_maker: false,
            phone_number_alternative_order_maker: "",
            promo_codes: (this.state.promoCode) ? [this.state.promoCode] : [],
            notes: this.state.notes,
            offer_broker: true, // have agent
            indent_code: "push",
            id_card_owner: (this.state.surveyData?.purchaseOrder?.id_card?.full_name) ? this.state.surveyData?.purchaseOrder?.id_card : this.state.surveyData?.survey?.owner_id_card,
            id_card_order_maker: (this.state.surveyData?.purchaseOrder?.order_maker_id_card?.full_name) ? this.state.surveyData?.purchaseOrder?.order_maker_id_card : this.state.surveyData?.survey?.order_make_id_card,
            id_card_guarantor: (this.state.surveyData?.purchaseOrder?.guarantor_id_card?.full_name) ? this.state.surveyData?.purchaseOrder?.guarantor_id_card : this.state.surveyData?.survey?.guarantor_id_card,
            family_register_owner: (this.state.surveyData?.purchaseOrder?.family_register?.family_register_number) ? this.state.surveyData?.purchaseOrder?.family_register : this.state.surveyData?.creditForm?.family_register_owner,
            address_owner: {
                full_address: this.state.surveyData?.survey?.owner_address?.full_address ?? "",
                province_name: this.state.surveyData?.survey?.owner_address?.province?.name ?? "",
                province_code: this.state.surveyData?.survey?.owner_address?.province?.code ?? "",
                city_name: this.state.surveyData?.survey?.owner_address?.city?.name ?? "",
                city_code: this.state.surveyData?.survey?.owner_address?.city?.code ?? "",
                district_name: this.state.surveyData?.survey?.owner_address?.district?.name ?? "",
                district_code: this.state.surveyData?.survey?.owner_address?.district?.code ?? "",
                sub_district_name: this.state.surveyData?.survey?.owner_address?.sub_district?.name ?? "",
                sub_district_code: this.state.surveyData?.survey?.owner_address?.sub_district?.code ?? "",
                zip_code: this.state.surveyData?.survey?.owner_address?.zip_code ?? "",
                hamlet: this.state.surveyData?.survey?.owner_address?.hamlet ?? "",
                neighbourhood: this.state.surveyData?.survey?.owner_address?.neighbourhood ?? "",
            },
            address_order_maker: {
                full_address: this.state.surveyData?.survey?.order_maker_address?.full_address ?? "",
                province_name: this.state.surveyData?.survey?.order_maker_address?.province?.name ?? "",
                province_code: this.state.surveyData?.survey?.order_maker_address?.province?.code ?? "",
                city_name: this.state.surveyData?.survey?.order_maker_address?.city?.name ?? "",
                city_code: this.state.surveyData?.survey?.order_maker_address?.city?.code ?? "",
                district_name: this.state.surveyData?.survey?.order_maker_address?.district?.name ?? "",
                district_code: this.state.surveyData?.survey?.order_maker_address?.district?.code ?? "",
                sub_district_name: this.state.surveyData?.survey?.order_maker_address?.sub_district?.name ?? "",
                sub_district_code: this.state.surveyData?.survey?.order_maker_address?.sub_district?.code ?? "",
                zip_code: this.state.surveyData?.survey?.order_maker_address?.zip_code ?? "",
                hamlet: this.state.surveyData?.survey?.order_maker_address?.hamlet ?? "",
                neighbourhood: this.state.surveyData?.survey?.order_maker_address?.neighbourhood ?? "",
            },
            address_guarantor: {
                full_address: this.state.surveyData?.survey?.order_maker_address?.full_address ?? "",
                province_name: this.state.surveyData?.survey?.order_maker_address?.province?.name ?? "",
                province_code: this.state.surveyData?.survey?.order_maker_address?.province?.code ?? "",
                city_name: this.state.surveyData?.survey?.order_maker_address?.city?.name ?? "",
                city_code: this.state.surveyData?.survey?.order_maker_address?.city?.code ?? "",
                district_name: this.state.surveyData?.survey?.order_maker_address?.district?.name ?? "",
                district_code: this.state.surveyData?.survey?.order_maker_address?.district?.code ?? "",
                sub_district_name: this.state.surveyData?.survey?.order_maker_address?.sub_district?.name ?? "",
                sub_district_code: this.state.surveyData?.survey?.order_maker_address?.sub_district?.code ?? "",
                zip_code: this.state.surveyData?.survey?.order_maker_address?.zip_code ?? "",
                hamlet: this.state.surveyData?.survey?.order_maker_address?.hamlet ?? "",
                neighbourhood: this.state.surveyData?.survey?.order_maker_address?.neighbourhood ?? "",
            },
            address_shipping: {
                full_address: this.state.surveyData?.survey?.shipping_address?.full_address ?? "",
                province_name: this.state.surveyData?.survey?.shipping_address?.province?.name ?? "",
                province_code: this.state.surveyData?.survey?.shipping_address?.province?.code ?? "",
                city_name: this.state.surveyData?.survey?.shipping_address?.city?.name ?? "",
                city_code: this.state.surveyData?.survey?.shipping_address?.city?.code ?? "",
                district_name: this.state.surveyData?.survey?.shipping_address?.district?.name ?? "",
                district_code: this.state.surveyData?.survey?.shipping_address?.district?.code ?? "",
                sub_district_name: this.state.surveyData?.survey?.shipping_address?.sub_district?.name ?? "",
                sub_district_code: this.state.surveyData?.survey?.shipping_address?.sub_district?.code ?? "",
                zip_code: this.state.surveyData?.survey?.shipping_address?.zip_code ?? "",
                hamlet: this.state.surveyData?.survey?.shipping_address?.hamlet ?? "",
                neighbourhood: this.state.surveyData?.survey?.shipping_address?.neighbourhood ?? "",
            },
            vehicle: {
                brand_uuid: (this.state.vehicleBrandUuid) ? this.state.vehicleBrandUuid : tempVehicleBrandUuid,
                brand_name: (this.state.vehicleBrandName) ? this.state.vehicleBrandName : tempVehicleBrandName,
                model_uuid: (this.state.vehicleModelUuid) ? this.state.vehicleModelUuid : tempVehicleModelUuid,
                model_name: (this.state.vehicleModelName) ? this.state.vehicleModelName : tempVehicleModelName,
                variant_uuid: (this.state.vehicleVariantUuid) ? this.state.vehicleVariantUuid : tempVehicleVariantUuid,
                variant_code: (this.state.vehicleVariantCode) ? this.state.vehicleVariantCode : tempVehicleVariantCode,
                variant_name: (this.state.vehicleVariantName) ? this.state.vehicleVariantName : tempVehicleVariantName,
                color_code: (this.state.vehicleColorCode) ? this.state.vehicleColorCode : tempVehicleColorCode,
                color: (this.state.vehicleColorName) ? this.state.vehicleColorName : tempVehicleColorName,
                alternative_color: {
                    code: (this.state.surveyData?.purchaseOrder?.po_vehicle?.alternative_color?.code) ? this.state.surveyData?.purchaseOrder?.po_vehicle?.alternative_color?.code : this.state.surveyData?.survey?.vehicle?.alternative_color?.code,
                    name: (this.state.surveyData?.purchaseOrder?.po_vehicle?.alternative_color?.name) ? this.state.surveyData?.purchaseOrder?.po_vehicle?.alternative_color?.name : this.state.surveyData?.survey?.vehicle?.alternative_color?.name,
                }
            },
            document_ownership_full_name: (this.state.surveyData?.purchaseOrder?.po_vehicle?.document_ownership_full_name) ? this.state.surveyData?.purchaseOrder?.po_vehicle?.document_ownership_full_name : this.state.surveyData?.survey?.id_card?.full_name,

            credit: {
                otr: this.state.surveyData?.purchaseOrder?.pricing_scheme?.otr,
                dp_amount: this.state.surveyData?.purchaseOrder?.pricing_scheme?.down_payment,
                tenor: this.state.surveyData?.purchaseOrder?.pricing_scheme?.tenor,
                installment_amount: this.state.surveyData?.purchaseOrder?.pricing_scheme?.installment,
                finco_code: this.state.surveyData?.purchaseOrder?.leasing_company?.code,
                finco_branch: "",
                finco_name: this.state.surveyData?.purchaseOrder?.leasing_company?.name,
                finco_comission: 1,
                po_code: this.state.surveyData?.purchaseOrder?.poNumber,
                po_track_number: this.state.surveyData?.purchaseOrder?.track_number,
                po_time: this.state.surveyData?.purchaseOrder?.po_created_at?.replace('T', ' ').substring(0, 19),
                po_image: this.state.surveyData?.purchaseOrder?.poImage,
                po_phone_number: this.state.surveyData?.purchaseOrder?.phone_number,
                po_data: {},
                po_expire_policy: this.state.surveyData?.purchaseOrder?.po_expire_policy,
                po_expired_at: this.state.surveyData?.purchaseOrder?.po_expired_at,
                different_po_data: this.state.surveyData?.purchaseOrder?.different_po_data,
                credit_note: "",
                promo_event: promoEvent,
            },
            // "stock_closing_date": "2022-09-21 00:00:00"
        }

        try {
            await offerApiServices.createOffer(
                dataOffer
            ).then(successData => {
                this.setState({
                    freezeStatus: false,
                    successPage: true,
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            this.setState({
                freezeStatus: false,
            });
        }
    }

    onVehicleModelChange = (data: any) => {
        this.setState({
            vehicleModelUuid: data.uuid,
            vehicleModelName: data.value,
            vehicleVariantUuid: null,
            vehicleVariantCode: null,
            vehicleVariantName: null,
            vehicleColorCode: null,
            vehicleColorName: null,
        });
    }

    onVehicleVariantChange = (data: any) => {
        this.setState({
            vehicleVariantUuid: data.uuid,
            vehicleVariantCode: data.value,
            vehicleVariantName: data.variant_name,
            vehicleColorCode: null,
            vehicleColorName: null,
        });
    }

    onVehicleVariantColorChange = (data: any) => {
        console.log(data);
        this.setState({
            vehicleColorCode: data.key,
            vehicleColorName: data.children,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Row style={{backgroundColor: '#fff', paddingBottom: 5, borderBottom: "#f0f2f5 2px solid"}}>
                        <Col xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <MyProfileLoginHeader title={"Push Offer"}/>
                        </Col>
                    </Row>
                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={{minHeight: '77vh', backgroundColor: "#fff", padding: 20}}>


                            {this.state.noAccessPage
                                ? <React.Fragment>
                                    <h3>No Access</h3>
                                </React.Fragment>
                                : <React.Fragment>
                                    <div style={this.state.fetchData ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                        <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                                    </div>

                                    <div style={(!this.state.fetchData && this.state.offerNotFount) ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                        <h3>Data Survey Tidak Ditemukan.</h3>
                                        <Empty description={""}/>
                                    </div>

                                    <div style={(!this.state.fetchData && this.state.poNotFound) ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                        <h3>Data PO pada Survey belum dilengkapi.</h3>
                                        <Empty description={""}/>
                                    </div>

                                    <div style={(this.state.successPage) ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                        <h3>Proses Success.</h3>
                                        <span>Data berhasil dipush kedalam offer.</span>
                                        <br/><br/>

                                        <a href={`/spk/${this.state.offerCode}`}>
                                            <Button type="primary" style={{fontSize: "95%", background: "#64b48f", color: "#fff", borderColor: "#64b48f"}}>Lihat Detail SPK</Button>
                                        </a>
                                    </div>

                                    {/*<div style={(!this.state.fetchData && !this.state.offerNotFount && !this.state.successPage) ? {} : {display: 'none'}}>*/}
                                    <div style={(!this.state.fetchData && !this.state.offerNotFount && !this.state.poNotFound && !this.state.successPage && !this.state.offerExist) ? {} : {display: 'none'}}>

                                        <Divider orientation="left" plain>Data Offer</Divider>
                                        <Row>
                                            <Col span={6}><Typography.Text type="secondary"> Nomor SPK: </Typography.Text></Col>
                                            <Col span={18}>{this.state.offerCode}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={6}><Typography.Text type="secondary"> Nomor PO: </Typography.Text></Col>
                                            <Col span={18}>{this.state.surveyData?.purchaseOrder?.poNumber}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={6}><Typography.Text type="secondary"> Nama Pemilik: </Typography.Text></Col>
                                            <Col span={18}>{this.state.surveyData?.purchaseOrder?.full_name ?? this.state.surveyData?.creditForm?.id_card_owner?.full_name}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={6}><Typography.Text type="secondary"> Kendaraan: </Typography.Text></Col>
                                            <Col span={18}> {(this.state.surveyData?.purchaseOrder?.po_vehicle?.variant?.name) ? (this.state.surveyData?.purchaseOrder?.po_vehicle?.variant?.name + " - " + this.state.surveyData?.purchaseOrder?.po_vehicle?.color) : (this.state.surveyData?.creditForm?.vehicle?.variant_name)}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={6}><Typography.Text type="secondary"> OTR: </Typography.Text></Col>
                                            <Col span={18}>{(this.state.surveyData?.purchaseOrder?.pricing_scheme?.otr) ? currencyFormat(this.state.surveyData?.purchaseOrder?.pricing_scheme?.otr) : currencyFormat(this.state.surveyData?.creditForm?.vehicle?.price)}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={6}><Typography.Text type="secondary"> Skema Kredit: </Typography.Text></Col>
                                            <Col
                                                span={18}> {(this.state.surveyData?.purchaseOrder?.pricing_scheme?.down_payment) ? ("DP: " + currencyFormat(this.state.surveyData?.purchaseOrder?.pricing_scheme?.down_payment) + " | " + this.state.surveyData?.purchaseOrder?.pricing_scheme?.tenor + " @ " + currencyFormat(this.state.surveyData?.purchaseOrder?.pricing_scheme?.installment)) : ("DP: " + currencyFormat(this.state.surveyData?.creditForm?.credit?.dp_amount) + " | " + this.state.surveyData?.creditForm?.credit?.tenor + " @ " + currencyFormat(this.state.surveyData?.creditForm?.credit?.installment_amount))}</Col>
                                        </Row>

                                        <Divider/>

                                        <Row>
                                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}}>
                                                <Form.Item>
                                                    <span className={`text-small-grey`}>Area</span>
                                                    <Select
                                                        onChange={value => this.onFieldChange("areaCode", value)}
                                                        value={this.state.areaCode}
                                                        style={{width: '100%'}}
                                                        placeholder={`Pilih Area`}
                                                    >
                                                        {this.state.listCityGroup.map((item: any, i: number) =>
                                                            <Select.Option key={i} value={item?.city_group}>{item?.city_group}</Select.Option>
                                                        )}
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item>
                                                    <span className={`text-small-grey`}>Model Kendaraan</span>
                                                    <VehicleModelSelect onVehicleModelChangeProps={this.onVehicleModelChange} value={this.state.vehicleModelName} brandCode={this.state.vehicleBrandName} category={"motorcycle"} time={Math.floor(+new Date() / 1000)}/>
                                                </Form.Item>

                                                <Form.Item>
                                                    <span className={`text-small-grey`}>Variant Kendaraan</span>
                                                    <VehicleVariantSelect onVehicleVariantChangeProps={this.onVehicleVariantChange} brandCode={this.state.vehicleBrandName} modelCode={this.state.vehicleModelName} value={this.state.vehicleVariantCode} time={Math.floor(+new Date() / 1000)}/>
                                                </Form.Item>

                                                <Form.Item>
                                                    <span className={`text-small-grey`}>Warna Kendaraan</span>
                                                    <VehicleVariantColorSelect onVehicleVariantColorChangeProps={this.onVehicleVariantColorChange} brandCode={this.state.vehicleBrandName} modelCode={this.state.vehicleModelName} variantCode={this.state.vehicleVariantCode} value={this.state.vehicleColorCode} time={Math.floor(+new Date() / 1000)}/>
                                                </Form.Item>

                                                <Form.Item>
                                                    <span className={`text-small-grey`}>Kode Promo</span>
                                                    <Input
                                                        value={this.state.promoCode}
                                                        placeholder="Kode Promo"
                                                        onChange={event => this.onFieldChange("promoCode", event.target.value)}
                                                        onBlur={this.simulateTotal}
                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                                    <span className={`text-small-grey`}>Catatan</span>
                                                    <Input
                                                        value={this.state.notes}
                                                        placeholder="Catatan"
                                                        onChange={event => this.onFieldChange("notes", event.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col style={{padding: 20}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}}>
                                                <Divider orientation="left" plain><span className={`text-small-grey`}>Simulasi Total Tagihan</span></Divider>
                                                <Button loading={this.state.fetchSimulationTotal} style={{background: "#fff", color: "#64b48f", borderColor: "#64b48f"}} onClick={this.simulateTotal}>Estimasi Tagihan</Button>
                                                <br/>
                                                <br/>

                                                <div style={this.state.fetchSimulationTotal ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                                                </div>
                                                <div style={!this.state.fetchSimulationTotal ? {} : {display: 'none'}}>
                                                    <Row>
                                                        <Col span={8}><span style={{color: "gray", fontSize: '90%'}}>Tagihan DP :</span></Col>
                                                        <Col span={16}>
                                                            <div style={{float: "right"}}>{currencyFormat(this.state.surveyData?.purchaseOrder?.pricing_scheme?.down_payment)}</div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={8}><span style={{color: "gray", fontSize: '90%'}}>Discount Promo :</span></Col>
                                                        <Col span={16}>
                                                            <div style={{float: "right"}}>{currencyFormat(this.state.tempSimulationDiscountPromo)}</div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={8}><span style={{color: "gray", fontSize: '90%'}}>Tahun Kendaraan :</span></Col>
                                                        <Col span={16}>
                                                            <div style={{float: "right"}}>{this.state.tempSimulationYearPromo}</div>
                                                        </Col>
                                                    </Row>
                                                    <Divider/>
                                                    <Row>
                                                        <Col span={8}><span style={{color: "gray", fontSize: '90%'}}>Total Tagihan :</span></Col>
                                                        <Col span={16}>
                                                            <div style={{float: "right"}}>{currencyFormat(this.state.tempSimulationTotal)}</div>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </Col>
                                        </Row>

                                        <Button onClick={this.sendPushOffer} style={{background: "#64b48f", color: "#fff", borderColor: "#64b48f"}}> Push Offer </Button>
                                    </div>
                                </React.Fragment>
                            }


                        </div>
                    </Col>
                    <FooterSection areaCode={'bandung'} version={appVersion} lite={true}/>
                </Layout>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(Spk);
