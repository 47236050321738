import React, {Component} from "react";
import {Buffer} from "buffer";
import Cookies from 'universal-cookie';
import currencyFormat from "../../helper/currencyFormat";
import {ExpressStates} from "./types/ExpressTypes";
import withAuth from "../../hoc/withAuth";
import './Express.css';
import Layout from "antd/lib/layout";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";
import {Divider, Switch} from "antd";
import Spin from "antd/lib/spin";
import {offerApiServices} from "../../services/offer/OfferApiService";
import MyProfileLoginHeader from "../../components/layouts/MyProfileLoginHeader";
import FooterSection from "../../components/footer/FooterSection";
import {appVersion} from "../../config/dataConfig/appConfig";
import UploadIdCardImage from "../../components/image/UploadIdCardImage";
import Checkbox from "antd/lib/checkbox";
import {promoServices} from "../../services/promo/PromoService";

const randomString: any = Array.from({length: 16}, () => Math.floor(Math.random() * 10)).join('');

class Express extends Component<any, ExpressStates> {
    private readonly initState!: ExpressStates;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        document.title = `Amartahonda | Express`;
        this.initState = {
            listFinco: [],
            areaCode: "BANDUNG",
            poCode: "",
            promoCode: "",
            notes: "",
            offerCode: "",
            commandType: true,
            freezeStatus: false,
            userCode: Buffer.from(this.cookies.get('_amh-uc'), 'base64').toString() ?? "",
            userName: Buffer.from(this.cookies.get('_amh-n'), 'base64').toString() ?? "",
            amartaVipUid: Buffer.from(this.cookies.get('_amh-ui'), 'base64').toString() ?? "",
            amartaVipType: Buffer.from(this.cookies.get('_amh-ut'), 'base64').toString() ?? "",
            sameData: false,
            imageIdCardOwner: "",
            imageIdCardOrderMaker: "",

            showDetailPromo: false,
            fetchSimulationTotal: false,
            tempSimulationDiscountPromo: 0,
            tempSimulationYearPromo: "",
            tempSimulationTotal: 0,
        }
        this.state = {...this.initState}
    }

    onFieldChange = <T extends keyof Pick<any, "commandType" | "offerCode" | "notes" | "promoCode" | "poCode" | "sameData">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "commandType":
                currentState.commandType = value;
                break;
            case "offerCode":
                currentState.offerCode = value;
                break;
            case "poCode":
                currentState.poCode = value;
                break;
            case "promoCode":
                currentState.promoCode = value;
                currentState.showDetailPromo = false;
                break;
            case "notes":
                currentState.notes = value;
                break;
            case "sameData":
                currentState.sameData = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    sendDataOffer = async () => {
        if (this.state.sameData) {
            await this.promisedSetState({
                imageIdCardOrderMaker: this.state.imageIdCardOwner,
            });
        }

        if (!this.state.userCode || this.state.userCode === "NONE") {
            Modal.error({
                title: 'Proses Gagal',
                content: "Anda belum memiliki kode agen, silakan hubungi admin."
            });
            return false;
        }

        if (!this.state.imageIdCardOwner || !this.state.imageIdCardOrderMaker) {
            Modal.error({
                title: 'Proses Gagal',
                content: "Mohon Lengkapi Image KTP"
            });
            return false;
        }

        if (this.state.commandType) {
            if (!this.state.offerCode) {
                Modal.error({
                    title: 'Proses Gagal',
                    content: "Mohon Lengkapi Data"
                });
                return false;
            }

            const dataOffer = {
                company: "amarta",
                amarta_vip_uid: this.state.amartaVipUid,
                offer_code: this.state.offerCode,
                agent_code: this.state.userCode,
                promo_code: this.state.promoCode,
                notes: this.state.notes,
                amarta_vip_type: this.state.amartaVipType,
                agent_name: this.state.userName,
                promo_event: "acquisition_offer",
                id_card_owner: {
                    id_card_image: this.state.imageIdCardOwner
                },
                id_card_order_maker: {
                    id_card_image: this.state.imageIdCardOrderMaker
                },
                id_card_guarantor: {
                    id_card_image: this.state.imageIdCardOrderMaker
                }
            };

            await this.promisedSetState({
                freezeStatus: true
            });

            try {
                await offerApiServices.acquisitionOffer(
                    dataOffer
                ).then(successData => {
                    window.location.href = `/spk/${successData.data.data.offer_code}`;
                });
            } catch (e) {
                Modal.error({
                    title: 'Proses Gagal',
                    content: 'Server Menolak Inputan Anda :' + e
                });
                this.setState({
                    freezeStatus: false,
                });
            }
        } else {
            alert('akuisisi e-po sudah tidak digunakan');
            return;
        }
    }

    onImageUpdate = async (data: any) => {
        if (data.id === "owner") {
            await this.promisedSetState({
                imageIdCardOwner: data.image_url,
            });

            if (this.state.sameData) {
                await this.promisedSetState({
                    imageIdCardOrderMaker: data.image_url,
                });
            }
        }
        if (data.id === "ordermaker") {
            await this.promisedSetState({
                imageIdCardOrderMaker: data.image_url,
            });
        }
    }

    simulatePromo = async () => {
        const tempPromoCode = this.state.promoCode?.trim();
        let tempPromoAmount = 0;
        let tempTotalAmount = 0;
        let tempYear = "";

        if (tempPromoCode) {
            this.setState({
                fetchSimulationTotal: true,
                showDetailPromo: false,
            });

            if (tempPromoCode) {
                try {
                    const response: any = await promoServices.getInfoDetailPromo({
                        promoCode: tempPromoCode,
                    });
                    tempPromoAmount = response?.data?.data?.discount_value ?? 0;
                    tempYear = response?.data?.data?.vehicle_year ?? "Semua Tahun";

                } catch (e) {
                    console.log(e);
                    tempPromoAmount = 0;
                    tempYear = "";
                }
            }

            // tempTotalAmount = tempDp - tempPromoAmount;

            this.setState({
                fetchSimulationTotal: false,
                showDetailPromo: true,
                tempSimulationDiscountPromo: tempPromoAmount,
                tempSimulationTotal: tempTotalAmount,
                tempSimulationYearPromo: tempYear
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <Layout className="layout">
                <Row style={{backgroundColor: '#fff', paddingBottom: 5, borderBottom: "#f0f2f5 2px solid"}}>
                    <Col xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <MyProfileLoginHeader title={"Express"}/>
                    </Col>
                </Row>

                <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    <div style={{backgroundColor: '#fff', padding: '4.5%', paddingTop: '2%'}}>
                        <div>
                            <div style={{textAlign: "center"}}>
                                <img src={'assets/img/favicon.png'} alt={`amartahonda-logo`}/>
                            </div>
                            <div className={`express-title`}>Amarta Express</div>
                            <div style={{display: "none"}} className={`express-description`}>
                                <Switch checked={this.state.commandType} onChange={value => this.onFieldChange("commandType", value)} checkedChildren=" Akuisisi Nomor Offer " unCheckedChildren=" Buat Order / Akuisisi E-PO "/>
                            </div>

                            <Form layout="vertical" className={`track-form`}>
                                <Form.Item style={(this.state.commandType) ? {display: 'none'} : {}}>
                                    <div className={`text-small-grey`}>Nomor PO</div>
                                    <Input
                                        value={this.state.poCode}
                                        placeholder="Masukan Kode / Nomor PO"
                                        onChange={event => this.onFieldChange("poCode", event.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item style={(!this.state.commandType) ? {display: 'none'} : {}}>
                                    <div className={`text-small-grey`}>Kode / Nomor Offer</div>
                                    <Input
                                        value={this.state.offerCode}
                                        placeholder="Masukan Kode / Nomor Offer"
                                        onChange={event => this.onFieldChange("offerCode", event.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <div className={`text-small-grey`}>Kode Promo</div>
                                    <Input
                                        value={this.state.promoCode}
                                        placeholder="Kode Promo"
                                        onChange={event => this.onFieldChange("promoCode", event.target.value)}
                                        onBlur={this.simulatePromo}
                                    />
                                    <Row style={(!this.state.showDetailPromo) ? {display: 'none'} : {marginTop: 20}}>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{paddingRight: 10}}>
                                            <div style={{border: 1, borderColor: "grey", width: "100%"}}>
                                                <Col span={24}>
                                                    <i style={{color: "grey", fontSize: "85%"}}>Kode Promo:</i><br/>
                                                    <span>{this.state.promoCode}</span>
                                                </Col>
                                                <Col span={24} style={{marginTop: 8}}>
                                                    <i style={{color: "grey", fontSize: "85%"}}>Discount Promo:</i><br/>
                                                    <span>{currencyFormat(this.state.tempSimulationDiscountPromo)}</span>
                                                </Col>
                                                <Col span={24} style={{marginTop: 8}}>
                                                    <i style={{color: "grey", fontSize: "85%"}}>Tahun Kendaraan:</i><br/>
                                                    <span>{this.state.tempSimulationYearPromo}</span>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item>
                                    <div className={`text-small-grey`}>Catatan</div>
                                    <Input
                                        value={this.state.notes}
                                        placeholder="Catatan / warna kendaraan"
                                        onChange={event => this.onFieldChange("notes", event.target.value)}
                                    />
                                </Form.Item>

                                <Divider/>

                                <Form.Item>
                                    <Checkbox onChange={e => this.onFieldChange("sameData", e.target.checked)} checked={this.state.sameData}>
                                        <i className={`text-small-grey-nopadding`}>KTP pemilik dan pemohon sama</i>
                                    </Checkbox>
                                </Form.Item>

                                <Row>
                                    <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{paddingRight: 10}}>
                                        <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'owner'} title={`KTP Pemilik`} number={randomString} name={``} hideInfo={true}/>
                                    </Col>
                                    <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={(this.state.sameData) ? {display: 'none'} : {paddingRight: 10}}>
                                        <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'ordermaker'} title={`KTP Pemohon`} number={randomString} name={``} hideInfo={true}/>
                                    </Col>
                                </Row>

                                <Button onClick={this.sendDataOffer} style={{marginTop: 20, background: "#64b48f", color: "#fff", borderColor: "#64b48f"}}> {(this.state.commandType) ? "Akuisisi Offer" : "Buat Order / Akuisisi E-PO"} </Button>
                            </Form>
                        </div>
                    </div>
                </Col>
                <FooterSection areaCode={'bandung'} version={appVersion} lite={true}/>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>
            </Layout>
        );
    }
}

export default withAuth(Express);