import React, {Component} from "react"
import currencyFormat from "../../helper/currencyFormat"
import {AdiraExpressStates} from "./types/AdiraExpressTypes"
import {withRouter} from "../../hoc/withRouter";
import './Express.css'
import Col from "antd/lib/col"
import Button from "antd/lib/button"
import Form from "antd/lib/form"
import Input from "antd/lib/input"
import Modal from "antd/lib/modal"
import Spin from "antd/lib/spin"
import FooterSection from "../../components/footer/FooterSection"
import {appVersion} from "../../config/dataConfig/appConfig"
import {offerApiServices} from "../../services/offer/OfferApiService"
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices"
import NormalHeader from "../../components/layouts/NormalHeader"
import Row from "antd/lib/row"
import Select from "antd/lib/select"
import UploadIdCardImage from "../../components/image/UploadIdCardImage";

const randomString: any = Array.from({length: 16}, () => Math.floor(Math.random() * 10)).join('');

class Ad1xExpress extends Component<any, AdiraExpressStates> {
    private readonly initState!: AdiraExpressStates

    constructor(props: any) {
        super(props)
        document.title = `Amartahonda | Adira - Express`
        this.initState = {
            isDesktop: false,
            listFinco: [],
            areaCode: "BANDUNG",
            poCode: "",
            promoCode: "",
            notes: "",
            applicationCode: "",
            commandType: true,
            freezeStatus: false,
            modalConfirm: false,
            userCode: "",
            userName: "",
            amartaVipUid: "",
            amartaVipType: "",
            dataDetailOrder: {},
            dataDetailVariant: {},
            listColor: [],
            choseColorCode: null,
            agentCode: "",
            agentName: "",
            familyCardNumber: "",
            imageIdCardOwner: "",
            imageFamilyCard: "",
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            })
        } else {
            this.setState({
                isDesktop: true,
            })
        }
    }

    async componentDidMount() {
        window.addEventListener("resize", this.handleResize.bind(this))
        this.handleResize()
        await this.promisedSetState({
            amartaVipUid: "",
            amartaVipType: "",
            agentCode: "",
            agentName: "",
        });
    }

    onFieldChange = <T extends keyof Pick<any, "familyCardNumber" | "choseColorCode" | "commandType" | "applicationCode" | "notes" | "promoCode" | "poCode">>(target: T, value: any) => {
        const currentState: any = {...this.state}
        switch (target) {
            case "choseColorCode":
                currentState.choseColorCode = value
                break
            case "applicationCode":
                currentState.applicationCode = value
                break
            case "poCode":
                currentState.poCode = value
                break
            case "promoCode":
                currentState.promoCode = value
                break
            case "notes":
                currentState.notes = value
                break
            case "familyCardNumber":
                currentState.familyCardNumber = value?.replace(/[^0-9.]/g, '')
                break
        }

        this.setState({
            ...currentState,
        })
    }

    okModalConfirm = async () => {
        if (!this.state.choseColorCode) {
            Modal.error({title: 'Proses Gagal', content: "Pilih warna kendaraan."})
            return false
        }
        if (!this.state.familyCardNumber) {
            Modal.error({title: 'Proses Gagal', content: "lengkapi nomor KK."})
            return false
        }
        if (!this.state.imageIdCardOwner) {
            Modal.error({title: 'Proses Gagal', content: "lengkapi image KTP Pemilik."})
            return false
        }
        if (!this.state.imageFamilyCard) {
            Modal.error({title: 'Proses Gagal', content: "lengkapi image KK."})
            return false
        }

        await this.promisedSetState({
            freezeStatus: true,
            modalConfirm: false
        })

        const dataDetailOrder = {...this.state.dataDetailOrder}
        const dataDetailVariant = {...this.state.dataDetailVariant}
        const picked = this.state.listColor.find((o: { color_code: string }) => o.color_code === this.state.choseColorCode)
        dataDetailVariant.color_code = picked.color_code
        dataDetailVariant.color_name = picked.color_name
        dataDetailVariant.color = picked.color_name
        dataDetailOrder.vehicle = dataDetailVariant
        dataDetailOrder.area = this.state.areaCode
        dataDetailOrder.amarta_vip_uid = this.state.amartaVipUid
        dataDetailOrder.amarta_vip_type = this.state.amartaVipType
        dataDetailOrder.agent_code = this.state.agentCode
        dataDetailOrder.agent_name = this.state.agentName

        dataDetailOrder.id_card_owner.id_card_image = this.state.imageIdCardOwner
        dataDetailOrder.family_register_owner = {
            family_register_number: this.state.familyCardNumber,
            family_register_image: this.state.imageFamilyCard
        }

        if (this.state.notes) {
            dataDetailOrder.notes = this.state.notes
        }
        if (this.state.promoCode) {
            dataDetailOrder.promo_codes = [this.state.promoCode]
        }

        try {
            await offerApiServices.createOffer(
                dataDetailOrder
            ).then(async successData => {
                window.location.href = `/spk/${successData.data.data.offer_code}`
            })
        } catch (e) {
            Modal.error({title: 'Proses Gagal', content: 'Server Menolak Inputan Anda :' + e})
            this.setState({freezeStatus: false, modalConfirm: true})
        }
    }

    sendDataOffer = async () => {
        if (!this.state.applicationCode) {
            Modal.error({title: 'Proses Gagal', content: "Nomor Aplikasi dibutuhkan."})
            return false
        }

        await this.promisedSetState({
            freezeStatus: true
        })

        try {
            const leasingData = await offerApiServices.acquisitionEpoOffer({
                type: "FETCH_ADIRA_EXPRESS",
                company: "amarta",
                finco_code: "ADMF",
                area: this.state.areaCode,
                po_code: this.state.applicationCode
            })
            await this.promisedSetState({
                dataDetailOrder: leasingData.data.data
            })

            const variantCode = leasingData?.data?.data?.vehicle?.variant_code
            try {
                const dataVariant = await trimitraCatalogServices.getCatalogVariant({areaCode: this.state.areaCode, variantCode: variantCode})
                const variantZero = dataVariant?.data?.data[0]
                const listColor = []
                for (const item of dataVariant?.data?.data) {
                    listColor.push({
                        color_code: item.color_code,
                        color_name: item.color_name,
                        url_image: item.url_image,
                    })
                }
                this.promisedSetState({
                    freezeStatus: false,
                    modalConfirm: true,
                    dataDetailVariant: {
                        brand_name: "honda",
                        brand_uuid: "afce6884-fc4d-4f7a-b784-09d2a2f7b903",
                        model_name: variantZero?.model_name,
                        model_uuid: variantZero?.model_uuid,
                        variant_name: variantZero?.variant_name,
                        variant_code: variantZero?.variant_code,
                        variant_uuid: variantZero?.variant_uuid,
                        price: variantZero?.price,
                        image_url: variantZero?.url_image,
                        alternative_color: {
                            code: "SAME_AS_ORDER",
                            name: "warna sesuai pesanan"
                        }
                    },
                    listColor: listColor
                })
            } catch (e) {
                Modal.error({title: 'Proses Gagal', content: 'Variant Tidak ditemukan'})
                this.setState({freezeStatus: false})
            }


        } catch (e) {
            Modal.error({title: 'Proses Gagal', content: 'Server Menolak Inputan Anda :' + e})
            this.setState({freezeStatus: false})
        }
    }

    onAreaChange = async (data: any) => {
        await this.promisedSetState({
            areaCode: data.city_group,
        })
    }

    onImageUpdate = async (data: any) => {
        if (data.id === "owner") {
            await this.promisedSetState({
                imageIdCardOwner: data.image_url,
            });
        }
        if (data.id === "familycard") {
            await this.promisedSetState({
                imageFamilyCard: data.image_url,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve))

    render() {
        return (
            <React.Fragment>
                <NormalHeader areaCode={this.state.areaCode} onAreaChangeProps={this.onAreaChange} isDesktop={this.state.isDesktop}/>

                <Row className={`mt-20`} style={{backgroundColor: "#f8f8f8"}}>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col className={`p-10`} xxl={{span: 16}} xl={{span: 16}} md={{span: 16}} sm={{span: 24}} xs={{span: 24}}>
                                <div className={`detail-product-title`}>Adira Express - AmartaHonda {this.state.areaCode}</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row style={{minHeight: "77vh"}}>
                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={{backgroundColor: '#fff', padding: '4.5%', paddingTop: '2%'}}>
                            <div>
                                <div style={{textAlign: "center"}}>
                                    <img src={'assets/img/favicon.png'} alt={`amartahonda-logo`}/>
                                </div>
                                <div className={`express-title`}>Adira Express</div>
                                <Form layout="vertical" className={`track-form`}>
                                    <Form.Item>
                                        <div className={`text-small-grey`}>Nomor Aplikasi</div>
                                        <Input value={this.state.applicationCode} placeholder="Masukan Nomor Aplikasi" onChange={event => this.onFieldChange("applicationCode", event.target.value)}/>
                                    </Form.Item>
                                    <Button onClick={this.sendDataOffer} style={{marginTop: 20, background: "#64b48f", color: "#fff", borderColor: "#64b48f"}}> Lihat Order </Button>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
                <FooterSection areaCode={'bandung'} version={appVersion} lite={true}/>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>

                <Modal
                    open={this.state.modalConfirm}
                    style={{top: 2}}
                    title="Konfirmasi"
                    closeIcon={true}
                    footer={[
                        <Button key="1" type="primary" style={{marginTop: 20, background: "#64b48f", color: "#fff", borderColor: "#64b48f"}} onClick={this.okModalConfirm}>Buat Order</Button>,
                        <Button key="close" onClick={() => {
                            this.setState({modalConfirm: false})
                        }}>Cancel</Button>
                    ]}
                >
                    <Form layout={"vertical"}>
                        <Form.Item>
                            <Row>
                                <Col span={12}>
                                    <div>
                                        <i className={`small-text-grey`}>Nama Pemesan</i><br/>
                                        {this.state.dataDetailOrder?.id_card_owner?.full_name?.toUpperCase()}
                                    </div>
                                    <div>
                                        <i className={`small-text-grey`}>Nomor KTP</i><br/>
                                        {this.state.dataDetailOrder?.id_card_owner?.id_card_number}
                                    </div>
                                    <div>
                                        <i className={`small-text-grey`}>Nomor Telpon</i><br/>
                                        {this.state.dataDetailOrder?.phone_number_owner}
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div>
                                        <i className={`small-text-grey`}>Otr / DP</i><br/>
                                        {currencyFormat(this.state.dataDetailOrder?.credit?.otr)} / {currencyFormat(this.state.dataDetailOrder?.credit?.dp_amount)}
                                    </div>
                                    <div>
                                        <i className={`small-text-grey`}>Cicilan</i><br/>
                                        {currencyFormat(this.state.dataDetailOrder?.credit?.installment_amount)} x {currencyFormat(this.state.dataDetailOrder?.credit?.tenor)}
                                    </div>
                                    <div>
                                        <i className={`small-text-grey`}>Kendaraan</i><br/>
                                        {this.state.dataDetailVariant?.variant_name} - {this.state.dataDetailVariant?.variant_code}
                                    </div>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item>
                            <i className={`small-text-grey`}>Warna</i>
                            <Select
                                placeholder="Pilih Warna"
                                showSearch
                                style={{marginTop: 5}}
                                optionFilterProp="children"
                                onChange={value => this.onFieldChange("choseColorCode", value)}
                                value={this.props.choseColorCode}
                            >
                                {this.state.listColor.map((item: any, i: number) =>
                                    <Select.Option key={i} value={item?.color_code}>{item?.color_name}</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <i className={`small-text-grey`}>Kode Promo</i>
                            <Input value={this.state.promoCode} placeholder="Kode Promo" onChange={event => this.onFieldChange("promoCode", event.target.value)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`small-text-grey`}>Nomor Kartu Keluarga</i>
                            <Input value={this.state.familyCardNumber} placeholder="Nomor Kartu Keluarga" onChange={event => this.onFieldChange("familyCardNumber", event.target.value)}/>
                        </Form.Item>
                        <Row>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{paddingRight: 10}}>
                                <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'owner'} title={`KTP Pemilik`} number={randomString} name={``} hideInfo={true}/>
                            </Col>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{paddingRight: 10}}>
                                <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'familycard'} title={`KK`} number={randomString} name={``} hideInfo={true}/>
                            </Col>
                        </Row>
                        <Form.Item>
                            <i className={`small-text-grey`}>Catatan</i>
                            <Input value={this.state.notes} placeholder="Catatan / warna kendaraan" onChange={event => this.onFieldChange("notes", event.target.value)}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </React.Fragment>
        )
    }
}

export default withRouter(Ad1xExpress)