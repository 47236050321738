import React, {Component} from "react";
import {Link} from "react-router-dom";
import {UsedMokasStates} from "./types/UsedMokasTypes";
import {withRouter} from "../../hoc/withRouter";
import './UsedMokas.css'
import {Content} from "antd/es/layout/layout";
import {DivClassProduct} from "../../components/product/DivClassProduct";
import {CardProduct} from "../../components/product/CardProduct";
import CurrencyInput from "react-currency-input-field";
import dynamicSort from "../../helper/dynamicSort";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Drawer from "antd/lib/drawer";
import Input from "antd/lib/input";
import Layout from "antd/lib/layout";
import Row from "antd/lib/row";
import Select from "antd/lib/select";
import Slider from "antd/lib/slider";
import ControlOutlined from '@ant-design/icons/ControlOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import {trackLogServices} from "../../services/track/TrackLogService";

const marks = {
    20000000: '20jt',
    50000000: '50jt',
    75000000: '75jt',
}

class UsedMokas extends Component<any, UsedMokasStates> {
    private readonly initState!: UsedMokasStates;

    constructor(props: any) {
        super(props);
        document.title = "Amartahonda | Mokas"

        const {match} = this.props;
        const areaCode = match.params.area;

        this.initState = {
            areaCode: areaCode,
            newProductClass: "ALL",
            listNewProduct: [],
            listNewProductFiltered: [],
            showOfferCard: false,
            complateFetchVariant: false,
            visibleFilter: false,
            filterMinPrice: 10000000,
            filterMaxPrice: 100000000,
            filterSorting: "relevan",
        }
        this.state = {...this.initState}
    }

    onClassChange = async (data: string) => {
        await this.promisedSetState({
            newProductClass: data
        });

        await this.setupListNewProductFiltered();
    }

    setupListNewProductFiltered = async () => {
        let filteredData: any[] = [];
        const filterMinPrice = (this.state.filterMinPrice === 0) ? 0 : this.state.filterMinPrice;
        const filterMaxPrice = (this.state.filterMaxPrice === 0) ? 99999999999 : this.state.filterMaxPrice;
        const filterSorting = this.state.filterSorting;

        this.state.listNewProduct.forEach((el: any) => {
            if (this.state.newProductClass === 'ALL') {
                if (el.price > filterMinPrice && el.price < filterMaxPrice) {
                    filteredData.push(el);
                }
            } else if ((el.class === "scooter" || el.class === "scooter-high") && (this.state.newProductClass === 'MATIC')) {
                if (el.price > filterMinPrice && el.price < filterMaxPrice) {
                    filteredData.push(el);
                }
            } else if ((el.class === "cub") && (this.state.newProductClass === 'CUB')) {
                if (el.price > filterMinPrice && el.price < filterMaxPrice) {
                    filteredData.push(el);
                }
            } else if ((el.class === "sport" || el.class === "sport-low") && (this.state.newProductClass === 'SPORT')) {
                if (el.price > filterMinPrice && el.price < filterMaxPrice) {
                    filteredData.push(el);
                }
            }
        });

        if (filterSorting === 'minprice') {
            filteredData.sort(dynamicSort("price"));
        }
        if (filterSorting === 'maxprice') {
            filteredData.sort(dynamicSort("-price"));
        }

        await this.promisedSetState({
            listNewProductFiltered: filteredData,
            visibleFilter: false,
        });
    }

    showFilterDrawer = () => {
        this.setState({
            visibleFilter: true,
        })
    }

    closeFilterDrawer = () => {
        this.setState({
            visibleFilter: false,
        })
    }

    onChange = (value: any) => {
        console.log('onChange: ', value);
    }

    onChangeSlider = (value: any) => {
        this.setState({
            filterMinPrice: value[0],
            filterMaxPrice: value[1]
        });
    }

    onFieldChange = <T extends keyof Pick<any, "filterMinPrice" | "filterMaxPrice">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "filterMinPrice":
                currentState.filterMinPrice = value;
                break;
            case "filterMaxPrice":
                currentState.filterMaxPrice = value;
                break;
        }
        this.setState({
            ...currentState,
        });
    }

    onChangeFilter = (value: any, option: any) => {
        this.setState({
            filterSorting: value,
        });
    }

    async componentDidMount() {
        trimitraCatalogServices.getCatalogMokas({
            areaCode: 'bandung'
        }).then(async dataVariantHome => {
            await this.promisedSetState({
                listNewProduct: dataVariantHome.data.data
            });
            this.setupListNewProductFiltered();
        });

        await trackLogServices.sendTrackLog({
            eventName: `mokas page`,
            eventSourceUrl: window.location.href,
            customData: null
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <Layout className="layout-new-vehicle-variant">
                <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}}>
                    <div className={`variant-header-new-vehicle-variant`}>
                        <Row>
                            <Col span={4}><Link to="/"><LeftOutlined className={'hedaer2-back-new-vehicle-variant'}/></Link></Col>
                            <Col span={16}><Input onClick={this.showFilterDrawer} prefix={<SearchOutlined className="site-form-item-icon"/>} placeholder="mokas"/></Col>
                            <Col span={4}><ControlOutlined onClick={this.showFilterDrawer} className={`header2-control-new-vehicle-variant`}/></Col>
                        </Row>
                    </div>
                    <div className={`variant-header-new-vehicle-variant-border-bottom`}>
                        <DivClassProduct newProductClass={this.state.newProductClass} onClickClass={this.onClassChange}/>
                    </div>
                    <Content style={{backgroundColor: "white"}}>
                        <Row className={`home-row-chosen-product`}>
                            {
                                this.state.listNewProductFiltered.map((item: any, i: number) =>
                                    <Col span={12} key={i}>
                                        <CardProduct option={'MOKAS'} registration_year={item.registration_year} productImageUrl={item.url_image} product={item.license_plate} productArea={this.state.areaCode} productName={item.variant_name} productPrice={item.price} productStrikethroughtPrice={item.price_strikethrough}/>
                                    </Col>
                                )
                            }
                        </Row>

                    </Content>
                </Col>

                <Drawer
                    title="Filter"
                    placement="right"
                    open={this.state.visibleFilter}
                    onClose={this.closeFilterDrawer}
                >

                    <p>Harga Minimum</p>
                    <CurrencyInput
                        className={'ant-input'}
                        placeholder={'Harga Minimum'}
                        allowDecimals={false}
                        groupSeparator={'.'}
                        decimalSeparator={','}
                        prefix={'Rp. '}
                        value={this.state.filterMinPrice}
                        onValueChange={value => this.onFieldChange("filterMinPrice", value ? parseFloat(value) : 0)}
                    />
                    <br/><br/>

                    <p>Harga Maksimum</p>
                    <CurrencyInput
                        className={'ant-input'}
                        placeholder={'Harga Maksimum'}
                        allowDecimals={false}
                        groupSeparator={'.'}
                        decimalSeparator={','}
                        prefix={'Rp. '}
                        value={this.state.filterMaxPrice}
                        onValueChange={value => this.onFieldChange("filterMaxPrice", value ? parseFloat(value) : 0)}
                    />
                    <br/><br/>

                    <p>Range Harga</p>
                    <Slider
                        min={0}
                        max={150000000}
                        // step={200000}
                        marks={marks}
                        range defaultValue={[this.state.filterMinPrice, this.state.filterMaxPrice]}
                        value={[this.state.filterMinPrice, this.state.filterMaxPrice]}
                        onChange={this.onChangeSlider}
                    />
                    <br/>

                    <p>Urutkan Berdasarkan:</p>
                    <Select onChange={this.onChangeFilter} defaultValue={this.state.filterSorting} style={{width: '100%'}}>
                        <Select.Option value="relevan">Relevan</Select.Option>
                        <Select.Option value="minprice">Harga Terendah</Select.Option>
                        <Select.Option value="maxprice">Harga Tertinggi</Select.Option>
                    </Select>

                    <div className="custom-footer">
                        <Button onClick={this.setupListNewProductFiltered} type="primary" icon={<SearchOutlined/>}>
                            Terapkan Filter
                        </Button>
                    </div>

                </Drawer>
            </Layout>
        );
    }
}

export default withRouter(UsedMokas);
