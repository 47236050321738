import React, {Component} from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Image from "antd/lib/image";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Collapse from "antd/lib/collapse";
import CropIdCardImage from "./CropIdCardImage";
import {Upload} from "antd";
import {baseUrlCdnUpload} from "../../config/apiConfig/apiConfig";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import {cdnApiService} from "../../services/trimitraCdn/CdnApiService";
import Spin from "antd/lib/spin";
import Input from "antd/lib/input";

const {Panel} = Collapse;

class UploadMultiImage extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            imageUrl1: "",
            imageUrl2: "",
            imageUrl3: "",
            imageUrl4: "",
            caption1: "",
            caption2: "",
            caption3: "",
            caption4: "",
            imageState: 0,
            base64IdCardOwner: "",
            showModalUploadImage: false,
            showOldUploadRawImage: false,
            freezeStatus: false,
            imageCaption: "",
        }
        this.state = {...this.initState}
    }

    clickUpdateIdCardImage = (a: number) => {
        this.setState({
            imageCaption: "",
            showModalUploadImage: true,
            showOldUploadRawImage: false,
            imageState: a,
        });
    }

    saveOwnerIdCardData = async () => {
        if (!this.state.imageCaption) {
            Modal.error({title: 'Error', content: "Mohon lengkapi deksripsi gambar"});
            return;
        }

        if (this.state.base64IdCardOwner?.length < 10) {
            Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"});
            await this.promisedSetState({
                showOldUploadRawImage: true,
            });
            return;
        }

        const dataUpload = {
            id_image: (Math.random().toString(36).substring(2, 10)),
            document_type: "used_vehicle",
            image_data: this.state.base64IdCardOwner,
            source: "amartahonda.com",
            notes: "used vehicle"
        }

        await this.uploadImageAndUpdateState(dataUpload);
    }

    exportData = async () => {
        const finalData = [];
        if (this.state.imageUrl1) {
            finalData.push({
                image_url: this.state.imageUrl1,
                caption: this.state.caption1,
            });
        }
        if (this.state.imageUrl2) {
            finalData.push({
                image_url: this.state.imageUrl2,
                caption: this.state.caption2,
            });
        }
        if (this.state.imageUrl3) {
            finalData.push({
                image_url: this.state.imageUrl3,
                caption: this.state.caption3,
            });
        }
        if (this.state.imageUrl4) {
            finalData.push({
                image_url: this.state.imageUrl4,
                caption: this.state.caption4,
            });
        }

        this.props.onImageUpdateProps(finalData);
    }

    uploadImageAndUpdateState = async (dataUpload: any) => {
        await this.setState({
            freezeStatus: true,
        });

        try {
            await cdnApiService.uploadIdCardBase64(dataUpload).then(async successData => {
                if (this.state.imageState === 1) {
                    await this.promisedSetState({
                        imageUrl1: successData?.data?.data?.url_document,
                        caption1: this.state.imageCaption
                    });
                } else if (this.state.imageState === 2) {
                    await this.promisedSetState({
                        imageUrl2: successData?.data?.data?.url_document,
                        caption2: this.state.imageCaption
                    });
                } else if (this.state.imageState === 3) {
                    await this.promisedSetState({
                        imageUrl3: successData?.data?.data?.url_document,
                        caption3: this.state.imageCaption
                    });
                } else if (this.state.imageState === 4) {
                    await this.promisedSetState({
                        imageUrl4: successData?.data?.data?.url_document,
                        caption4: this.state.imageCaption
                    });
                }

                await this.promisedSetState({
                    freezeStatus: false,
                    showModalUploadImage: false,
                });

                await this.exportData();
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.setState({
                freezeStatus: false
            });
        }
    }

    onCropIdCardOwner = async (base64DataImagePng: any) => {
        await this.setState({
            base64IdCardOwner: base64DataImagePng,
        });
    }

    uploadImageChange = async (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document;
            this.setState({
                freezeStatus: false,
            });
            if (this.state.imageState === 1) {
                await this.promisedSetState({
                    imageUrl1: newImgLocation,
                    caption1: this.state.imageCaption
                });
            } else if (this.state.imageState === 2) {
                await this.promisedSetState({
                    imageUrl2: newImgLocation,
                    caption2: this.state.imageCaption
                });
            } else if (this.state.imageState === 3) {
                await this.promisedSetState({
                    imageUrl3: newImgLocation,
                    caption3: this.state.imageCaption
                });
            } else if (this.state.imageState === 4) {
                await this.promisedSetState({
                    imageUrl4: newImgLocation,
                    caption4: this.state.imageCaption
                });
            }

            await this.exportData();
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                freezeStatus: true,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                freezeStatus: false,
            });

            if (this.state.imageState === 1) {
                await this.promisedSetState({
                    imageUrl1: "",
                });
            } else if (this.state.imageState === 2) {
                await this.promisedSetState({
                    imageUrl2: "",
                });
            } else if (this.state.imageState === 3) {
                await this.promisedSetState({
                    imageUrl3: "",
                });
            } else if (this.state.imageState === 4) {
                await this.promisedSetState({
                    imageUrl4: "",
                });
            }
        }
    }

    onFieldChange = <T extends keyof Pick<any, "imageCaption">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "imageCaption":
                currentState.imageCaption = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xl={{span: 10}} lg={{span: 10}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 20}}>
                        {this.state.imageUrl1
                            ? <div style={{textAlign: "center", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.imageUrl1}/></div>
                            : <div onClick={() => this.clickUpdateIdCardImage(1)} style={{textAlign: "center", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                        }
                        <div onClick={() => this.clickUpdateIdCardImage(1)} className="text-small-green-nopadding" style={{textAlign: "center", fontWeight: 400}}>
                            {this.state.imageUrl1
                                ? <span>Update Gambar 1</span>
                                : <span>Lengkapi Gambar 1</span>
                            }
                        </div>
                    </Col>
                    <Col xl={{span: 10}} lg={{span: 10}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 20}}>
                        {this.state.imageUrl2
                            ? <div style={{textAlign: "center", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.imageUrl2}/></div>
                            : <div onClick={() => this.clickUpdateIdCardImage(2)} style={{textAlign: "center", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                        }
                        <div onClick={() => this.clickUpdateIdCardImage(2)} className="text-small-green-nopadding" style={{textAlign: "center", fontWeight: 400}}>
                            {this.state.imageUrl2
                                ? <span>Update Gambar 2</span>
                                : <span>Lengkapi Gambar 2</span>
                            }
                        </div>
                    </Col>
                    <Col xl={{span: 10}} lg={{span: 10}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 20}}>
                        {this.state.imageUrl3
                            ? <div style={{textAlign: "center", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.imageUrl3}/></div>
                            : <div onClick={() => this.clickUpdateIdCardImage(3)} style={{textAlign: "center", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                        }
                        <div onClick={() => this.clickUpdateIdCardImage(3)} className="text-small-green-nopadding" style={{textAlign: "center", fontWeight: 400}}>
                            {this.state.imageUrl3
                                ? <span>Update Gambar 3</span>
                                : <span>Lengkapi Gambar 3</span>
                            }
                        </div>
                    </Col>
                    <Col xl={{span: 10}} lg={{span: 10}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 20}}>
                        {this.state.imageUrl4
                            ? <div style={{textAlign: "center", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.imageUrl4}/></div>
                            : <div onClick={() => this.clickUpdateIdCardImage(4)} style={{textAlign: "center", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                        }
                        <div onClick={() => this.clickUpdateIdCardImage(4)} className="text-small-green-nopadding" style={{textAlign: "center", fontWeight: 400}}>
                            {this.state.imageUrl4
                                ? <span>Update Gambar 4</span>
                                : <span>Lengkapi Gambar 4</span>
                            }
                        </div>
                    </Col>
                </Row>

                <Modal
                    title="Lengkapi Data Kendaraan"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadImage}
                    onCancel={() => this.setState({showModalUploadImage: false})}
                    footer={[
                        <Button style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}} key={'saveCustomer'} type="primary" onClick={this.saveOwnerIdCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadImage: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical" style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64IdCardOwner) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", paddingLeft: 20}}>
                                    <li>Klik Tombol Upload Gambar Kendaraan</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item style={this.state.imageState === 1 ? {} : {display: 'none'}}>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardOwner}/>
                        </Form.Item>
                        <Form.Item style={this.state.imageState === 2 ? {} : {display: 'none'}}>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardOwner}/>
                        </Form.Item>
                        <Form.Item style={this.state.imageState === 3 ? {} : {display: 'none'}}>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardOwner}/>
                        </Form.Item>
                        <Form.Item style={this.state.imageState === 4 ? {} : {display: 'none'}}>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardOwner}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Deskripsi Gambar <b style={{color: "red"}}>*</b></i>
                            <Input
                                value={this.state.imageCaption}
                                placeholder="Deskripsi gambar"
                                onChange={event => this.onFieldChange("imageCaption", event.target.value)}
                            />
                        </Form.Item>
                    </Form>

                    <Form layout="vertical" style={this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Upload
                            method="POST"
                            action={baseUrlCdnUpload + "/upload-document/document-data"}
                            listType="picture"
                            maxCount={1}
                            data={{
                                document_name: (Math.random().toString(36).substring(2, 10)),
                                document_type: "used_vehicle",
                                source: "amartahonda.com",
                                notes: "used vehicle"
                            }}
                            name="image"
                            onChange={this.uploadImageChange}
                        > <Button icon={<UploadOutlined/>}>Upload Ulang Gambar Kendaraan</Button>
                        </Upload>
                    </Form>
                </Modal>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default UploadMultiImage;
