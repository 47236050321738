import React, {Component} from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import DoubleRightOutlined from "@ant-design/icons/DoubleRightOutlined";
import Image from "antd/lib/image";
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import Select from "antd/lib/select";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import ReCAPTCHA from "react-google-recaptcha";
import reformatPhoneNumber from "../../helper/reformatPhoneNumber";

class FindingRightProduct extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            showModalForm: false,
            greeting: "Kakak",
            name: "",
            phoneNumber: "",
            loading: false,
            captchaSuccess: false,
        }
        this.state = {...this.initState}
    }

    sendSaleRequest = async () => {
        if (this.state.phoneNumber.length < 8) {
            Modal.error({title: 'Error', content: 'Nomor telpon tidak valid'});
            return;
        }

        if (!this.state.greeting || !this.state.name || !this.state.phoneNumber) {
            Modal.error({title: 'Error', content: 'Mohon Lengakpi Data'});
            return;
        }

        await this.promisedSetState({
            loading: true,
        });

        const data = {
            greeting: this.state.greeting,
            name: this.state.name,
            phoneNumber: reformatPhoneNumber(this.state.phoneNumber),
            loading: this.state.loading,
        }

        console.log(data);
    }

    onFieldChange = <T extends keyof Pick<any, "greeting" | "name" | "phoneNumber">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "greeting":
                currentState.greeting = value;
                break;
            case "name":
                currentState.name = value;
                break;
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, '');
                break;
        }

        if (target === 'phoneNumber') {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== '08') {
                    currentState.phoneNumber = "";
                }
            }
        }

        this.setState({
            ...currentState,
        });
    }

    onCaptchaChange = () => {
        this.setState({
            captchaSuccess: true,
        });
    }

    onCaptchaExpire = () => {
        this.setState({
            captchaSuccess: false,
        });
    }

    onErrored = () => {
        this.setState({
            captchaSuccess: true,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div className={`card-home-find-motor`} style={{cursor: "pointer"}} onClick={() => {
                    console.log("ai under construct")
                    // this.setState({showModalForm: true, greeting: "Kakak", name: "", phoneNumber: "", loading: false})
                }}>
                    <Row className={`pad-five`}>
                        <Col xl={{span: 23}} lg={{span: 23}} md={{span: 20}} sm={{span: 20}} xs={{span: 20}}>
                            <Image width={'30'} style={{paddingLeft: 20, paddingRight: 20}} preview={false} src={'assets/img/icon/helmetsearchquiz.svg'} alt={`amartahonda-logo`}/>
                            <span className={`pad-find-motor`}>Bingung cari motor yang pas?</span>
                        </Col>
                        <Col xl={{span: 1}} lg={{span: 1}} md={{span: 1}} sm={{span: 1}} xs={{span: 1}}>
                            <p className={`pad-find-motor`}><DoubleRightOutlined/></p>
                        </Col>
                    </Row>
                </div>

                <Modal
                    title={"Form Amartahonda"}
                    open={this.state.showModalForm}
                    onCancel={() => {
                        this.setState({showModalForm: false})
                    }}
                    onOk={() => {
                        this.setState({showModalForm: false})
                    }}
                    footer={[
                        <Button style={!this.state.captchaSuccess ? {display: 'none'} : {backgroundColor: "#64b48f", borderColor: "#64b48f"}} onClick={this.sendSaleRequest} loading={this.state.loading} type={"primary"} key="send">Kirim Form</Button>,
                        <Button key="close" onClick={() => {
                            this.setState({showModalForm: false})
                        }}>Cancel</Button>
                    ]}
                >
                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Sapaan <b style={{color: "red"}}>*</b></i>
                        <Select
                            onChange={value => this.onFieldChange("greeting", value)}
                            value={this.state.greeting}
                            style={{width: '100%'}}
                            placeholder={`Jarak Tempuh / Odometer Kendaraan`}
                        >
                            <Select.Option value={"Kakak"}>Kakak</Select.Option>
                            <Select.Option value={"Bapak"}>Bapak</Select.Option>
                            <Select.Option value={"Ibu"}>Ibu</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Nama Anda <b style={{color: "red"}}>*</b></i>
                        <Input
                            value={this.state.name}
                            placeholder="Nama Anda"
                            onChange={event => this.onFieldChange("name", event.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Nomor Whatsapp <b style={{color: "red"}}>*</b></i>
                        <Input
                            value={this.state.phoneNumber}
                            maxLength={17}
                            placeholder="Format Telpon: 08xxxxxxxx"
                            onChange={event => this.onFieldChange("phoneNumber", event.target.value)}
                        />
                    </Form.Item>

                    <div style={!this.state.captchaSuccess ? {} : {display: 'none'}}>
                        <ReCAPTCHA
                            sitekey={'6Ldw1lsaAAAAAIPSAP_2Ivl1VXPeY8fOEvEsYQS9'}
                            onChange={this.onCaptchaChange}
                            onExpired={this.onCaptchaExpire}
                            onErrored={this.onErrored}
                        />
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

export default FindingRightProduct;