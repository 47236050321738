import axios from "axios";
import {Buffer} from "buffer";
import {baseUrlTrimitraCatalog, xApiKeyTrimitraCatalog} from "../../config/apiConfig/apiConfig";
import {TrimitraBaseResponse} from "./types/trimitraCatalogTypes";
import {ICityModel} from "./types/cityTypes";
// import {IVariant} from "./types/variantTypes";
import {IModel} from "./types/modelTypes";

class TrimitraCatalogServices {
    private axios = axios.create({
        baseURL: baseUrlTrimitraCatalog,
    });

    public async getCity(params: any) {
        const queries = {
            ...(params?.name && {
                name: params.name,
            })
        }
        try {
            return await this.axios.get<TrimitraBaseResponse<ICityModel[]>>('/area/amarta/city', {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getCityGroup(params: any) {
        const queries = {
            ...(params?.area && {
                area: params.area,
            }),
            ...(params?.name && {
                name: params.name,
            }),
        }
        try {
            return await this.axios.get<TrimitraBaseResponse<ICityModel[]>>('/area/amarta/city_group', {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getCurationHomepage(params?: { areaCode?: string }) {
        try {
            return await this.axios.get<any>(`curations/amarta/citygroup/${params?.areaCode?.toLowerCase()}/curation_homepage`,
                {
                    headers: {
                        "X-Api-Key": xApiKeyTrimitraCatalog,
                    }
                });
        } catch (e: any) {
            console.log(e.response.data.error.message);
            throw new Error(e.response.data.error.message);
        }
    }

    public async getListCatalog(params?: { areaCode?: any, code?: string }) {
        try {
            return await this.axios.get<TrimitraBaseResponse<IModel[]>>(`catalogs/amarta/citygroup/${params?.areaCode?.toLowerCase()}/${params?.code?.toLowerCase()}`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getCatalogVariant(params?: { areaCode?: string, modelName?: string, variantCode?: string, homeShowed?: boolean, showUnActive?: any }) {
        const queries = {
            ...(params?.modelName && {
                model_name: params.modelName,
            }),
            ...(params?.variantCode && {
                code: params.variantCode,
            }),
            ...(params?.homeShowed && {
                home_showed: params.homeShowed,
            }),
            ...(params?.showUnActive && {
                show_active_unactive: params.showUnActive,
            }),
        }
        try {
            return await this.axios.get<any>('products/amarta/citygroup/' + params?.areaCode?.toLowerCase() + '/variant', {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getCatalogModel(params?: { areaCode?: string, modelClass?: string, homeShowed?: boolean }) {
        const queries = {
            ...(params?.modelClass && {
                class: params.modelClass,
            }),
            ...(params?.homeShowed && {
                home_showed: params.homeShowed,
            }),
        }
        try {
            return await this.axios.get<TrimitraBaseResponse<IModel[]>>('products/amarta/citygroup/' + params?.areaCode?.toLowerCase() + '/model', {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            console.log(e.response.data.error.message);
            throw new Error(e.response.data.error.message);
        }
    }

    public async getCatalogMokas(params?: { areaCode?: string, mokasCode?: string }) {
        const queries = {
            ...(params?.mokasCode && {
                license_plate: Buffer.from(params.mokasCode, 'base64').toString().replace(/ /g, ''),
            }),
        }
        try {
            return await this.axios.get<TrimitraBaseResponse<any>>('products/amarta/citygroup/' + params?.areaCode?.toLowerCase() + '/used', {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getCatalogDetailVariant(params?: { variantCode?: string }) {
        const queries = {
            ...(params?.variantCode && {
                code: params.variantCode,
            }),
        }
        try {
            return await this.axios.get<TrimitraBaseResponse<any>>('/detail-products/amarta', {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getCatalogMaster(params?: { cityGroup?: string, active?: boolean, destinationLink?: string }) {
        const queries = {
            company: 'amarta',
            ...(params?.cityGroup && {
                city_group: params.cityGroup,
            }),
            ...(params?.destinationLink && {
                destination_link: params.destinationLink,
            }),
            ...(params?.active && {
                active: params.active,
            }),
        }
        try {
            return await this.axios.get<TrimitraBaseResponse<any>>('/catalog/master', {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getSimulationCreditFinco(params?: { areaCode?: string, vehicleModel?: string, fincoCode?: string }) {
        const queries = {
            ...(params?.vehicleModel && {
                vehicle_model: params.vehicleModel,
            }),
        }
        try {
            return await this.axios.get<TrimitraBaseResponse<any>>(`credit-simulation/config/amarta/${params?.fincoCode?.toLowerCase()}/${params?.areaCode?.toLowerCase()}`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getStock(params?: { areaCode?: string, vehicleVariant?: string }) {
        const queries = {
            ...(params?.vehicleVariant && {
                code: params.vehicleVariant,
            }),
        }
        try {
            return await this.axios.get<any>(`stock/amarta/${params?.areaCode?.toLowerCase()}`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getMultipleStock(params?: { areaCode?: string, vehicleVariant?: string, cityCode?: string }) {
        const queries = {
            ...(params?.vehicleVariant && {
                code: params.vehicleVariant,
                city_code: params.cityCode,
            })
        }
        try {
            return await this.axios.get<any>(`stock/multiple-dealer/amarta`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getListMicroPage(params?: any) {
        const queries = {
            ...(params?.vehicleModel && {
                tags: params.tags,
            }),
        }
        try {
            return await this.axios.get<any>(`/micro-page/amarta`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                    "Content-Type": "application/json"
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getDetailMicroPage(params?: any) {
        const queries = {}
        try {
            return await this.axios.get<any>(`/micro-page/amarta/${params.title}`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                    "Content-Type": "application/json"
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getAdditionalCostOrder(params?: { purchaseMethod?: string, cityGroup?: any, model?: any }) {
        try {
            return await this.axios.get<TrimitraBaseResponse<any>>(`/additional-order-cost/amarta?city_group=${params?.cityGroup?.toLowerCase()}&type=model&model=${params?.model?.toLowerCase()}&purchase_method=${params?.purchaseMethod?.toLowerCase()}`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getDealList(params?: any) {
        const queries = {}
        try {
            return await this.axios.get<any>(`/deal/amarta?show=true&active=true`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                    "Content-Type": "application/json"
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async createUsedVehicle(inputData?: any) {
        try {
            return await this.axios.post<any>('/used-vehicle/request-vehicle-sale',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyTrimitraCatalog
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async sendMessageUsedVehicle(inputData?: any) {
        try {
            return await this.axios.post<any>('/used-vehicle/message',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyTrimitraCatalog
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getPublicUsedVehicleMaster(params?: { company?: string, area?: string, uid?: string, state?: string }) {
        const queries = {
            ...(params?.area && {
                area: params.area,
            }),
            ...(params?.state && {
                state: params.state,
            }),
            ...(params?.uid && {
                used_vehicle_uuid: params.uid,
            }),
        }
        try {
            return await this.axios.get<any>(`/products/${params?.company}/area/default/used-vehicle`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getLiveList(params?: any) {
        const queries = {}
        try {
            return await this.axios.get<any>(`/live?company=amarta`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                    "Content-Type": "application/json"
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getMasterPaymentConfig(params?: any) {
        const queries = {}
        try {
            return await this.axios.get<any>(`/payment-method?company=amarta`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                    "Content-Type": "application/json"
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

}

export const trimitraCatalogServices = new TrimitraCatalogServices();
