import React, {Component} from "react";
import {promoServices} from "../../services/promo/PromoService";
import parse from 'html-react-parser';
import Image from "antd/lib/image";
import Badge from "antd/lib/badge";
import Form from "antd/lib/form";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import Empty from "antd/lib/empty";
import Modal from "antd/lib/modal";
import Spin from "antd/lib/spin";
import Typography from "antd/lib/typography";

class ListPromoModel extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            company: "amarta",
            dataPromo: [],
            selectPromoCode: null,
            inputPromoCode: "",
            fetchingPromo: true,
            dataOffer: {},
        }
        this.state = {...this.initState}
    }

    fetchProvinceData = async (data: any) => {

        await this.promisedSetState({
            dataOffer: data,
            fetchingPromo: true,
        })

        try {
            promoServices.getPromo(
                {
                    cityGroup: data.area,
                    purchaseMethod: this.props.purchaseMethod ?? 'cash',
                    brand: 'honda',
                    model: data.vehicle?.model_name?.toLowerCase(),
                    variant: data.vehicle?.variant_code?.toUpperCase(),
                    tenor: this.props?.tenor ?? null
                }
            ).then(dataFetchPromo => {
                this.setState({
                    company: this.state.company,
                    dataPromo: dataFetchPromo?.data?.data,
                    selectPromoCode: null,
                    fetchingPromo: false,
                });
            }).catch(e => {
                this.setState({
                    company: this.state.company,
                    dataPromo: [],
                    selectPromoCode: null,
                    fetchingPromo: false,
                });
            });
        } catch (e) {
            this.setState({
                company: this.state.company,
                dataPromo: [],
                selectPromoCode: null,
                fetchingPromo: false,
            });
        }
    }

    async componentDidMount() {
        await this.promisedSetState({
            company: this.props.company
        });
        this.fetchProvinceData(this.props);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.company !== prevProps.company || this.props.tenor !== prevProps.tenor) {
            this.fetchProvinceData(this.props);
        }
    }

    onFieldChange = <T extends keyof Pick<any, "inputPromoCode">>(target: T, value: string) => {
        const currentState = {...this.state};
        switch (target) {
            case "inputPromoCode":
                currentState.inputPromoCode = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    onClickPromo = (promoCode: any) => {
        if (promoCode) {

            this.setState({
                fetchingPromo: true,
            })

            promoServices.getDetailPromo(
                {
                    transactionAmount: this.state.dataOffer?.vehicle?.price,
                    promoCode: promoCode,
                    cityGroup: this.state.dataOffer.area,
                    purchaseMethod: this.props.purchaseMethod ?? 'cash',
                    brand: 'honda',
                    model: this.state.dataOffer?.vehicle?.model_name?.toLowerCase(),
                    variant: this.state.dataOffer?.vehicle?.variant_code?.toUpperCase(),
                    tenor: this.props?.tenor ?? null,
                    transactionValue: this.props?.transactionValue ?? null,
                    fincoCode: this.props?.fincoCode ?? null,
                    alternativeColor: this.props?.alternativeColor ?? null,
                    amartaVipUid: this.props?.amartaVipUid ?? null,
                }
            ).then(dataFetchDetailPromo => {
                this.setState({
                    fetchingPromo: false,
                });
                this.props.onPromoChangeProps(dataFetchDetailPromo);
            }).catch(e => {
                Modal.error({
                    title: 'Terjadi Kesalahan',
                    content: e.toString() + ' / kode promo tidak memenuhi syarat'
                });
                this.setState({
                    fetchingPromo: false,
                });
            });
        } else {
            Modal.error({
                title: 'Error',
                content: "Silakan isi kode promo atau pilih promo yang tersedia"
            });
            return false;
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div className={`footer-div-icon`} style={this.state.fetchingPromo ? {} : {display: 'none'}}>
                    <br/>&nbsp;
                    <Spin size="large"/>
                    <br/>&nbsp;
                </div>

                <div style={!this.state.fetchingPromo ? {} : {display: 'none'}}>
                    <Row>
                        <Col span={17}>
                            <Form layout="vertical">
                                <Input value={this.state.inputPromoCode} onChange={event => this.onFieldChange("inputPromoCode", event.target.value)} placeholder="Masukan Kode Promo Yang Anda Miliki"/>
                            </Form>
                        </Col>
                        <Col span={7}> <Button type="primary" block onClick={() => this.onClickPromo(this.state.inputPromoCode)}>Terapkan</Button></Col>
                    </Row>
                    <br/><br/>

                    <div style={this.state.dataPromo.length === 0 ? {} : {display: 'none'}}>
                        <Empty/>
                    </div>

                    {this.state.dataPromo.map((item: any, i: number) =>
                        <Badge.Ribbon key={'a' + i} text={item.purchase_method.toString()}>
                            <div className={`card-shadow-promo`} onClick={() => this.onClickPromo(item.promo_code)} style={{cursor: "pointer"}}>
                                <Row>
                                    <Col className={`card-col`} style={{backgroundColor: '#64b48f', borderTopLeftRadius: 7, borderBottomLeftRadius: 7}} span={7}>
                                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <Image preview={false} src={item.url_image}/>
                                        </div>
                                    </Col>
                                    <Col span={17} style={{padding: 10}}>
                                        <b style={{color: '#64b48f', marginBottom: '20px'}}>{item.caption}</b><br/>
                                        <Typography.Text code>{item.promo_code}</Typography.Text>
                                        <br/>&nbsp;
                                        <span style={{fontSize: '88%'}}>{parse(item.information)}</span>
                                        <span style={{fontSize: '88%'}}>Akhir Promo: {item.end_period?.slice(0, -3)}</span>
                                    </Col>
                                </Row>
                            </div>
                        </Badge.Ribbon>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default ListPromoModel;
