import axios from "axios";
import {AutotrimitraBaseResponse} from "./types/autotrimitrayServiceTypes";
import {baseUrlAutotrimitra} from "../../config/apiConfig/apiConfig";

class AreaServices {
    private axios = axios.create({
        baseURL: baseUrlAutotrimitra,
    });
    private key = "AIzaSyAFTemJSnp4h16lYQfITqLD8Ryp9fGNsVg";

    public async getProvince() {
        try {
            return await this.axios.get<AutotrimitraBaseResponse>('/geo-area/province', {
                params: {
                    key: this.key,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getCity(params?: { provinceCode?: string }) {
        const queries = {
            ...(params?.provinceCode && {
                province_code: params.provinceCode,
            }),
        }
        try {
            return await this.axios.get<AutotrimitraBaseResponse>('/geo-area/city', {
                params: {
                    key: this.key,
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getDistrict(params?: { cityCode?: string }) {
        const queries = {
            ...(params?.cityCode && {
                city_code: params.cityCode,
            }),
        }
        try {
            return await this.axios.get<AutotrimitraBaseResponse>('/geo-area/district', {
                params: {
                    key: this.key,
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getSubdistrict(params?: { districtCode?: string }) {
        const queries = {
            ...(params?.districtCode && {
                district_code: params.districtCode,
            }),
        }
        try {
            return await this.axios.get<AutotrimitraBaseResponse>('/geo-area/subdistrict', {
                params: {
                    key: this.key,
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }
}

export const areaServices = new AreaServices();
