import React, {Component} from "react";
import {Buffer} from "buffer";
import {NewVehicleVariantCreditStates} from "./types/NewVehicleVariantDetailCreditTypes";
import {withRouter} from "../../hoc/withRouter";
import currencyFormat from "../../helper/currencyFormat";
import SimpleLayout from "../../components/layouts/SimpleLayout";
import {userServices} from "../../services/amartaVip/UserServices";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import {vehicleServices} from "../../services/autotrimitra/vehicleServices";
import variantCodeUrlFormat from "../../helper/variantCodeUrlFormat";
import {promoServices} from "../../services/promo/PromoService";
import {fincoServices} from "../../services/b2b/FincoService";
import {offerApiServices} from "../../services/offer/OfferApiService";
import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";
import Image from "antd/lib/image";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";
import notification from "antd/lib/notification";
import Select from "antd/lib/select";
import Spin from "antd/lib/spin";
import ResumeMiniProduct from "../../components/product/ResumeMiniProduct";
import {trackLogServices} from "../../services/track/TrackLogService";
import {Dropdown, Menu, Popover, Space} from "antd";
import {DownOutlined, ExclamationCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Tag from "antd/lib/tag";
import {Divider} from "antd/es";
import {authApiService} from "../../services/amartaVip/AuthApiService";

class NewVehicleVariantDetailCredit extends Component<any, NewVehicleVariantCreditStates> {
    private readonly initState!: NewVehicleVariantCreditStates;
    private readonly areaCode: any;

    constructor(props: any) {
        super(props);
        const {match, location} = this.props;
        this.areaCode = match.params.area;
        const urlVariantColor = match.params.productcode.split("-");
        const variantCode = variantCodeUrlFormat(urlVariantColor[0]);
        const variantColorCode = urlVariantColor[1];

        document.title = `Amartahonda | Credit | Detail Variant Kendaraan Baru | ${match.params.area} | ${variantCode}`;

        const params = new URLSearchParams(location.search);
        const stockarea: any = params.get('stockarea');

        this.initState = {
            fincoListData: [],
            submitting: false,
            areaCode: this.areaCode,
            variantCode: variantCode,
            variantColorCode: variantColorCode,
            listNewVariantColor: [],
            chosenNewVariantColor: {},
            chosenNewVariantData: {},
            phoneNumber: "",
            offerFor: "owner",
            orderMakerName: "",
            agentCode: "",
            agentName: "",
            isWhatsappAvailable: true,
            completeFetchVariant: false,
            creditParam: {
                tenor: 0,
                dp_amount: 0,
                installment_amount: 0,
                finco_code: null,
                finco_name: null,
                credit_start_date: null,
            },
            promoParam: null,
            listColor: [],
            alternativeColor: {
                code: "SAME_AS_ORDER",
                name: "warna sesuai pesanan"
            },
            additionalOrderData: {
                cost: 0,
                note: "",
            },
            amartaVipUid: "",
            showModalNeedLogin: false,
            extraTextNeedLogin: "",
            offerNote: (stockarea) ? `stock area: ${stockarea}` : "",
        }
        this.state = {...this.initState}
    }

    onChange = (value: any, option: any) => {
        this.setState({
            offerFor: value,
        });
    }

    onFieldChange = <T extends keyof Pick<any, "phoneNumber" | "isWhatsappAvailable" | "orderMakerName">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, '')?.trim();
                break;
            case "isWhatsappAvailable":
                currentState.isWhatsappAvailable = Boolean(value);
                break;
            case "orderMakerName":
                currentState.orderMakerName = value?.toUpperCase()?.replace(/[^a-zA-Z0-9\s]/g, '');
                break;
        }

        if (target === 'phoneNumber') {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== '08') {
                    currentState.phoneNumber = "";
                }
            }
        }

        this.setState({
            ...currentState,
        });
    }

    checkBlacklistContact = async () => {
        let aaa = false;
        await userServices.checkBlacklistPhoneNumber(
            {
                phoneNumber: this.state.phoneNumber
            }
        ).then(dataCheck => {
            aaa = true;
        }).catch(e => {
            aaa = false;
        });

        return aaa;
    }

    onClickStart = async () => {
        if (this.state.offerFor === "maker") {
            if (!this.state.amartaVipUid) {
                await this.promisedSetState({
                    showModalNeedLogin: true,
                    extraTextNeedLogin: "",
                });
                return;
            }
        }

        if (!this.state.phoneNumber) {
            Modal.error({
                title: 'Proses Gagal',
                content: "Mohon Lengkapi Nomor Telpon"
            });
            return;
        }

        this.setState({submitting: true});

        if (this.state.offerFor === "owner") {
            const checkBlacklist = await this.checkBlacklistContact();
            if (checkBlacklist) {
                Modal.error({
                    title: 'Proses Gagal',
                    content: "Nomor telpon pemilik tidak dapat digunakan, hubungi admin untuk info lebih lanjut"
                });
                this.setState({
                    submitting: false
                });
                return;
            }
        }

        const promoCodes = [];
        if (this.state.promoParam?.toUpperCase()) {
            promoCodes.push(this.state.promoParam?.toUpperCase());
        }

        const input = {
            company: "amarta",
            source: "amartahonda.com",
            purchase_method: "credit",
            area: this.state.areaCode,
            agent_code: this.state.agentCode,
            agent_name: this.state.agentName,
            offer_broker: (this.state.offerFor !== 'owner'),
            id_card_owner: {
                full_name: (this.state.orderMakerName?.trim()) ? "" : this.state.orderMakerName?.trim()
            },
            id_card_order_maker: {
                full_name: (!this.state.orderMakerName?.trim()) ? "" : this.state.orderMakerName?.trim()
            },
            // contact: {
            //     phone_number_owner: (this.state.orderMakerName) ? "" : this.state.phoneNumber,
            //     phone_number_wa_available_owner: (this.state.orderMakerName) ? "" : this.state.isWhatsappAvailable,
            //     phone_number_order_maker: (!this.state.orderMakerName) ? "" : this.state.phoneNumber,
            //     phone_number_wa_available_order_maker: (!this.state.orderMakerName) ? "" : this.state.isWhatsappAvailable
            // },
            contact: {
                //phone_number_order_maker: this.state.phoneNumber,
                //phone_number_wa_available_order_maker: this.state.isWhatsappAvailable,
                phone_number_owner: "",
                phone_number_alternative_owner: "",
                phone_number_wa_available_owner: true,
                phone_number_order_maker: this.state.phoneNumber,
                phone_number_wa_available_order_maker: true,
                phone_number_guarantor: this.state.phoneNumber,
            },
            vehicle: {
                brand_name: this.state.chosenNewVariantData.brand_name,
                brand_uuid: this.state.chosenNewVariantData.brand_uuid,
                model_name: this.state.chosenNewVariantColor.model_name,
                model_uuid: this.state.chosenNewVariantData.model_uuid,
                variant_name: this.state.chosenNewVariantColor.variant_name,
                variant_uuid: this.state.chosenNewVariantData.variant_uuid,
                variant_code: this.state.chosenNewVariantColor.variant_code,
                color: this.state.chosenNewVariantColor.color_name,
                color_code: this.state.chosenNewVariantColor.color_code,
                image_url: this.state.chosenNewVariantColor.url_image,
                alternative_color: this.state.alternativeColor
            },
            credit: this.state.creditParam,
            promo_code: this.state.promoParam,
            promo_codes: promoCodes,
            notes: this.state.offerNote,
        }

        this.setState({
            submitting: true
        });

        try {
            await offerApiServices.createB2bCreditOffer(
                input
            ).then(successData => {
                window.location.href = `/aju-kredit/${successData.data.data.offer_code}`;
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            this.setState({
                submitting: false,
            });
        }
    }

    async componentDidMount() {
        const loginData = await authApiService.isExistAmartaVipInternalAffiliateData();
        const amartaVipUid = (loginData.userUid) ? Buffer.from(loginData.userUid, 'base64').toString() : null;
        const amartaVipName = (loginData.userName) ? Buffer.from(loginData.userName, 'base64').toString() : null;
        // const amartaVipType = (loginData.userType) ? Buffer.from(loginData.userType, 'base64').toString() : null;
        const amartaVipCode = (loginData.userAgentCode) ? Buffer.from(loginData.userAgentCode, 'base64').toString() : null;

        if (amartaVipUid) {
            await this.promisedSetState({
                amartaVipUid: amartaVipUid,
                agentCode: amartaVipCode,
                agentName: amartaVipName,
            });
        }

        await fincoServices.getListLeasing().then(async value => {
            const tempFinco = [{
                code: "GLOBAL",
                name: "PENAWARAN TERBAIK"
            }];

            for (const element of value?.data?.data) {
                tempFinco.push({
                    code: element?.code,
                    name: element?.name
                });
            }

            this.setState({
                fincoListData: tempFinco
            });
        }).catch(async reason => {
            this.setState({
                fincoListData: []
            });
        });

        await trimitraCatalogServices.getCatalogVariant({
            areaCode: this.state.areaCode,
            variantCode: this.state.variantCode,
        }).then(dataVariantDetail => {
            const listColor: any = [];
            for (const item of dataVariantDetail.data.data) {
                listColor.push({
                    color_code: item?.color_code,
                    color_name: item?.color_name
                });
                if (item.color_code.toUpperCase() === this.state.variantColorCode.toUpperCase()) {
                    this.setState({
                        completeFetchVariant: true,
                        listNewVariantColor: dataVariantDetail.data.data,
                        chosenNewVariantColor: item
                    });
                }
            }

            this.setState({
                listColor: listColor
            });
        });

        await vehicleServices.getVehicleVariant({
            code: this.state.variantCode
        }).then(dataFetchModel => {
            this.setState({
                chosenNewVariantData: dataFetchModel.data.data[0]
            });
        });

        // get additional order cost
        await trimitraCatalogServices.getAdditionalCostOrder(
            {
                cityGroup: this.state.areaCode,
                purchaseMethod: 'credit',
                model: this.state.chosenNewVariantColor.model_name?.toLowerCase(),
            }
        ).then(dataFetchPromo => {
            if (dataFetchPromo?.data?.data?.active) {
                this.setState({
                    additionalOrderData: {
                        cost: dataFetchPromo?.data?.data?.additional_order_cost ?? 0,
                        note: dataFetchPromo?.data?.data?.note ?? "",
                    }
                });
            } else {
                this.setState({
                    additionalOrderData: {
                        cost: 0,
                        note: "",
                    }
                });
            }
        }).catch(e => {
            this.setState({
                additionalOrderData: {
                    cost: 0,
                    note: "",
                }
            });
        });

        // Check promo parameter from url query
        const queryParams = new URLSearchParams(window.location.search);
        const promoParam = queryParams.get('promo');
        const fincoParam = queryParams.get('finco');
        const downPaymentParam = queryParams.get('downpayment');
        const tenorParam = queryParams.get('tenor');
        const creditStartDateParam = queryParams.get('creditstartdate');
        if (fincoParam || downPaymentParam || tenorParam || creditStartDateParam) {
            let fincoCodeParam = null;
            let fincoNameParam = null;
            for (const a of this.state.fincoListData) {
                if (a.code === fincoParam?.toUpperCase()) {
                    fincoCodeParam = a.code;
                    fincoNameParam = a.name;
                }
            }
            const creditParam = {
                tenor: (tenorParam != null) ? parseInt(tenorParam) : 0,
                dp_amount: (downPaymentParam != null) ? parseInt(downPaymentParam) : 0,
                installment_amount: 0, // hardcode
                finco_code: fincoCodeParam,
                finco_name: fincoNameParam,
                credit_start_date: creditStartDateParam ?? null
            }
            this.setState({
                creditParam: creditParam
            });
        }
        if (promoParam) {
            this.setState({
                completeFetchVariant: false,
            });
            promoServices.getDetailPromo(
                {
                    transactionAmount: this.state.chosenNewVariantColor.price,
                    promoCode: promoParam.trim(),
                    cityGroup: this.state.areaCode,
                    purchaseMethod: 'credit',
                    brand: 'honda',
                    model: this.state.chosenNewVariantColor.model_name?.toLowerCase(),
                    variant: this.state.chosenNewVariantColor.variant_code?.toUpperCase()
                }
            ).then(dataFetchDetailPromo => {
                this.setState({
                    completeFetchVariant: true,
                    promoParam: promoParam
                });
                notification['success']({
                    message: 'Informasi Promo',
                    description: dataFetchDetailPromo?.note,
                    duration: 5
                });
            }).catch(e => {
                this.setState({
                    completeFetchVariant: true,
                });
                notification['warning']({
                    message: 'Informasi Promo',
                    description: e.toString() + ' / kode promo tidak memenuhi syarat, anda tetap dapat menggunakan promo lain pada form selanjutnya',
                    duration: 6
                });
            });
        }

        trackLogServices.sendTrackLog({
            eventName: `vehicle credit page`,
            eventSourceUrl: window.location.href,
            customData: null
        });
    }

    onChangeAlternativeColor = async (code: string, text: string) => {
        this.setState({
            alternativeColor: {
                code: code,
                name: text,
            }
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        const menu = (
            <Menu>
                <Menu.Item key={'a'} onClick={() => this.onChangeAlternativeColor('SAME_AS_ORDER', 'warna sesuai pesanan')}>
                    warna sesuai pesanan
                </Menu.Item>
                {this.state.listColor.map((colorData: any, x: number) =>
                    <Menu.Item key={x} onClick={() => this.onChangeAlternativeColor(colorData?.color_code, colorData?.color_name)}>
                        {colorData?.color_code} - {colorData?.color_name}
                    </Menu.Item>
                )}
                <Menu.Item key={'b'} onClick={() => this.onChangeAlternativeColor('NO_PREFERENCE', 'tanpa prferensi warna')}>
                    tanpa prferensi warna
                </Menu.Item>
            </Menu>
        );

        const contentInfoColor = (
            <div style={{fontSize: "85%", color: "gray"}}>
                <b>warna sesuai pesanan:</b> <span>warna kendaraan sesuai warna yang dipilih.</span><br/>
                <b>tanpa preferensi warna:</b> <span>warna kendaraan sesuai dengan stock yang tersedia di dealer. (memungkinkan warna random)</span>
            </div>
        );

        return (
            <React.Fragment>
                {this.state.completeFetchVariant &&
                    <React.Fragment>
                        <SimpleLayout headerTitle={`Pengajuan Kredit ${this.state.areaCode?.toUpperCase()}`} linkHeaderBack={`/baru/${this.state.areaCode}/${this.state.variantCode.replace('/', '')}`}>
                            <div className={`content-top-padding-20 height-100`}>
                                <Row>
                                    <Col span={19}>
                                        <ResumeMiniProduct
                                            modelName={this.state.chosenNewVariantColor.model_name}
                                            variantCode={this.state.chosenNewVariantColor.variant_code}
                                            variantName={this.state.chosenNewVariantColor.variant_name}
                                            variantColorCode={this.state.chosenNewVariantColor.color_code}
                                            variantColorName={this.state.chosenNewVariantColor.color_name}
                                            linkTarget={`/baru/${this.state.areaCode}/${this.state.variantCode.replace('/', '')}`}
                                        />
                                        <Row style={{marginTop: 5}}>
                                            <Col span={8} className={`text-small-green-nopadding`} style={{cursor: "pointer"}}>
                                                <Popover content={contentInfoColor} trigger="hover">
                                                    Warna Alternatif
                                                    <InfoCircleOutlined style={{fontSize: "100%", paddingLeft: 5}}/>
                                                </Popover>
                                            </Col>
                                            <Col span={14}>
                                                <Dropdown overlay={menu}>
                                                    <Space style={{cursor: "pointer"}} className={`text-small-grey-nopadding`}>
                                                        {this.state.alternativeColor?.name}
                                                        <DownOutlined/>
                                                    </Space>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={4}>
                                        <div style={{paddingTop: 30}}>
                                            <Image alt={`variant color`} src={this.state.chosenNewVariantColor.url_image} style={{width: "100%"}}/>
                                        </div>
                                    </Col>
                                </Row>
                                <br/>

                                <Divider/>

                                <Tag style={this.state.additionalOrderData?.cost ? {marginBottom: '10px'} : {display: 'none'}} icon={<ExclamationCircleOutlined/>} color="processing">
                                    {this.state.additionalOrderData?.note} {currencyFormat(this.state.additionalOrderData?.cost)}
                                </Tag>

                                <br/>
                                <Form layout="vertical">
                                    <Form.Item>
                                        <i className={`text-small-grey-nopadding`}>Mengajukan Kredit Untuk</i>
                                        <Select
                                            placeholder="Pilih Pengajuan Kredit"
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={this.onChange}
                                            filterOption={(input, option) =>
                                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            value={this.state.offerFor}
                                        >
                                            <Select.Option value="owner">Saya Sendiri</Select.Option>
                                            <Select.Option value="maker">Orang Lain</Select.Option>
                                        </Select>
                                    </Form.Item>

                                    <div style={this.state.offerFor === 'maker' ? {} : {display: 'none'}}>
                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Kode Agent (tidak wajib diisi)</i>
                                            <Input disabled={true} value={(this.state.agentCode) ? `${this.state.agentCode} - ${this.state.agentName}` : `Akun Anda Tidak Memiliki Kode Agent`}/>
                                        </Form.Item>
                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Nama Pemesan</i>
                                            <Input
                                                value={this.state.orderMakerName}
                                                placeholder="Masukan Nama Anda Sebagai Pemesan"
                                                onChange={event => this.onFieldChange("orderMakerName", event.target.value)}
                                            />
                                        </Form.Item>
                                    </div>

                                    <Form.Item>
                                        <i className={`text-small-grey-nopadding`}>Nomor Telepon Genggam</i>
                                        <Input
                                            value={this.state.phoneNumber}
                                            maxLength={17}
                                            placeholder="Format: 08xxxxxxxx"
                                            onChange={event => this.onFieldChange("phoneNumber", event.target.value)}
                                        />
                                        <Checkbox onChange={e => this.onFieldChange("isWhatsappAvailable", e.target.checked)} checked={this.state.isWhatsappAvailable}>
                                            <i className={`text-small-grey-nopadding`}>Ini juga merupakan nomor Whatsapp saya.</i>
                                        </Checkbox>
                                    </Form.Item>
                                </Form>
                            </div>
                            <br/><br/><br/>
                        </SimpleLayout>
                        <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}} style={{position: "fixed", zIndex: 500, width: '100%', bottom: 0, backgroundColor: '#fff', paddingTop: 10, paddingBottom: 10, paddingLeft: '0.5%', paddingRight: '0.5%', boxShadow: '0px 2px 7px 2px rgb(208 208 208)'}}>
                            <Button style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} loading={this.state.submitting} block={true} onClick={() => this.onClickStart()} type="primary"> Mulai Pengajuan</Button>
                        </Col>
                    </React.Fragment>
                }

                {!this.state.completeFetchVariant &&
                    <SimpleLayout headerTitle={`Pengajuan Kredit ${this.state.areaCode?.toUpperCase()}`} linkHeaderBack={`/baru/${this.state.areaCode}/${this.state.variantCode.replace('/', '')}`}>
                        <div className="loading">
                            <Spin tip="Loading..."/>
                        </div>
                    </SimpleLayout>
                }

                <Modal
                    title={"Login Dibutuhkan"}
                    open={this.state.showModalNeedLogin}
                    onCancel={() => {
                        this.setState({showModalNeedLogin: false})
                    }}
                    onOk={() => {
                        this.setState({showModalNeedLogin: false})
                    }}
                    footer={[
                        <Button key="login" type={"primary"} style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} onClick={() => {
                            window.location.href = "/login?internal-page-source=" + window.location.href;
                        }}>Login / Daftar AmartaVIP</Button>,
                        <Button key="close" onClick={() => {
                            this.setState({showModalNeedLogin: false})
                        }}>Cancel</Button>
                    ]}
                >
                    Anda perlu login terlebih dahulu. {this.state.extraTextNeedLogin}
                </Modal>

            </React.Fragment>
        );
    }
}

export default withRouter(NewVehicleVariantDetailCredit);
