import React from "react";
import ImageCarousel from "../carousel/ImageCarousel";
import Carousel from "antd/lib/carousel";

export interface ILandingCarousel {
    homeBanner: any;
}

const LandingCarousel = (props: ILandingCarousel) => {
    return (
        <Carousel autoplay draggable={true}>
            {props.homeBanner.map((dt: any, x: number) =>
                <ImageCarousel key={x} imageSrc={dt.url}/>
            )}
        </Carousel>
    );
}

LandingCarousel.defaultProps = {
    homeBanner: [],
}

export default LandingCarousel;
